import { Card, Stack, Box, Dialog, DialogTitle, DialogContent, Snackbar, Alert, Divider, DialogActions, Button, TextField, CardContent, CardActions, LinearProgress, MobileStepper, Typography, CircularProgress } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Section from "./Section";

import { useNavigate } from "react-router-dom";
import jax from "../../helper/jax";
import { ArrowBack, ArrowForward, HealthAndSafety } from "@mui/icons-material";
import { evaluate } from "../../helper/util";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const EvalFooter = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalHeader = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalIntro = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalOutro = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

export default function Eval(props) {
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const { step, course_code, eval_type, role_id, onClose, is_lead, onSubmitted, finishText, footerMessage, show } = props;
	const [section, setSection] = useState(step || -1); 

	const [role, setRole] = useState(role_id);
	const [evalType, setEvalType] = useState(eval_type);
	const [courseCode, setCourseCode] = useState(course_code);
	
	const [answers, setAnswers] = useState();
	const [content, setContent] = useState();
	const [invalid, setInvalid] = useState({});
	const [showInvalid, setShowInvalid] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [showError, setShowError] = useState(false);
	const user = useSelector((state) => state.data.user);

	const theme = useTheme();
	var footer = <></>;
	var header = <></>;
	var intro = null;
	var outro = null;
	React.Children.forEach(props.children, (child) => {
		if (React.isValidElement(child)) {
			if (child.type === EvalFooter) {
				footer = child;
			} else if (child.type === EvalHeader) {
				header = child;
			} else if (child.type === EvalIntro) {
				intro = child;
			} else if (child.type === EvalOutro) {
				outro = child;
			}
		}
	});

	useEffect(()=>{
		if (show && courseCode && (role || evalType) && !content ) {
			begin();
		}
	}, [role, courseCode, evalType, show]);

	// useEffect(()=>{
	// 	if (content && step && !isNaN(step)) {
	// 		setSection(parseInt(step));
	// 	}
	// }, [step,content]);

	const go = function(to) {
		setSection(to);
		scrollToTop();
	}

	const begin = async function() {
		var course = await jax.get('/app/evals/'+courseCode+'/'+(evalType ?? role));
		
		if (course && course.content) {
			course.content.sections.map(s=>s.blocks = s.blocks.filter(b=>!b.lead_instructor || is_lead));
			setEvalType(course.content.type_id);
			setContent(course.content);

			//Reset answers if course or role changes
			if (!answers || answers.course_code != course.course_code || answers.role_id != role) { 
				var empty = {
					course_code: course.course_code,
					role_id: role,
					eval_type: course.content.id,
					answers: {}
				}
				
				course.content.sections.map((section, i) => {
					section.blocks.map((block, j) => {
						empty.answers[block.block_id] = {};
						if (block.type == 'likert') {
							empty.answers[block.block_id]._type = 'likert';
						}
						block.questions.map((question, k) => {
							empty.answers[block.block_id][question.answer_id] = null;
							if (question.allow_other) {
								empty.answers[block.block_id][question.answer_id+'_OTHER'] = null;
							}
						});
					});
				});
				setAnswers(empty);
			}

			go(!!intro ? -1 : 0);
			
		}
	} 

	const submit = async function(e) {
		e.preventDefault();
		if (validate(section)) {
			var url = '/app/evals/'+courseCode;
			if (role) {
				url += '/'+role;
			}

			try {
				setSubmitting(true);
				await jax.post(url, {answers: answers.answers, eval_type: answers.eval_type, eval_type_code: evalType, uid: user?.uid});
			} catch (err) {
				setSubmitting(false);
				setErrorMessage("An unexpected error occurred while saving your responses. Please try again.");
				setShowError(true);
				setTimeout(()=>setShowError(false), 5000);
				
				console.error(err);
				return;
			} finally {
				setSubmitting(false);
			}

			setSubmitted(true);
			if (onSubmitted) {
				onSubmitted();
			}
		}
	}

	const {control, trigger, handleSubmit, register, watch, formState: { errors }} = useForm({
	});

	const validate = function(section) {
		
		var valid = true;
		var inv = {}
		if (section < 0) return true;
		content.sections[section].blocks.map((block, i) => {
			
			//Don't check hidden blocks
			if (block.condition && !evaluate(block.condition, { ...answers.answers, _course_code: answers.course_code, _role: answers.role_id, _is_lead: is_lead})) return;

			block.questions.map((question, j) => {
				if ((block.required || question.required) && (!answers.answers[block.block_id] || !answers.answers[block.block_id][question.answer_id])) {
					valid = false;
					inv[block.block_id] ||= {};
					inv[block.block_id][question.answer_id] = true;
					//setInvalid({block_id: block.block_id, answer_id: question.answer_id});
				}
			});
		});
		
		setInvalid(inv);
		if (!valid) {
			showErrorMessage('One or more required questions have not been answered.')
		}
		//setShowInvalid(!valid);
		return valid;
	}

	const showErrorMessage = (message) =>{
		setErrorMessage('One or more required questions have not been answered.');
		setShowError(true);
		setTimeout(()=>setShowError(false), 5000);
	}

	const recordAnswer = function(answer) {
		var block = answers.answers[answer.block_id];
		block[answer.answer_id] = answer.answer;
		invalid[answer.block_id] ||= {};
		delete invalid[answer.block_id][answer.answer_id];
		setAnswers({...answers, answers: {...answers.answers}});
		setInvalid(invalid);
	}

	const next = async function() {
		if (validate(section)) {
			go(section+1);
		}
	}

	const contentRef = useRef();

	const scrollToTop = function() {
		if (contentRef.current) {
			contentRef.current.scrollTop = 0
		}
	}


	return <Dialog fullScreen={isMobile} maxWidth="800px"  open={!!show} className="eval">
			<DialogTitle sx={{my:0, p:0}}>
				<Box p={2} className="card-header">{content?.name || 'Course Evaluation'}</Box>
				<Box flex={1} maxWidth="800px">{!!header && header}</Box>
				{!submitted && section > -1 && <Stack direction="row" className="section-title" justifyContent="space-between" alignItems="center" sx={{p:2}}>
					{content.sections[section]?.title && <Box>{content.sections[section].title}</Box>}
					<Box flex={1}></Box>
					{section > -1 && <Box color={theme.palette.success.main}>Step {section+1} {isMobile ? "/" : "of"} {content.sections.length}</Box>}
				</Stack>}
			</DialogTitle>
			
			<DialogContent sx={{px:0, p:0, width: {lg:800}, maxWidth: 800, minHeight: 400, position:'relative'}} ref={contentRef}>
				{content ? <form onSubmit={submit}>
					{!submitted ? <Box>
							{section < 0 && !!intro && intro}
							{section !== undefined && section > -1 && content && <Box><Section onAnswer={recordAnswer} answers={answers} invalid={invalid} is_lead={is_lead} section={content.sections[section]}></Section></Box>}
						</Box> : <Box sx={{p: 1, pb:8}}>
							{outro}
						</Box>}
				</form> : <Box p={12} textAlign="center">
						<CircularProgress></CircularProgress>
				</Box>}

				
			</DialogContent>
			
			
			<Snackbar open={showInvalid} autoHideDuration={6000} onClose={()=>setShowInvalid(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} sx={{position:"absolute", bottom: '84px !important'}} >
				<Alert severity="error" variant="filled" sx={{ width: '100%' }}>
					One or more required questions have not been answered.
				</Alert>
			</Snackbar>
			<Divider sx={{my:0}}></Divider>
			<DialogActions sx={{p:0, position:'relative'}}>
				<Box className={`eval-error ${showError && "shown"}`} sx={{ backgroundColor: theme.palette.error.main, color: 'white' }} >
					<span style={{color:'white'}}>{errorMessage}</span>
				</Box>
				<Box p={2}><Button disabled={true}>&nbsp;</Button></Box>
			</DialogActions>
			<DialogActions sx={{p:0, position:'absolute', bottom: 0, right: 0, left: 0, background: 'white', zIndex:2}}>
				
				<Stack direction="column" flex={1} maxWidth="800px" alignItems="stretch">
				{!!footer && footer}
				{!submitted ? <Stack flex={1} direction="row" justifyContent="space-between" sx={{p:2}}>
					{<Button onClick={props.onClose}>Cancel</Button>}
					<Box flex={1}></Box>
					{content && (section > 0 || (intro && section > -1)) && <Button variant="outlined" onClick={()=>go(section-1) } sx={{mx:1}} startIcon={<ArrowBack/>}>Back</Button>}
					{content  && section < content.sections.length-1 && <Button variant="contained" sx={{mx:1}} onClick={()=>next()} endIcon={<ArrowForward/>}>Continue</Button>}
					{content && section == content.sections.length-1 ? <Button variant="contained" disabled={submitting} onClick={submit} sx={{mx:1}} >{finishText || "Submit Responses"}</Button> : <></>}
				</Stack> : <Box flex={1} p={2} textAlign="center"><Button variant="contained" onClick={()=>onClose()} sx={{mx:1}}>Close</Button></Box>
				}
				</Stack>
			</DialogActions>
		</Dialog>
	;
}

export {EvalFooter, EvalIntro, EvalOutro, EvalHeader};
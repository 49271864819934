
import { Checkbox, Chip, FormControlLabel, Stack } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, HighlightOff } from '@mui/icons-material';
import { useState } from 'react';

export const Tag = function(props) {
	const {tag, onDelete, onClick} = props;
	const {name, color} = tag;
	return <Chip 
		label={tag.name} 
		color="primary" 
		variant="filled" 
		size="small" 
		className="tiny colored hide-remove" 
		onDelete={onDelete && ((e)=>{e.stopPropagation(); onDelete(tag)})}
		deleteIcon={<HighlightOff/>} 
		sx={{backgroundColor: `${tag.color} !important`, color: "white !important"}} 
		onClick={(e)=>{e.currentTarget.classList.toggle('hide-remove'); 
e.stopPropagation(); e.preventDefault(); return false}} 
	/>
}

export const TagFilters	= function(props) {
	const {tags, onChange} = props;
	const [selected, setSelected] = useState([]);
	
	function onCheckChange(e) {
		var sel = selected;
		var val = parseInt(e.currentTarget.value);
		if (e.currentTarget.checked) {
			sel =[...selected, val];
		} else {
			sel = selected.filter((s)=>s != val);
		}
		setSelected(sel);
		onChange && onChange(sel);
	}

	return <Stack alignItems="flex-start" className="tag-filters">
		{tags.map((t,i)=>{
			return <FormControlLabel 
				key={i} 
				label={t.name}  
				
				sx={{ml:"0px", "& .MuiFormControlLabel-label": {fontSize: "1rem !important"}}}
				control={<Checkbox 
					checked={selected.indexOf(t.id) > -1}
					onChange={onCheckChange}
					value={t.id}
					sx={{mr: 0.5, color: t.color, "&.MuiCheckbox-root": {padding: "2px"}}}
					icon={<CheckBoxOutlineBlank />} 
					checkedIcon={<CheckBox sx={{color: t.color}}/>} 
					>
				</Checkbox>}>
			</FormControlLabel>;
		})}	
	</Stack>;
}


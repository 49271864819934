import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Link, Stack, Step, StepConnector, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserSelector from "./UserSelector";
import { Check } from "@mui/icons-material";
import { maskHas } from "../helper/util";
import { set } from 'react-hook-form';
import jax from "../helper/jax";
import { useSelector } from "react-redux";


const UserStep = function(props) {
	const {user, onContinue, onBack, index, onSelectUser, onGotoRoles, roleNames} = props;


	return <div>
		<div className="sub-title">
			
			The following user will be assigned the role(s) of <Link sx={{cursor:"pointer"}} onClick={onGotoRoles}>{roleNames}</Link>
			
		</div>
		<Box pt={2.25}>
			
			<UserSelector user={user} onSelect={onSelectUser} />
		</Box>
		
	</div>;
	
}

const UnitStep = function(props) {
	const {unit, onContinue, onBack, index, onSelectUnit} = props;
	
	return <div>
		<div className="sub-title">
			The following unit will 
		</div>
		<div>
			{unit ? unit.name : "No Unit Selected"}
			<Box mt={2}>
				<Button size="small" onClick={onContinue} variant="contained">Continue</Button>
				<Button size="small" onClick={onBack}>Back</Button>
			</Box>
		</div>
	</div>;
}

const GrantStep = function(props) {
	const {unit, user, canGoBack, roles, onSelectRole, onGotoUser, roleOptions, onContinue} = props;
	const unitRoles = useSelector(state=>state.data.unit_roles);
	
	// useEffect(()=>{
		
	// }, [roles]);

	function onChange(e) {
		onSelectRole(e.target.value, e.target.checked);
	}

	return unit ?<div>
		<div>
			<FormGroup onChange={onChange} sx={{paddingLeft:"4px", paddingTop:"8px"}}>
				{unitRoles.filter(x=>!roleOptions?.length || roleOptions.includes(x.unit_role_id)).map((role, i)=>{
					return <><FormControlLabel key={i} control={<Checkbox value={role.unit_role_id} defaultChecked={(roles & role.unit_role_id) == role.unit_role_id } sx={{marginLeft: '12px', padding: "4px 4px 4px 4px"}} />} label={role.name} />
					<Box ml={4.5} mb={2}  className="sub-title">{role.description}</Box></>;
				})}
				
			</FormGroup>
		</div>
		{!user && <div>
			<Box mt={1} ml={1} pb={1}>
				<Button size="small" disabled={!roles} onClick={onContinue} variant="contained">Continue</Button>
			</Box>
		</div>}
	</div>  : <></>;

}

export default function AccessGrantor(props) {
	const {unit, show, user, roles, children, onClose, onFinish, roleOptions, title, intro} = props;
	const [steps, setSteps] = useState([]);
	const [labels, setLabels] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const unitRoles = useSelector(state=>state.data.unit_roles);

	const [selectedUser, setUser] = useState(user);
	const [selectedUnit, setUnit] = useState(unit);
	const [selectedRoles, setRoles] = useState(0);
	const [roleNames, setRoleNames] = useState(null);

	useEffect(()=>{
		if (!show) {
			setUser(null);
			setUnit(null);
			setRoles(0);
			setActiveStep(0);
		} else {
			setUnit(unit);
			setUser(user);
			setRoles(roles || 0);
		}
	}, [show]);

	function handleRoleSelection(role, checked) {
		var roles = 0;
		if (checked) {
			roles = selectedRoles | role;
		} else {
			role = selectedRoles & ~role;
		}
		setRoles(roles);

		var names = unitRoles.filter(r=>maskHas(roles,r.unit_role_id)).map((r)=>r.name).join(', ');
		setRoleNames(names);
	}

	const applyRoles = async(user, unit, new_roles) => {
		var role_scope = roleOptions.reduce((acc, val)=>acc | val, 0);
		var result = await jax.post(`/app/admin/units/${unit.id}/appoint`, {
			user_id: user.uid,
			unit_id: unit.id,
			roles: new_roles,
			role_scope: role_scope // dont' remove roles outside of this dialog's scope
		});

		if (result) {
			onFinish && onFinish();
			onClose && onClose();
		}
	}

	return <Dialog open={!!show} onClose={onClose} fullWidth >
		<Box className="card-header">{title}</Box>
		
		<DialogContent dividers>
			<Box minHeight={400}>
				<Box pb={1} className="sub-title" dangerouslySetInnerHTML={{__html:intro}}>
					
				</Box>
				<Stepper  activeStep={activeStep} orientation="vertical" connector={<Box ml="10px" p={1} pl={2.5} className="sub-title" borderLeft="dotted 2px #ccc" display={activeStep > 0 ? "block" : "none"}>
					<b>Selected:</b> {roleNames}
				</Box>}>
					

					
					<Step>
						{!(user && unit) && <StepLabel StepIconComponent={user && unit && ((props)=>null)}>Select role(s) {user ? ` for ${user.title}` : ''}</StepLabel>}
						<StepContent sx={{paddingLeft:0}}>
							<GrantStep canGoBack={!unit} unit={selectedUnit} user={user} roles={selectedRoles} roleOptions={roleOptions} onSelectRole={handleRoleSelection}  onContinue={()=>setActiveStep(1)}/>
						</StepContent>
					</Step>
					
					{!user && <Step >
						<StepLabel>{user ? `Selected: ${user.title}` : "Select Personnel"}</StepLabel>
						<StepContent>
							<UserStep user={selectedUser} roleNames={roleNames} onSelectUser={(user)=>setUser(user)} onGotoRoles={()=>!user && setActiveStep(0)}  />
						</StepContent>
					</Step>}

					
					
						
					
					{/* {steps.map((step,i)=>{
						return step({onContinue:()=>setActiveStep(i+1), onBack:()=>setActiveStep(i-1), index: i, user:selectedUser, unit:selectedUnit, roles: selectedRoles, onSelectUser: (user)=>setUser(user), onSelectUnit: (unit)=>setUnit(unit), onSelectRole: handleRoleSelection})	
					})} */}
				</Stepper>
			</Box>
		</DialogContent>
		<DialogActions>
			<Stack direction="row" justifyContent="space-between" flex={1}>
				<Button size="small" onClick={onClose}>Cancel</Button>
				<Box flex={1}></Box>
				{activeStep > 0 && <Button size="small"  onClick={()=>setActiveStep(activeStep-1)}>Back</Button>}
				<Button size="small" variant="contained" disabled={!selectedUnit || !selectedUser || activeStep < steps.length-1 } onClick={()=>applyRoles(selectedUser, selectedUnit, selectedRoles)}>Update</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}
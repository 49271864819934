import { Box, Button, Dialog, DialogActions, DialogTitle, FormControl, Stack, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import jax from "../helper/jax";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function AddCommand(props) {
	const {unit, onClose, open, parent_id, onAdd, onUpdate} = props;

	const [name, setName] = useState('');
	const [location, setLocation] = useState('');
	const [alias, setAlias] = useState('');
	const [identifier, setIdentifier] = useState('');
	const [agencyId, setAgencyId] = useState('');
	const [keywords, setKeywords] = useState('');
	const [idName, setIdName] = useState('Command Identifier');
	const agencies = useSelector((state) => state.data.agencies);
	
	const {control , reset, handleSubmit, watch, formState: { errors }} =  useForm({
		defaultValues: {
			name: unit?.formal_name || '',
			alias: unit?.alias || '',
			agency_id: unit?.agency_id || 1,
			location: unit?.location || '',
			keywords: unit?.keywords || '',
			identifier: unit?.identifier || ''
		}
	});

	var watchAgencyId = watch('agency_id');

	useEffect(()=>{
		if (unit) {
			reset({
				name: unit?.formal_name || '',
				alias: unit?.alias || '',
				agency_id: unit?.agency_id || 1,
				keywords: unit?.keywords || '',
				location: unit?.location || '',
				identifier: unit?.identifier || ''
			});
		} else {
			reset({
				name: '',
				alias: '',
				agency_id: 1,
				keywords: '',
				location: '',
				identifier: ''
			});
		}
		
	}, [unit, control]);

	useEffect(()=>{
		if (watchAgencyId) {
			var agency = agencies.find((a)=>a.id == watchAgencyId);
			setIdName(agency?.unit_identifier || 'Command Identifier');
		}
	}, [watchAgencyId]);

	async function save(obj) {
		try {
			if (unit) {
				obj.unit_id = unit.id;
				
				var result = jax.post('/app/admin/units/update', obj);
				onUpdate();
				onClose();
			} else {
				
				obj.parent_id = parent_id;
				var result = await jax.put('/app/admin/units', obj);
				onAdd(result.id);
				onClose();
			}
		} catch(err) {
			debugger;
		
		}

	}

	return <Dialog open={open} onClose={onClose} >
		<form onSubmit={handleSubmit(save)}>
		<DialogTitle>{unit ? `Modify ${unit.name}` : "Add Command"}</DialogTitle>
		
			<Stack p={2} spacing={2} width={400} >
				<FormControl fullWidth>
					<Controller name="name" defaultValue={unit?.name} control={control} rules={{required: 'Required'}} render={({ field }) =>
						<TextField label="Command Name" size="small" required {...field} error={!!errors.name}></TextField>
					}/>
				</FormControl>
				<FormControl fullWidth>
					<Controller name="alias" control={control} render={({ field }) =>
						<TextField label="Friendly Name" size="small"  {...field} error={!!errors.name}></TextField>
					}/>
				</FormControl>
				<FormControl size="small">
					<InputLabel >Agency</InputLabel>
					<Controller name="agency_id" control={control} rules={{required: 'Required'}} render={({ field }) =>
						<Select label="Agency" size="small" required  {...field} error={!!errors.syllabus_id} >
							{agencies.map((s,i)=><MenuItem  key={i} value={s.id}>{s.name}</MenuItem>)}
						</Select>
					}/>
				</FormControl>
				<FormControl >
					<Controller name="identifier" control={control} rules={{required: 'Required'}} render={({ field }) =>
						<TextField label={idName} size="small" required {...field} error={!!errors.name}></TextField>
					}/>
				</FormControl>
				<FormControl fullWidth>
					<Controller name="location" control={control} rules={{required: 'Required'}} render={({ field }) =>
						<TextField label="Location" size="small" required {...field} error={!!errors.name}></TextField>
					}/>
				</FormControl>
				<FormControl fullWidth>
					<Controller name="keywords" control={control} render={({ field }) =>
						<TextField label="Keywords" size="small"  {...field} error={!!errors.name}></TextField>
					}/>
				</FormControl>
			</Stack>
		
		<DialogActions>
			<Stack direction="row" justifyContent="flex-end" spacing={1} flex={1}>
				<Button onClick={onClose}>Cancel</Button>
				<Button type="submit">Save</Button>
			</Stack>
		</DialogActions>
		</form>
	</Dialog>
}
import { Autocomplete, Avatar, Box, InputLabel, FormControl, ListItemButton, Popper, Stack, Typography, InputAdornment, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { hasRole, roles } from "../features/access";
import UserAvatar from "./UserAvatar";
import { getSearchClient } from "../helper/search";

const AutoWidthPopper = function(props) {
	return <Popper {...props} style={{width: 'fit-content'}} placement="bottom-start" />;
}

export default function UserSearch(props) {
	const {onSelect, index, user, label, managedOnly} = props;
	const {config} = useSelector((state) => state.data);
	
	const {tenantInfo} = useSelector(state=>state.app);
	const search_client = getSearchClient(tenantInfo.search_key);
	const search_index = search_client.initIndex(index || config.personnel_index);
	const me = useSelector(state=>state.data.user);
	const [searchTerm, setSearchTerm] = useState(user?.name || '');
	const [searchHits, setSearchHits] = useState([]);
	const {agencies} = useSelector(state=>state.data);
	

	const search = (term)=>{
		
		var facets = [];
		
		if (managedOnly && !hasRole(me, roles.ADMIN)) {
			
		}
			
		search_index.search(term || '*', {
			//attributesToRetrieve: ['uid', 'name', 'role_id','affiliation_id', 'agency_id', 'rank_id', 'photo'],
			filters: facets.join(' OR '),
			hitsPerPage: 25,
			//page: page-1,
		}).then((resp ) => {
			var us = resp.hits.map(h=>{
				return { ...h, unit_name: h.name, unit_id: h.id};
			})
			setSearchHits(us);
		});
	}

	useEffect(()=>{
		if (searchTerm?.length > 2) {
			search(searchTerm);
		} else {
			setSearchHits([]);
		}
	}, [searchTerm]);

	return <Box><FormControl fullWidth>

		<Autocomplete 
			freeSolo 
			PopperComponent={AutoWidthPopper} 
			getOptionLabel={(user)=> {
				return typeof user === 'string' ? user : user.name || ''
			}} 
			clearOnBlur={false} 
			ListboxProps={{sx:{py:0, width: "100%"}, disablePadding:true}}
			onInputChange={(e,v)=>!user && setSearchTerm(v)} 
			options={searchHits} 
			filterOptions={(x) => x} 
			isOptionEqualToValue={(option, value) => option.uid === value.uid}
			size="small" 
			label={label || "Assigned Command"}
			onChange={(e,v)=>{setSearchTerm(''); onSelect && onSelect(v); }}
			value={user ? user : searchTerm} 
			
			renderInput={(params) => <TextField id="user-search" sx={{minWidth:"250px"}} {...params} label={label || "Find Personnel"} />} fullWidth renderOption={(props, user, state)=>{
				var agency = agencies.find(x=>x.id == user.agency_id);
				return <ListItemButton {...props} key={user.uid} selected={true} onClick={()=>{setSearchHits([]); onSelect && onSelect(user); }}><Stack direction="row" justifyContent="flex-start" alignItems="center" flex={1}>
					<Box>
						<UserAvatar user={user} sx={{border:"solid 2px white", height: 30, width: 30, mr:1 }} />
					</Box>
					<Box>
						<Typography fontWeight={500} whiteSpace="nowrap">{user.unit_name}</Typography>
						<Box className="sub-title">{user.unit_location}</Box>
					</Box>
				</Stack></ListItemButton>;
		}} />
	</FormControl></Box>;
}
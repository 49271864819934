import { memo, useEffect, useState } from "react";
import {  Box, Breadcrumbs, Card, CardActionArea, CardActions, CardContent, Divider, Grid, Skeleton, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import jax from "../helper/jax";
import { unitRoles, unitRoleNames, actions } from "../features/access";
import {  AdminList } from "./Commands";
import { FlexTabs, FlexTab } from "../components/FlexTabs";
import ProfileDialog from "../components/ProfileDialog";
import Personnel from "./Personnel";
import {Link as RouterLink} from 'react-router-dom';
import CommandOverview from "./CommandOverview";
import SafetyReports from "../training/SafetyReports";
import Programs from "./Programs";
import Donors from "./Donors";
import AgencyAvatar from "../components/AgencyAvatar.tsx";

import { useSelector } from "react-redux";
import UnitSelector from "../components/UnitSelector";
import { can } from "../features/access";
import { AddCircle, AddCircleOutline, AddOutlined, Lock } from "@mui/icons-material";
import AddCommand from "./AddCommand.js";



export default function AdminPage(props) {

	const [tabIndex, setTabIndex] = useState(0);
	const theme = useTheme();
	
	const {unit_id, tab, object_id} = useParams();

	const [unit, setUnit] = useState(null);
	const [parents, setParents] = useState([]);
	const [crumbs, setCrumbs] = useState([]);
	const [subUnits, setSubUnits] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [showProfile, setShowProfile] = useState(null);
	const [loadPersonnel, setLoadPersonnel] = useState(false);
	const [createUnit, setCreateUnit] = useState(false);
	const {agencies, affiliations} = useSelector((state) => state.data);
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
	
	
	const nav = useNavigate();
	const {user} = useSelector(state=>state.data); 

	const load = async ()=>{
		const p1 = await jax.get(`/app/admin/units/${unit_id || ''}`).then((response)=>{
			response.admins.map((a)=>{
				a.roleNames = [];
				Object.values(unitRoles).map((r)=>{
					if ((a.roles & r) == r) {
						a.roleNames.push(unitRoleNames[r]);
					}
				});
			});
			setUnit(response.unit);
			setParents(response.parents);

			var crumbs = response.unit?.parent_ids?.map((id, i) => {return {id: id, name: response.unit.parent_names[i]};}) || [];
			setCrumbs(crumbs);

			var units = response.units.map((u)=>{
				var ag = agencies.find((a)=>a.id == u.agency_id);
				var aff = affiliations.find((a)=>a.id == ag.affiliation_id);
				return {
					...u,
					agency: ag,
					affiliation: aff
				};
			});
			setSubUnits(units);

			setAdmins(response.admins.sort((a,b)=>unit_id == a.authoritative_unit_id ? -1 : 1));
		});

		
	}

	useEffect(()=>{
		setLoadPersonnel(true);
		load();
	}, [unit_id]);

	return <Stack mt={0} flex={1}>
		<ProfileDialog uid={showProfile} onClose={()=>{setShowProfile(null)}}/>
		{unit && unit_id && <Stack spacing={1}>
			<Box >
				<Breadcrumbs separator={<Box className="sub-title">/</Box>}> 
					<RouterLink className="sub-title" color="text.secondary" to={`/admin`}>All Commands</RouterLink>
					{crumbs?.map((u,i)=>{
						return <RouterLink key={i} className="sub-title" color="text.secondary" to={`/admin/${u.id}`}>{u.name}</RouterLink>;
					})}
				</Breadcrumbs>
			</Box>
			<Stack direction={{xs:"column", lg:"row"}} pb={1} justifyContent="space-between" alignItems={{xs: "stretch", lg:"center"}}>
				<h2 >{unit ? unit.name : <Skeleton></Skeleton>}</h2>
				<Box mt={2}>
					<UnitSelector label="Find Command" managedOnly clearOnSelect onSelect={(u)=>u && nav(`/admin/${u.id}`)} />
				</Box>
			</Stack>
			
			<Box mx={isMobile && "-16px !important"}>
				<Card square={!!isMobile} >

					<Stack direction="column" justifyContent="stretch" alignItems="stretch">
						<FlexTabs value={tab || ""} onChange={(v)=>{nav(`/admin/${unit_id}/${v}`)}} flex={2} noBorderRadius>
							<FlexTab label="Overview" value="">
								<CommandOverview unit={unit} admins={admins} subUnits={subUnits} parents={parents} onReload={load} onShowProfile={setShowProfile} onChanges={(u)=>setUnit({...unit,...u})}/>
							</FlexTab>	
							{can(user, actions.VIEW_UNIT_PERSONNEL, unit.id) && <FlexTab label="Personnel" value="personnel" watch={[unit.id]}>
								<Personnel unit={unit} isActive={tab == "personnel"} onLoad={()=>setLoadPersonnel(false)}/> 
							</FlexTab>}
							{can(user, actions.VIEW_UNIT_DONORS, unit.id) && <FlexTab label="Donors" value="donors"  watch={[unit.id]}>
								<Donors unit={unit} isActive={tab == "donors"}></Donors>
							</FlexTab>}
							{can(user, [actions.VIEW_PROGRAM_COURSES, actions.VIEW_PROGRAM_REPORTS], unit.id) && <FlexTab label="Training Programs" value="programs" watch={[unit.id]} >
								{tab == "programs" && <Programs unit={unit} onShowProfile={setShowProfile}/>}
							</FlexTab>}
							<FlexTab label="Management" value="management" p={0}>
								<Stack direction={{xs:"column", lg:"row"}}>
									<Box flex={1} >
										<AdminList admins={admins} title="Command Management" unit={unit} onRemove={load} onShowProfile={uid=>setShowProfile(uid)} onFinish={load} addText="Add Manager" roles={[unitRoles.COMMAND_MGR, unitRoles.MEDICAL_OFFICER, unitRoles.PERSONNEL_MGR, unitRoles.DONOR_MGR]}></AdminList>				
									</Box>
									<Divider orientation="vertical" flexItem></Divider>
									<Box flex={1} >
										<AdminList admins={admins} title="Support Staff" unit={unit} onRemove={load} onShowProfile={uid=>setShowProfile(uid)} onFinish={load} addText="Add Support" roles={[unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.PROGRAM_ADMIN]}></AdminList>
									</Box>
								</Stack>
							</FlexTab>
							{can(user, actions.VIEW_UNIT_SAFETY_REPORTS, unit.id) && <FlexTab label="Safety Reports" value="safety" >
								<SafetyReports unit={unit} />
							</FlexTab>}
						</FlexTabs>
					</Stack>
				</Card>
			</Box>
		</Stack>}

		<AddCommand  open={createUnit} onClose={()=>{setCreateUnit(false)}} onAdd={(id)=>nav(`/admin/${id}`)} />

		{/* ALL AVAILABLE UNITS */}
		{!unit_id && !unit && subUnits.length > 0 && <Box>
			<Stack direction={{xs:"column", lg:"row"}} pb={3} justifyContent="space-between" alignItems="center">
				<h2 >Available Commands</h2>
				<Box>
					<UnitSelector managedOnly clearOnSelect label="Find Command" onSelect={(u)=>u && nav(`/admin/${u.id}`)} />
				</Box>
			</Stack>
			<Grid container spacing={2} direction="row" alignItems="stretch">
				{subUnits?.map((u,i) => { 
					return <Grid item xs={12} lg={3} key={i}>
						<Card key={i} sx={{height: "100%"}} variant="outlined">
							<CardActionArea onClick={()=>{nav(`/admin/${u.unit_id}`)}} sx={{height: "100%"}} >
								<CardContent sx={{paddingBottom:0.5}}>
									<Stack direction="column" alignItems="center" justifyContent="flex-start" spacing={1} sx={{height:"100%"}}>
										<AgencyAvatar agency_id={u.agency_id} size={64}></AgencyAvatar>
										<Box className="sub-title" pt={0.5} textAlign="center">
											<h2>{u.unit_name}</h2>
										</Box>
									</Stack>
								</CardContent>
								<CardActions sx={{justifyContent:"center", paddingBottom:2}}>
									<Box className="sub-title" pt={0}>{u.affiliation.name} | {u.agency.name}</Box>
								</CardActions>
							</CardActionArea>
						</Card>
					</Grid>;
				})}
				{can(user, actions.CREATE_UNIT) && <Grid item xs={12} lg={3} >
					<Card sx={{height: "100%", background: "none"}} onClick={()=>setCreateUnit(true)} variant="outlined">
						<CardActionArea sx={{height: "100%"}} >
							<CardContent sx={{paddingBottom:0.5}}>
								<Stack direction="column" alignItems="center" spacing={1} sx={{height:"100%"}}>
									<Lock sx={{fontSize:"64px"}} color="info"/>
									<Box pt={0.5} textAlign="center" color="info">
										<Typography variant="h5" color={theme.palette.info.main}>Add Command</Typography>
									</Box>
								</Stack>
							</CardContent>
							<CardActions sx={{justifyContent:"center", paddingBottom:2}}>
								{/* <Box className="sub-title" pt={0}>{u.affiliation.name} | {u.agency.name}</Box> */}
							</CardActions>
						</CardActionArea>
					</Card>
				</Grid>}
			</Grid>
		</Box>}
	</Stack>;
}

const KeepPersonnel = memo((props)=>{
	return <Personnel {...props} />
}, (prevProps, nextProps)=>{
	//true to skip re-render, false to re-render
	//skip if the tab isn't active or the unit has not changed

	var needsUpdate = prevProps.unit?.id != nextProps.unit?.id && nextProps.isActive;
	return !needsUpdate;
});


import React, {useState, useEffect, useRef} from 'react';
import {Box, Button, Popover, Alert, Stack, CircularProgress, AlertTitle} from '@mui/material';


export default function FeedbackButton(props) {
	const {error, text, onClick, loading, onHideError, children, errorTitle, severity, ...other} = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const btnRef = useRef(null);

	useEffect(()=>{
		if (error) {
			setAnchorEl(btnRef.current);
		}
	
	}, [loading, error]);

	return <Box position="relative" display="inline">
		<Button variant="outlined" {...other} ref={btnRef} onClick={onClick} disabled={loading}>
			<Box sx={{visibility: loading ? 'hidden' : 'visible'}}>{children}</Box>
			{loading && <Stack alignItems="center" justifyContent="center" sx={{position:'absolute', top: 0, left: 0, right: 0, bottom: 0 }} p={.25}><CircularProgress size="1.5rem" color="white"/></Stack>}
		</Button>
		<Popover
			elevation={2}
			onClose={()=>{setAnchorEl(null); onHideError && onHideError();}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			  }}
			  transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			  }}
			  anchorEl={anchorEl}
			  open={!!anchorEl}
			  slotProps={{
				paper: {sx:{transform: 'translateY(-8px) !important'}}
				}}
			>
			<Alert severity={severity} icon={severity && errorTitle ? undefined : false}>
				{errorTitle && <AlertTitle>{errorTitle}</AlertTitle>}
				{error}</Alert>
		</Popover>
	</Box>
}
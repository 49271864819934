import { AirlineSeatFlatAngled, ArrowCircleUp, HourglassBottom, HourglassTop, MilitaryTech, Psychology, Restore, ScienceOutlined, Speed, Update, Vaccines } from "@mui/icons-material";
import { Divider, Stack, Tooltip } from "@mui/material";
import moment from "moment";


export default function DonorIndicators(props) {
	const {donor} = props;

	var days = moment().diff(donor.last_draw_date, 'd');
	return <Stack direction="row" spacing={1} alignItems="center" sx={{fontSize: '1.5rem'}}>
		{donor.venous_score != 0 ? <Tooltip arrow title={donor.venous_score > 0 ? "Easy Venous Access" : "Difficult Venous Access"}><Vaccines  fontSize="inherit" color={donor.venous_score < 0 ? "error" : (donor.venous_score > 0 ? "success" : "disabled")}/></Tooltip> : <Vaccines  fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}/>}
		<Divider orientation="vertical" flexItem></Divider>
		
		{donor.tactical_score < 0 ? <Tooltip arrow title="Key Operational Leader"><MilitaryTech fontSize="inherit" color="error"></MilitaryTech></Tooltip> : <MilitaryTech fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}></MilitaryTech>}
		<Divider orientation="vertical" flexItem></Divider>
		{donor.vasovagal_score != 0 ? <Tooltip arrow title={donor.vasovagal_score > 0 ? "Resilient Vasovagal Response" : "Poor Vasovagal Response"}><AirlineSeatFlatAngled fontSize="inherit" color={donor.vasovagal_score < 0 ? "error" : (donor.vasovagal_score > 0 ? "success" : "disabled")}></AirlineSeatFlatAngled></Tooltip> : <AirlineSeatFlatAngled fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}></AirlineSeatFlatAngled>}
		<Divider orientation="vertical" flexItem></Divider>
		{donor.weight_score != 0 ? <Tooltip arrow title={donor.weight_score > 0 ? "High Body Weight" : "Low Body Weight"}><Speed fontSize="inherit" color={donor.weight_score < 0 ? "error" : (donor.weight_score > 0 ? "success" : "disabled")} sx={{transform: donor.weight_score < 0 ? "scale(-1,1)" : "none"}}></Speed></Tooltip> : <Speed fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}></Speed>}
		<Divider orientation="vertical" flexItem></Divider>
		{donor.fill_score > 0 && <Tooltip arrow title="Rapid Fill"><Update fontSize="inherit" color="success"></Update></Tooltip>}
		{donor.fill_score < 0 && <Tooltip arrow title="Slow Fill"><Restore fontSize="inherit" color="error"></Restore></Tooltip>}
		{donor.fill_score == 0 && <Tooltip arrow title="Average Fill"><Restore fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}></Restore></Tooltip>}
		<Divider orientation="vertical" flexItem></Divider>
		{days < 56 ? <Tooltip arrow title={`Donated ${days} days ago`}><HourglassTop fontSize="inherit" color="error"></HourglassTop></Tooltip> : <HourglassBottom fontSize="inherit" color="disabled" sx={{opacity: 0.06125}}></HourglassBottom>}
	</Stack>
}
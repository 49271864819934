import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";


export default function UserAvatar(props) {
	var user = useSelector((state) => state.data.user);
	if (props.user) {
		user = props.user;
	}
	

	var src = user.photo;;
	if (!src && user.agency_abbr) {
		src = "/images/agency/"+user.agency_abbr+".png";
	}

	return <Avatar {...props} alt={user.name} src={src}  />
}
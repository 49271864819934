import { Accordion, AccordionDetails, AccordionSummary, Card, Box, RadioGroup, FormControlLabel, Radio, Divider, Stack, TextField } from "@mui/material";
import { useState } from "react";
import PageTitle from "../components/PageTitle";

export default function Donation(props) {

	const [incident, setIncident] = useState("");

	return <Box>
		<PageTitle title="Post Donation Follow-up"/>
		
		<Box >
			This information is used to track transfusion events in order to improve safety and outcomes.  
		</Box>

		<Box py={2}>
			<Card variant="outlined">
				<Box p={2} pb={1}><b>Please provide details related to the recording of this data.</b></Box>
				<Stack direction={{xs:"column", lg:"row"}} spacing={2} p={2} flex={1}>
					<Box flex={1}>
						<TextField label="Your Name" size="small" fullWidth InputLabelProps={{shrink:true}}></TextField>
					</Box>
					<Box flex={1}>
						<TextField label="Location" size="small" fullWidth InputLabelProps={{shrink:true}}></TextField>
					</Box>
					<Box flex={1}>
						<TextField label="Date" size="small" fullWidth type="date" InputLabelProps={{shrink:true}}></TextField>
					</Box>
					<Box flex={1} display={{xs:"none", lg:"block"}}></Box>
					<Box flex={1} display={{xs:"none", lg:"block"}}></Box>
				</Stack>
			</Card>
		</Box>

		<Box py={1}>
			<Card variant="outlined" mt={2}>
				<Box p={2} pb={1}><b>Please provide the recipient information found on the blood bag label that was provided.</b></Box>
				<Stack direction={{xs:"column", lg:"row"}} spacing={2} p={2} flex={1}>
					<Box flex={1}>
						<TextField label="Collected By" size="small" fullWidth InputLabelProps={{shrink:true}}></TextField>
					</Box>
					<Box flex={1}>
						<TextField label="Collection Date / Time" size="small" fullWidth InputLabelProps={{shrink:true}} type="date"></TextField>
					</Box>
					<Box flex={1}>
						<TextField label="Administered By" size="small" fullWidth InputLabelProps={{shrink:true}}></TextField>
					</Box>
					<Box flex={1}>
						<TextField label="Administration Date / Time" size="small" fullWidth InputLabelProps={{shrink:true}}	type="date"></TextField>
					</Box>
					<Box flex={1} display={{xs:"none", lg:"block"}}></Box>
				</Stack>
			</Card>
		</Box>

		<Box pt={1} pb={2}>
			<Card p={2}>
				<Box p={2}><b>Please provide the context that led to this transfusion event.</b></Box>
				<RadioGroup name="incident-type" value={incident} onChange={(e)=> setIncident(e.currentTarget.value)}>
					<Accordion expanded={incident == "live"} disableGutters>
						<AccordionSummary sx={{minHeight: '36px !important', "& .MuiAccordionSummary-content": {"margin": "0 !important"}}}><FormControlLabel control={<Radio value="live"/>} label="Emergency Whole Blood Transfusion (Non-training)"/></AccordionSummary>
						<AccordionDetails sx={{pt: 0}}>
							<Divider/>
							<Box pt={1} pl={2}>
								<Box>This selection will notify the donor’s Donor Manager and Medical Officer in order to facilitate post-donation testing.  The Joint Armed Services Blood Program will also be notified of this Emergency Whole Blood transfusion event.</Box>
								<Box pt={2}><b>If known, please provide the recipient's identification number (DOD ID#)</b></Box>
								<Box pt={3} width={300}>
									<TextField label="Recipient DOD ID#" size="small" InputLabelProps={{shrink:true}} fullWidth></TextField>
								</Box>
							</Box>
							
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={incident == "training"} disableGutters>
						<AccordionSummary sx={{minHeight: '36px !important', "& .MuiAccordionSummary-content": {"margin": "0 !important"}}}><FormControlLabel control={<Radio value="training"/>} label="Autologous Transfusion for training purposes"/></AccordionSummary>
						<AccordionDetails sx={{pt: 0}}>

						<Divider/>
							<Box pt={1} pl={4}>
								<Box py={2}><b>Please report any adverse reaction that occured due to the autologous transfusion.</b></Box>
								<RadioGroup name="training-details" >
									<FormControlLabel control={<Radio/>} value="DONOR_MISMATCH" label="Donor-Patient mismatch *** Select this option for any inadvertent non-autologous transfusion of blood. ***"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="CONTAMINATED_STICK" label="Needle stick with blood exposure between individuals (contaminated)"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="CLEAN_STICK" label="Needle stick without blood cross-contamination (clean)"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="OCULAR_EXPOSURE" label="Ocular blood exposure. (blood exposure to the eyes)"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="ALLERGIC_REACTION" label="Allergic reaction (suspected or actual)"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="INFILTRATED_IV" label="Infiltrated intravenous access / extravasation"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="THRMBOPHLEBITIS" label="Thrombophlebitis"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="ARTERIAL_PUNCTURE" label="Arterial puncture"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="NERVE_DAMAGE" label="Nerve damage"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="VASOVAGAL_SYNCOPE" label="Vasovagal syncope or near syncope"></FormControlLabel>
									<FormControlLabel control={<Radio/>} value="OTHER" label="Other ..."></FormControlLabel>

								</RadioGroup>
							</Box>
						</AccordionDetails>
					</Accordion>
				</RadioGroup>
			</Card>
		</Box>
		
		
	</Box>;

}
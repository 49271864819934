import { Download, Error, KeyboardDoubleArrowRight, Publish, Verified } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, Dialog, DialogActions, DialogContent, Divider, LinearProgress, Stack, Table, TableBody, TableCell, TableRow, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState, useEffect, useRef } from 'react';
import jax from "../helper/jax";

export default function DonorVerification(props) {
	const {open, onClose, onVerify, onInvalidate, donors} = props;
	const [index, setIndex] = useState(0);
	const [donor, setDonor] = useState(null);
	const [comments, setComments] = useState("");
	const [showComments, setShowComments] = useState(false);
	const [verified, setVerified] = useState("");

	useEffect(()=>{
		setComments('');
		setShowComments(false);
		if (donors && index < donors.length) {
			
			setDonor(donors[index]);
				
		} else{
			setDonor(null);
		
		}

	}, [index, donors]);

	useEffect(()=>{
		if (donor) {
			if (donor.verified === 1) {
				setVerified(1);
			} else if (donor.invalidated === 1) {
				setVerified(0);
			} else {
				setVerified("");
			}
		}
	}, [donor]);

	useEffect(()=>{
		if (open) {
			setIndex(0);
		}
		if (donors) {
			setDonor(donors[index]);
		}
	}, [open]);

	async function verify(){
		try {
			await jax.post(`/app/admin/donors/${donor.unit_id}/verify/${donor.screening_code}`);
			if (onVerify) {
				onVerify(donor);
			}
			setIndex(index+1);
		} catch (err) {
			console.error(err);
		
		}
	}

	async function invalidate(){
		try {
			await jax.post(`/app/admin/donors/${donor.unit_id}/invalidate/${donor.screening_code}`, {comments: comments});
			if (onInvalidate) {
				onInvalidate(donor);
			}
			setIndex(index+1);
		} catch (err) {
			console.error(err);
		
		}
	}

	useEffect(()=>{
		
			setTimeout(()=>{
				if (showComments) {
					commentRef.current.focus();
				}
			}, 250);
		
	}, [showComments])

	var commentRef = useRef();

	return <Dialog open={props.open} onClose={onClose} keepMounted={false}>
		<DialogContent style={{minWidth: 400}}>
			{donor && donors ? <Stack flex={1} spacing={1} position="relative" overflow="hidden">
				<Box pb={1}>Referencing the lab results from this donor's most recent screening, please verify that the details below are correct.</Box>
				
				<Box textAlign="center" pb={0.5} pt={1}>
					<Box>Donor {index+1} of {donors.length}</Box>
				</Box>
				<Box pb={2}>
					<LinearProgress variant="determinate" value={(index+1)/donors.length*100} />
				</Box>
				<Card variant="outlined" elevation={0}>
					<Table size="small">
						<TableBody>
							<TableRow>
								<TableCell className="bold">Donor Name</TableCell>
								<TableCell>{donor.title}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="bold">DOD ID</TableCell>
								<TableCell>{donor.mil_id}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="bold">Donor ID</TableCell>
								<TableCell>---</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="bold">Blood Unit ID (DIN)</TableCell>
								<TableCell>{donor.donor_no}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="bold">Blood Type</TableCell>
								<TableCell>
									<Stack direction="row" spacing={1} alignItems="center">
										<Box>{donor.blood_type}</Box>
										{donor.blood_type.startsWith('O') && <Divider orientation="vertical" flexItem/>} 
										{donor.blood_type.startsWith('O') && <>
											{donor.low_titer ? <Download color="success" sx={{fontSize:"1.2rem"}}/> : <Publish color="error" sx={{fontSize:"1.2rem"}}/>}
											{donor.low_titer ? <Box color="primary.main">Low Titer</Box> : <Box color="error.main">High Titer</Box>}
									 	</>}
									</Stack>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="bold" sx={{borderBottom: 0}}>Okay To Donate?</TableCell>
								<TableCell sx={{borderBottom: 0}}><i>This individual meets all ASBP laboratory criteria</i></TableCell>
							</TableRow>
						</TableBody>
					</Table>
					
				</Card>

				{/* THIS IS HERE A SHIM FOR ACCURATE SPACING FOR ABSOLUTE POSITIONED DRAWER */}
				<Box pt={2} sx={{visibility:'hidden'}}>
					<ButtonGroup size="medium" >
						<Button size="medium" sx={{py: 1.5}}>HIDDEN</Button>
					</ButtonGroup>
				</Box>
				
				<Box pt={2} className={`verification-drawer ${showComments && 'open'}`} bgcolor="white">
					<ButtonGroup sx={{display:'flex'}} color="success" size="medium" >
						<Button size="medium" variant={verified === 1 ? "contained" : "outlined"} sx={{py: 1.5, flex:1, }} color="primary" startIcon={<Verified/>} onClick={verify}>Verify Results</Button>
						<Divider orientation="vertical" flexItem />
						<Button size="medium" variant={verified === 0 ? "contained" : "outlined"} value={true} sx={{py: 1.5, flex:1}} color="error" startIcon={<Error/>} onClick={()=>setShowComments(!showComments)}>Report Discrepency</Button>
						<Divider orientation="vertical" flexItem />
						<Button size="medium" value={true} sx={{py: 1.5}} color="disabled" endIcon={<KeyboardDoubleArrowRight/>} onClick={()=>setIndex(index+1)}>Skip</Button>
					</ButtonGroup>
					<Stack spacing={1} pb={1} pt={2}>
						<TextField 
							multiline
							rows={2}
							inputRef={commentRef}
							label="Comments" 
							value={comments} 
							onChange={(e)=>setComments(e.currentTarget.value)}
							size="small" 
							fullWidth
						/>
						<Stack direction="row" spacing={1} justifyContent="flex-end">
							<Button variant="contained" size="small" color="error" onClick={invalidate}>Report</Button>
							<Button size="small" color="disabled" onClick={()=>setShowComments(false)}>Cancel</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack> : <Box p={4}>
				There are no remaining donors to verify.  
			</Box>}
		
		</DialogContent>

		<DialogActions sx={{justifyContent: 'center'}}>
			<Button onClick={props.onClose}>Close</Button>
		</DialogActions>
	</Dialog>
}
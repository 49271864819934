import { Alert, AlertTitle, Avatar, Badge,  Box, Button, ButtonGroup, Checkbox, Chip, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, FormLabel, Icon, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuList, Pagination, Paper, Radio, RadioGroup, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import DonorProfile, { FactorSlider } from "../components/DonorProfile";
import {ClickAwayListener} from "@mui/base";	
import React, { useEffect, useRef, useState } from "react";
import jax from "../helper/jax";
import { Add, ArrowCircleDown, ArrowCircleUp, Bloodtype, Cancel, Menu as MenuIcon, Check, Close, ContentPaste, Delete, DoDisturb, DoNotDisturb, Edit, Error, GroupAdd, GroupRemove, Groups, HourglassBottom, HourglassDisabled, HourglassEmpty, HourglassFull, HourglassTop, HowToReg, PeopleAlt, Search, Warning, Print, ArrowDropDown, Badge as BadgeIcon, Filter, FilterAlt, ArrowCircleUpTwoTone, ArrowCircleDownTwoTone, CheckBoxOutlineBlankTwoTone, CheckBoxTwoTone, MilitaryTech, AirlineSeatFlat, Update, Vaccines, Speed, AirlineSeatFlatAngled, ArrowRight, FilterList, Note, Verified, Download, Publish, AssignmentReturnOutlined, ContentPasteTwoTone, AddCircleOutline, Share, SwapHoriz, ContentPasteGoTwoTone, ContentPasteGo, NoteOutlined, AddCircle, ArrowUpward, ArrowDownward, AssignmentReturnedOutlined } from "@mui/icons-material";
import algoliasearch from 'algoliasearch/lite';
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { palette } from '@mui/material/styles';
import moment from "moment";
import DonorIndicators from "../components/DonorIndicators";
import AgencyAvatar from "../components/AgencyAvatar.tsx";
import { getSearchClient } from "../helper/search";
import Notification from "../components/Notification";
import { asDate, useEffectDebugger } from "../helper/util";
import DonorVerification from "../components/DonorVerification";
import UnitSelector from "../components/UnitSelector";
import Can from "../components/Can";
import { actions } from "../features/access";

function Donor(props) {
	const {donor, rosters, selected, onSelect, onClick, shared} = props;

	const theme = useTheme();
	var rosterNames = "";
	if (rosters?.length > 0) {
		rosterNames = rosters.filter(x=>donor.roster_ids.includes(x.id)).map((r,i)=>r.name);
	}

	function getDrawColor() {
		var days = moment().diff(donor.last_draw_date, 'd');
		if (days > 56) {
			return theme.palette.success.main;
		} else if (days > 30) {
			return theme.palette.warning.main;
		} else if (donor.last_draw_date){
			return theme.palette.error.main;
		} else {
			return theme.palette.grey[400];
		}
	}

	function getDrawIcon() {
		var days = moment().diff(donor.last_draw_date, 'd');
		if (days > 56) {
			return <HourglassBottom/>
		} else if (days > 30) {
			return <HourglassBottom/>
		} else if (donor.last_draw_date){
			return <HourglassTop/>
		} else {
			return <HourglassDisabled/>
		}
	
	}

	return <ListItem disableGutters disablePadding divider dense>
		<ListItemButton role={undefined} selected={selected} sx={{paddingLeft:0}} onClick={onClick}>
			<Stack direction="row" alignItems="center" flex={1}>
				{/* <Box lineHeight={0} pl={1} sx={{cursor: 'drag'}}>
					<MenuIcon color="disabled" />
				</Box> */}
				<Checkbox
					color="primary"
					checked={selected}
					mr={0}
					value={donor.uuid}
					sx={{p:1.5}}
					onClick={(e)=>{e.stopPropagation();}}
					onChange={(e)=>{e.stopPropagation(); onSelect && onSelect(donor.uuid, e.currentTarget.checked)}}
				/>
				
				<Stack direction={{xs:"column", lg:"row"}} alignItems="stretch" justifyContent="flex-start" flex={1} spacing={1}>
					<Stack direction="row" sx={{flex:3}} spacing={1} alignItems="center" justifyContent="flex-start" >
						<AgencyAvatar rank_id={donor.rank_id}  size={28}/>
						<Box color={donor.invalidated ? theme.palette.error.main : "inherit"}>
							{donor.title}
							{shared && <Tooltip arrow title={`Shared donor from ${donor.unit_name}`}>
								<Stack direction="row" alignItems="center" className="sub-title xs" spacing={0.5} mt={.25} ml="-1px" >
									<AssignmentReturnedOutlined sx={{fontSize:"18px", marginTop:"2px !important"}}/>
									<Box>
										<i>{donor.unit_name}</i>
									</Box>
								</Stack>
							</Tooltip>}
						</Box>
						{!!donor.invalidated && <Tooltip arrow title="Recent screening data was invalidated"><DoNotDisturb color="error" sx={{fontSize:22}}/></Tooltip>}
						{!donor.verified && !donor.invalidated   && <Tooltip arrow title="Recent screening data is pending verification"><Warning color="warning" sx={{fontSize:22}}/></Tooltip>}
						<Box flex={1}/>
						<Tooltip arrow open={!donor.blood_type?.startsWith('O') ? false : undefined} title={donor.low_titer ? "Low Titer" : "High Titer"}><Stack direction="row" alignItems="center" whiteSpace="nowrap" pr={1} spacing={0.5} color={donor.blood_type?.startsWith('O') ? (!donor.low_titer ? theme.palette.error.main : theme.palette.success.main) : theme.palette.error.main}>
							
							<Bloodtype color="inherit"/>
							<Box color="inherit">{donor.blood_type}</Box>
							{donor.blood_type?.startsWith('O') && !donor.low_titer && <Publish color="error" fontSize='small'/>} 
							{donor.blood_type?.startsWith('O') && !!donor.low_titer && <Download color="success" fontSize='small'/>} 
						</Stack></Tooltip>
					</Stack>
					

					<Stack direction="row" flex={2} spacing={1} alignItems="center">
						<Box flex={1.5} px={3} pl={4}>
							<DonorIndicators donor={donor}/>
						</Box>
						
						<Box textAlign="right" pr={.5} >
							{!!rosterNames.length ? <Tooltip arrow title={`Active on ${rosterNames?.length} roster${rosterNames?.length > 1 ? 's': ''}: ${rosterNames?.join(', ')}`}>
								<Box position="relative">
									<ContentPaste color="disabled" fontSize="medium" mr={1} />
									{rosterNames.length > 1  && <Box sx={{position:"absolute", top:6, right:0, left:4, width:16, textAlign:"center", color:theme.palette.info.main, fontSize:12, fontWeight:800}}>{rosterNames.length > 9 ? "..." : rosterNames.length} </Box>}
								</Box>
							</Tooltip> : <ContentPaste color="disabled" fontSize="medium" mr={1} sx={{visibility: 'hidden'}}/>}
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</ListItemButton>
	</ListItem>;
}

function DonorGroup(props) {
	const {group, roster, color, count, title, selected, onSelect, icon, isShared, sharedWith} = props;

	return <ListItem disableGutters disablePadding divider>
		<ListItemButton role={undefined} selected={selected} onClick={()=>{onSelect(roster,group)}}>
			<ListItemIcon>
				{icon ? icon : (!!roster ? (isShared ? <AssignmentReturnedOutlined sx={{transform: "scaleX(1)"}}/> : (sharedWith?.length ? <ContentPasteGo/> : <ContentPaste/>)) : <Groups/>)}
			</ListItemIcon>
			<ListItemText sx={{flex:1}}>
				{title}
			</ListItemText>
			{!!count && <Chip sx={{fontWeight: 600, borderWidth:1, minWidth: 32}} variant={selected ? "filled" : "outlined"} size="small" label={count} color={color}></Chip>}
		</ListItemButton>
	</ListItem>

}

function Sharing(props) {
	const {sharing, roster, rosterName, unit, onClose, onShare, onTerminate} = props;

	const [toUnit, setToUnit] = useState(null);
	const [endDate, setEndDate] = useState('');

	const share = async ()=>{
		var result = await jax.post(`/app/admin/donors/${unit.id}/rosters/${roster}/share`, {unit_id: toUnit.id, end_date: endDate});
		onShare(result.id, toUnit, endDate);
	}

	const terminate = async (unit_id, share_id)=>{
		await jax.delete(`/app/admin/donors/${unit.id}/rosters/${roster}/share/${share_id}`);
		onTerminate(unit_id, share_id);
	}

	return <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
		<Box className="card-header">Share {rosterName}</Box>
		<DialogContent sx={{fontSize:"0.9rem", p:0}} dividers={sharing?.length == 0} >
			<Box p={2} pb={1}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Box>Share this roster with other commands that may required access to donor information for {unit.name}</Box>
				</Stack>
				<Stack py={2} >
					<Stack direction="row" spacing={2} alignItems="center" pt={1} flex={1} justifyContent="space-between" className="sub-title">
						<Box flex={1.5}>
							<UnitSelector label="Share with" unit={toUnit} onSelect={(u)=>setToUnit(u)} />
						</Box>
						<Box>
							<TextField type="date"  InputLabelProps={{shrink:true}} value={endDate}  onChange={(e)=>{setEndDate(e.currentTarget.value)}} label="Expiration" size="small" variant="outlined" />	
						</Box>
						<Box flex={1}/>
						<Box>
							<Button size="medium" variant="contained" startIcon={<ContentPasteGo/>} disabled={!toUnit} onClick={share}>Share</Button>
						</Box>
					</Stack>
					<Box className="sub-title xs" pt={2}>
						An expiration date can optionally be specified to limit the duration of the sharing agreement.
					</Box>
				</Stack>
			</Box>
			
			{<Box className="sub-title" mt={0}>
				{!!sharing?.length && <Divider>Currently Shared With</Divider>}
				{!sharing?.length && <Box p={4} pt={0} textAlign="center">This roster is not currently shared with any other commands</Box>}
			</Box>}

			<List dense>
				{sharing.map((s,i)=> <ListItem key={i} divider>
						<Stack direction="row" spacing={1} py={1} alignItems="center" justifyContent="space-between" flex={1}>
							<AgencyAvatar agency_id={s.agency_id} size={32}/>
							<Box flex={1} sx={{fontSize:'0.9rem'}}>
								<b>{s.unit}</b>&nbsp;
								{s.end_date && `until`} <b>{asDate(s.end_date)}</b>
							</Box>
							
							<Box flex={1}/>
							<Box ><Button size="small" variant="outlined" onClick={()=>terminate(s.unit_id, s.id)}>Terminate</Button></Box>
							
						</Stack>
					</ListItem>
				)}

			</List>

		</DialogContent>
		<DialogActions sx={{justifyContent:"center"}}>
			<Button size="small" onClick={onClose}>Close</Button>
		</DialogActions>
	</Dialog>
}

export default function Donors(props) {

	const emptytFilters = {
		blood_type: "",
		low_titer: "",
		verified: "",
		tactical_score: 0,
		venous_score: 0,
		vasovagal_score: 0,
		weight_score: 0,
		fill_score: 0,
	};

	const emptyFilterOpts = {
		blood_type: false,
		low_titer: false,
		verified: false,
		tactical_score: false,
		venous_score: false,
		vasovagal_score: false,
		weight_score: false,
		fill_score: false,
	};

	const {unit, isActive} = props;
	const {user} = useSelector((state) => state.data);
	const [addDonors, setAddDonors] = useState(false);
	const [rosters, setRosters] = useState(null);
	const [rosterCounts, setRosterCounts] = useState(null);
	// const [roster, setRoster] = useState(null);
	// const [subGroup, setSubgroup] = useState(null);
	const [view, setView] = useState({group: "all", roster: null});
	const [roster, setRoster] = useState(null);
	const [donors, setDonors] = useState([]);
	const [sharing, setSharing] = useState([]);
	const [hitCount, setHitCount] = useState(0);
	const {config} = useSelector((state) => state.data);
	const [selected, setSelected] = useState([]);
	const [showRosterMenu, setShowRosterMenu] = useState(null);
	const [showPrintMenu, setShowPrintMenu] = useState(null);
	const [showShareMenu, setShowShareMenu] = useState(null);
	const [showConfirmRelease, setShowConfirmRelease] = useState(null);
	const [showNewRoster, setShowNewRoster] = useState(false);
	const [showRosterNoticeFor, setShowRosterNoticeFor] = useState(false);
	const [verifyDonors, setVerifyDonors] = useState(null);
	const [newRosterName, setNewRosterName] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [showDonor, setShowDonor] = useState(null);
	
	const [pageTitle, setPageTitle] = useState("All Donors");
	const [editRoster, setEditRoster] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(null);
	const [rosterName, setRosterName] = useState("");
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);
	const theme = useTheme();
	const tenantInfo = useSelector(state=>state.app.tenantInfo);
	const search_client = getSearchClient(tenantInfo?.search_key);
	const {id_token} = useSelector((state) => state.data);
	const [showFilters, setShowFilters] = useState(false);
	const [filters,setFilters] = useState(emptytFilters);
	const [filterOpts, setFilterOpts] = useState(emptyFilterOpts);
	const [activeFilters, setActiveFilters] = useState(0);

	const handleSelect = (donor, isSelected) => {
		var sel = []
		if (isSelected) {
			sel = [...selected, donor];
		} else {
			sel = selected.filter((x)=>x != donor)
		}
		setSelected(sel);
	}

	useEffect(()=> {
		setRosters(null);
		updateRosterCounts();
		if (unit) {
			jax.get(`/app/admin/donors/${unit.id}/rosters`).then(data=>{
				setRosters(data.rosters.map((r)=>{
					return {...r, shared: r.unit_id != unit.id};
				}));
			});
		}
	}, [unit]);

	async function loadDonors() {
		if (view.roster && !searchTerm.length) {
			var results = await jax.get(`/app/admin/donors/${unit.id}/rosters/${view.roster}`);
			setDonors(results.donors);
			setSharing(results.sharing);
			setRoster(results.roster);
			setPages(1);
			setHitCount(results.donors.length);
		} else {
			
			const index = search_client.initIndex(config.donor_index);

			//ALWAYS filter by OKAY_TO_DONATE
			var facets = [`okay_to_donate:1`];

			if (view.roster) {
				facets.push(`all_units:${unit.id}`);
				facets.push(`roster_ids:${view.roster}`);
			} else {
				facets.push(`all_units:${unit.id}`);
				// facets.push(`unit_id:${unit.id}`);
				if (view.group == "LTOWB") {
					facets.push(`unit_verified_LTOWB:${unit.id}`);
				} else if (view.group == "unverified") {
					facets.push(`unit_unverified:${unit.id}`);
				} else if (view.group == "invalidated") {
					facets.push(`unit_invalidated:${unit.id}`);
				} 

				Object.keys(filters).forEach((k)=>{
					if (filterOpts[k]) {
						facets.push(`${k}:${(filters[k] < 0) ? '\\' : ''}${filters[k]}`); //Algolia requires a "-" sign to be escaped
					} 
				});
				
			}

			// console.log(facets);

			var query = searchTerm.length >= 3 ? searchTerm : null;

			await index.search(query || '*', {
				facets: ['roster_ids', 'unit_id', 'unit_verified_LTOWB', 'unit_verified', 'unit_unverified','unit_invalidated'],
				facetFilters: facets,
				page: page-1,
				hitsPerPage: 100,
				responseFields: ['nbHits', 'nbPages', 'hits', 'facets'],
			}).then(results => {
				setDonors(results.hits);
				setPages(results.nbPages);
				setHitCount(results.nbHits);
				
			});
		}
	}

	async function updateRosterCounts() {
		console.log('Update Roster Counts');
		const index = search_client.initIndex(config.donor_index);
		search_client.clearCache();
		var facets = [`all_units:${unit.id}`, 'okay_to_donate:1'];
		
		await index.search('*', {
			facets: ['roster_ids', 'unit_id', 'unit_verified_LTOWB', 'unit_verified', 'unit_unverified','unit_invalidated'],
			facetFilters: facets,
			page: page-1,
			hitsPerPage: 100,
			responseFields: ['facets', 'nbHits'],
		}).then(results => {

			setRosterCounts({
				...results.facets?.roster_ids,
				"all": results.facets?.unit_id ? results.facets?.unit_id[unit.id] : null, 
				"LTOWB": results.facets.unit_verified_LTOWB ? results.facets.unit_verified_LTOWB[unit.id] :  null, 
				"unverified": results.facets.unit_unverified ? results.facets.unit_unverified[unit.id] : null, 
				"invalidated": results.facets.unit_invalidated ? results.facets.unit_invalidated[unit.id] : null
			});
		});

	}

	const updateDonor = (donor) => {
		var d = donors.find((d)=>d.id == donor.id);
		Object.assign(d, donor);
		setDonors([...donors]);
	}

	useEffect(()=>{
		loadDonors();
		setActiveFilters(Object.keys(filterOpts).filter(k=>filterOpts[k]).length);
	}, [searchTerm, page, filters, filterOpts], [ "searchTerm", "page", "filters", "filterOpts"]);
	

	useEffect(()=>{
		setPage(1);
		
		setFilters(emptytFilters);
		setFilterOpts(emptyFilterOpts);

		setEditRoster(null);
		setSearchTerm("");
		setSelected([]);
		if (view.roster) {
			var name = rosters.find((r)=>r.id == view.roster).name;
			setPageTitle(name);
			setRosterName(name);
		} else {
			setRoster(null);
			if (view.group == "LTOWB") {
				setPageTitle("Verified LTOWB Donors");
			} else if (view.group == "unverified") {
				setPageTitle("Unverified Donors");
			} else if (view.group == "invalidated") {
				setPageTitle("Invalidated Results");
			} else {
				setPageTitle("All Donors");
			}
		}
	}, [view], ["roster", "subGroup"]);

	useEffect(()=>{
		setConfirmDelete(false);
	}, [editRoster]);

	function donorVerified(donor) {
		var d = donors.find(d=>d.id == donor.id); 
		if (d) { 
			d.verified = 1;
		}
		setDonors(donors.filter((d)=>!d.verified && !d.invalidated));
	}

	function donorInvalidated(donor) {
		var d = donors.find(d=>d.id == donor.id); 
		if (d) { 
			d.invalidated = 1;
		}
		setDonors(donors.filter((d)=>!d.verified && !d.invalidated));
	}

	async function addToRoster(roster_id) {
		try{
			var result = await jax.post(`/app/admin/donors/${unit.id}/rosters/add`, {uuids: selected, roster: roster_id});
			
			var records = selected.map((s)=>{
				var d = donors.find((d)=>d.uuid == s);
				d.roster_ids.push(roster_id);
				d.roster_names.push(rosters.find((r)=>r.id == roster_id).name);
			});
			setRosterCounts({...rosterCounts, [roster_id]: (rosterCounts[roster_id] || 0) + result.changed});
			setDonors([...donors]);
			setSelected([]);
			setShowRosterMenu(null);
		} catch(err) {
			console.error(err);
		}
	}

	async function removeFromRoster() {
		try{
			var result = await jax.post(`/app/admin/donors/${unit.id}/rosters/remove`, {uuids: selected, roster: view.roster});
			setRosterCounts({...rosterCounts, [view.roster]: (rosterCounts[view.roster] || 0) - result.changed});
			setDonors(donors.filter((d)=>!selected.includes(d.uuid)));
			setSelected([]);
			setShowRosterMenu(null);
		} catch(err) {

		}
	}

	async function createRoster(e){
		e.preventDefault();
		
		try {
			var result = await jax.put(`/app/admin/donors/${unit.id}/rosters`, {
				name: newRosterName
			});
			
			setRosters([...rosters, {name: newRosterName, id: result.id, unit_id: unit.id }]);
			setShowNewRoster(false);
			setNewRosterName("");
		} catch (err) {
			console.error(err);
		}
		
	}

	async function saveRosterName(e) {
		e.preventDefault();
		await jax.post(`/app/admin/donors/${unit.id}/rosters/${view.roster}`, {name: rosterName});
		setEditRoster(false);
		var rs = rosters.map((r)=>{
			if (r.id == view.roster) {
				r.name = rosterName;
			}
			return r;
		});
		setRosters(rs);
		setRoster({...roster, name: rosterName});
	}

	async function deleteRoster() {
		await jax.delete(`/app/admin/donors/${unit.id}/rosters/${view.roster}`);
		setRosters(rosters.filter((r)=>r.id != view.roster));
		setView({roster: null, group: null});
		setEditRoster(false);
	}

	async function share(id, unit, end_date) {
		setSharing([...sharing, {id: id, unit_id: unit.id, agency_id: unit.agency_id, unit: unit.name, end_date: end_date}]);
	}

	async function release_sharing(share_id) {
		await jax.delete(`/app/admin/donors/${unit.id}/rosters/${view.roster}/release/${share_id}`);
		setRosters(rosters.filter((r)=>r.id != view.roster));
		setView({roster: null, group: null});
		setRoster(null);
		setShowConfirmRelease(null);
	}

	async function unshare(unit_id, share_id) {
		setSharing(sharing.filter(x=>x.id != share_id));
		
		var rs = rosters.map(r=>{
			if (r.id == view.roster) {
				r.shared_with = r.shared_with.filter(x=>x.unit_id != unit_id);
			}
			return r;
		});
	}

	const printFormRef = useRef();

	async function printDoc(e,layout, acknowledge) {
		if (!acknowledge && donors.some(d=>d.verified === 0 || d.invalidated === 1)) {
			setShowRosterNoticeFor(layout);
			return;
		}
		setShowRosterNoticeFor(false);
		printFormRef.current.setAttribute('action', `/app/admin/donors/${unit?.id}/rosters/${view.roster}/print/donor_${layout}`);
		printFormRef.current.submit();
	}	

	return <Stack spacing={{xs:0, lg:0}} direction={{xs:"column", lg:"row"}}>
		<DonorProfile open={addDonors || !!showDonor} donor={showDonor} unit={unit} onClose={()=>{setAddDonors(false); setShowDonor(null); updateRosterCounts();}} onUpdateDonor={updateDonor}></DonorProfile>
		<Sharing open={!!showShareMenu} sharing={sharing} unit={unit} roster={view.roster} rosterName={rosterName} onClose={()=>setShowShareMenu(false)} onShare={share} onTerminate={(unit_id, share_id)=>unshare(unit_id, share_id)}/>
		{/* LISTS AND ROSTERS */}
		<Box flex={1}  flexShrink={0} pb={0} > 
			<List className={`rosters`}>
				<DonorGroup title="All Registered Donors" selected={!view.roster && !view.group} count={rosterCounts?.all} roster={null} group={null} color="info" onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
				<DonorGroup title="Verified LTOWB Donors" icon={<Verified/>} selected={!view.roster && view.group == "LTOWB"} count={rosterCounts?.LTOWB} roster={null} group="LTOWB" color="success" onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
				<DonorGroup title="Unverified Donors" icon={<Warning/>} selected={!view.roster && view.group == "unverified"} count={rosterCounts?.unverified} roster={null} group="unverified" color="warning" onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
				<DonorGroup title="Invalidated Results" icon={<DoNotDisturb/>} selected={!view.roster && view.group == "invalidated"} count={rosterCounts?.invalidated} roster={null} group="invalidated" color="error" onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
				
				<ListItem divider >
					<Stack direction="row" justifyContent="space-between" alignItems="center" pt={2} flex={1}>
						<Box className="sub-title primary">Rosters for <b>{unit.name}</b></Box>
						{<Can action={actions.CREATE_UNIT_DONOR_ROSTER} id={unit.id}><Box flex={1} sx={{visibility: showNewRoster ? "hidden" : ""}}><IconButton color="primary" size="small" onClick={()=>setShowNewRoster(true)}><AddCircleOutline fontSize="inherit"/></IconButton></Box></Can>}
					</Stack>
				</ListItem>

				{showNewRoster && <ListItem disableGutters disablePadding color="secondary">
					<Box flex={1} p={1}>
						<form onSubmit={createRoster} >
							<TextField 
								fullWidth 
								size="small"
								variant="outlined"
								autoFocus={true}
								value={newRosterName}
								onChange={(e)=>setNewRosterName(e.target.value)}
								InputProps={{
									sx:{paddingRight:1},
									endAdornment: <ButtonGroup><IconButton size="small" disabled={!newRosterName} type="submit"><Check fontSize="inherit"/></IconButton><IconButton size="small" onClick={()=>setShowNewRoster(false)}><Close fontSize="inherit"/></IconButton></ButtonGroup>
								}}
								 />
						</form>	
					</Box>
				</ListItem>}

				{!showNewRoster && !rosters?.some(r=>!r.shared) && <>
					<ListItem  >
						<Box flex={1} pt={1} className="sub-title">
							No rosters have been created for this command.  Rosters allow for organization of donors into sub-groups and can be shared with other commands.
							<Box flex={1} mt={2} textAlign="center">
								<Button size="small" startIcon={<AddCircleOutline/>} onClick={()=>setShowNewRoster(true)}>Create a Roster</Button>
							</Box>
						</Box>
						
					</ListItem>
				</>}

				{rosters?.filter(r=>!r.shared).map((r,i)=>{
					return <DonorGroup key={i} title={r.name} selected={view.roster == r.id} count={rosterCounts && rosterCounts[r.id]} roster={r.id} sharedWith={r.shared_with} group={null} color="info" isShared={r.unit_id != unit.id} onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
				})}
				
				
				
				{rosters?.some(r=>r.shared) && <>
					<ListItem divider >
						<Box flex={1} pt={2} className="sub-title primary">
							Shared Rosters
						</Box>
					</ListItem>
					
					
					{rosters?.filter(r=>r.shared).map((r,i)=>{
						return <DonorGroup key={i} title={r.name} selected={view.roster == r.id} count={rosterCounts && rosterCounts[r.id]} roster={r.id} group={null} color="info" isShared={r.unit_id != unit.id} onSelect={(r,g)=>{setView({roster:r, group:g})}}/>
					})}
				</>}
			</List>
		</Box>

		<Divider orientation="vertical" flexItem></Divider>

		<Box flex={3}  flexShrink={0} pb={0}>
			{/* HEADER */}
			<Stack direction="row" p={2} pr={1} spacing={2} justifyContent="space-between" alignItems="center">
				<Box>
					<Stack direction="row"  spacing={0.5} flex={1} alignItems="center">
						{!editRoster && <Typography variant="h5">{pageTitle}</Typography>}
						{view.roster && !editRoster && !view.roster.shared && <IconButton onClick={()=>setEditRoster(true)} color="disabled" size="small"><Edit fontSize="16px"/></IconButton>}
						{view.roster && editRoster && <form onSubmit={saveRosterName}>
							<Stack direction="row" spacing={0.5} alignItems="center">
								<TextField value={rosterName} autoFocus size="small" variant="outlined" onChange={(e)=>{setRosterName( e.currentTarget.value)}} disabled={!editRoster} InputProps={{
									endAdornment:<Stack direction="row">
										<IconButton size="small" type="submit"><Check fontSize="inherit"/></IconButton>
										<IconButton size="small" onClick={()=>setEditRoster(false)}><DoDisturb fontSize="inherit"/></IconButton>
									</Stack>}}></TextField>
								<Button size="small" onClick={(e)=>{setConfirmDelete(e.currentTarget)}} color="error" startIcon={<Delete sx={{marginRight:"-2px"}}/>}>DELETE</Button>
								<Notification 
									anchorEl={confirmDelete}
									open={!!confirmDelete}
									maxWidth={300}
									background={theme.palette.error.main}
									color="white"
									onClose={()=>setConfirmDelete(null)}>
										Are you sure you want to delete this donor roster?  Donors will be disassociated with this roster but will otherwise remain unaffected.
										<Box pt={1} textAlign="center"><Button size="small" color="white" variant="outlined" onClick={()=>deleteRoster()}>Yes, continue</Button></Box>
								</Notification>
							</Stack>
						</form>}
						
					</Stack>
				</Box>
					
				<Box flexShrink={0} minWidth={250}>
					<form onSubmit={(e)=>{e.preventDefault(); }}>
						<TextField
							value={searchTerm}
							placeholder={`Search ${pageTitle}`}
							variant="outlined"
							size="small"
							fullWidth
							onChange={(e)=>setSearchTerm(e.currentTarget.value)}
							InputProps={{
								sx: {paddingLeft:1, paddingRight:1}, 
								startAdornment: <Search fontSize="inherit" sx={{paddingRight:0.5}}/>,
								endAdornment: !!searchTerm ? <IconButton size="small" onClick={()=>{setSearchTerm(""); }}><Close fontSize="inherit"/></IconButton> : null
							}}/>
					</form>
				</Box>
			</Stack>


			{/* LIST DESCRIPTION */}
			<Box px={2} pb={2}>
				{!!roster?.shared && roster.unit_id != unit.id && <Box className="sub-title">
					This roster of donors is managed by <b>{roster?.home_unit}</b>{roster?.shared_until && <> and has been shared with this command through <b>{asDate(roster?.shared_until)}</b></>}.
					These donors may be utilized in local command rosters.
				</Box>}

				{view.group == "unverified" && <Box className="sub-title">
					These donors have pending screening results that must be reviewed before they are considered verified.
				</Box>}

				{view.group == "invalidated" && <Box className="sub-title">
					These donors have been reviewed and discrepencies were identified in their lab results.  New screening information must be submitted in order to verify these donors.
				</Box>}

				{!!sharing?.length && <Box className="sub-title">
					This roster is currently shared with: <b>{sharing.map(x=>x.unit).join(', ')}</b>
				</Box>}
			</Box>

			{/* LIST CONTROLS */}
			<Stack direction="row" py={1} px={1} spacing={1} sx={{borderBottom: "1px solid #ccc"}}>
				<Box pl={0}>
					<Checkbox
						color="primary"
						mr={0}
						sx={{padding:0.5}}
						disabled={hitCount == 0}
						onChange={(e)=>{e.stopPropagation(); setSelected(e.currentTarget.checked ? donors.map((u)=>u.uuid) : [])}}
					/>
				</Box>
				
				{view.roster && <form ref={printFormRef}  target="print" method="post" action="" >
					<input type="hidden" name="id_token" value={id_token}/>
					<input type="hidden" name="title" value={view.roster.name}/>
					<input type="hidden" name="sub_title" value={unit?.name}/>
					<input type="hidden" name="printed_by" value={user.title}/>
					<input type="hidden" name="printed_on" value={asDate()}/>
					<Dialog open={showRosterNoticeFor} onClose={()=>setShowRosterNoticeFor(false)} title="Notice">
						<Alert severity="warning">
							<AlertTitle>Unverified Donors</AlertTitle>
							One or more donors in this roster have not been verified or have invalidate screening results.  These donors will <b>NOT</b> be included on this document.
							<DialogActions>
								<Button size="small" onClick={()=>setShowRosterNoticeFor(false)}>Cancel</Button>
								<Button variant="contained" size="small" onClick={()=>printDoc(null,showRosterNoticeFor, true)}>Continue</Button>
							</DialogActions>
						</Alert>
					</Dialog>
					<Can action={actions.PRINT_UNIT_DONOR_DOCUMENTS} id={unit.id}><Button size="small" variant="contained" startIcon={<Print/>} endIcon={<ArrowDropDown/>} onClick={(e)=>setShowPrintMenu(e.currentTarget)}>Print</Button></Can>
					<Menu
						anchorEl={showPrintMenu}
						open={!!showPrintMenu}
						anchorOrigin={{vertical:"bottom", horizontal:"left"}}
						transformOrigin={{vertical:"top", horizontal:"left"}}
						onClose={()=>setShowPrintMenu(null)}>
							<MenuList>
								<MenuItem onClick={(e)=>{printDoc(e,'roster')}}>
									<ListItemIcon>
										<ContentPaste/>
									</ListItemIcon>
									<ListItemText>
										Donor Roster
									</ListItemText>
								</MenuItem>
								<MenuItem onClick={(e)=>{printDoc(e,'badges')}}>
									<ListItemIcon>
										<NoteOutlined/>
									</ListItemIcon>
									<ListItemText>
										Blood Donor Labels
									</ListItemText>
								</MenuItem>
							</MenuList>
					</Menu>
				</form>}
				
				{!view.roster && view.group != "invalidated" && view.group != "unverified" && <Can action={actions.REGISTER_UNIT_DONOR} id={unit.id}><Button flex={1} size="small" variant="contained" color="secondary" onClick={()=>{setShowDonor(null); setAddDonors(true);}}  startIcon={<HowToReg/>}>Register Donor(s)</Button></Can>}

				<DonorVerification open={!!verifyDonors?.length} donors={verifyDonors} onClose={()=>{setVerifyDonors(null); setTimeout(()=> updateRosterCounts(), 1000)}} onVerify={donorVerified} onInvalidate={donorInvalidated} unit={unit}/>
				{view.group == 'unverified' && <Can action={actions.VERIFY_UNIT_DONORS} id={unit.id}><Button size="small" variant="contained" color="warning" disabled={!donors?.length} startIcon={<Verified/>} onClick={()=>{setVerifyDonors([...donors]);}}>Begin Verification</Button></Can>}

				{view.group != "unverified" && view.group != "invalidated" && <Can action={actions.CREATE_UNIT_DONOR_ROSTER} id={unit.id}><Button size="small" variant="contained" disabled={!selected?.length} startIcon={<GroupAdd/>} endIcon={<ArrowDropDown/>} onClick={(e)=>setShowRosterMenu(e.currentTarget)}>Add to Roster</Button></Can>}
				{roster && roster.unit_id == unit.id && <Can action={actions.CREATE_UNIT_DONOR_ROSTER} id={unit.id}><Button size="small" variant="contained" disabled={!selected?.length} startIcon={<GroupRemove/>} onClick={removeFromRoster}>Remove from Roster</Button></Can>}
				
				<Box flex={1}></Box>
				{roster && roster.unit_id == unit.id && <Can action={actions.SHARE_UNIT_DONOR_ROSTER} id={unit.id}><Badge badgeContent={sharing?.length} sx={{".MuiBadge-badge": {border:'solid 1px white'}}} invisible={sharing?.length<1} color="info" anchorOrigin={{vertical: 'top', horizontal: 'left'}}><Button size="small" variant="contained" color="info" startIcon={<ContentPasteGo/>} onClick={(e)=>setShowShareMenu(e.currentTarget)}>Sharing</Button></Badge></Can>}
				
				{roster && roster.unit_id != unit.id && roster.shared && <Can action={actions.RELEASE_UNIT_DONOR_ROSTER} id={unit.id}><Button size="small" variant="contained" color="info" startIcon={<ContentPasteGo/>} onClick={(e)=>setShowConfirmRelease(e.currentTarget)}>Release Roster</Button></Can>}
				<Notification
					anchorEl={showConfirmRelease}
					open={!!showConfirmRelease}
					maxWidth={300}
					background={theme.palette.info.main}
					color="white"
					onClose={()=>setShowConfirmRelease(null)}>
						Do you want to release this shared roster? The donors from this roster will no longer be visible to this command.
						<Box pt={1} textAlign="center"><Button size="small" color="white" variant="outlined" onClick={()=>release_sharing(roster.share_id)}>Yes, continue</Button></Box>
				</Notification>
				

				{!view.roster && <Button size="small" variant={showFilters || activeFilters ? "contained" : "outlined"} color={showFilters || activeFilters ? "warning" : "disabled"} sx={{lineHeight: 1}} onClick={()=>!showFilters && setShowFilters(!showFilters)} startIcon={<FilterList/>} >
					{activeFilters > 0 && activeFilters} Filter{activeFilters > 1 && "s"}
				</Button>}
				<Menu
					id="roster-menu"
					anchorEl={showRosterMenu}
					open={!!showRosterMenu}
					anchorOrigin={{vertical:"bottom", horizontal:"left"}}
					transformOrigin={{vertical:"top", horizontal:"left"}}
					onClose={()=>setShowRosterMenu(null)}>
					{rosterCounts && rosters?.filter(x=>x.unit_id == unit.id).map((r,i)=>{
						return <MenuItem key={i} onClick={()=>{addToRoster(r.id)}}>
							<ListItemIcon>
								{r.unit_id != unit.id ? <AssignmentReturnOutlined/> : <ContentPaste/>}
							</ListItemIcon>
							<ListItemText>
								{r.name} {rosterCounts[r.id] && `(${rosterCounts && rosterCounts[r.id]})`}
							</ListItemText>
						</MenuItem>
					})}
				</Menu>

				
				
			</Stack>

			{/* DONOR LIST */}
			<List sx={{position:"relative", minHeight:640}} >
				
				{/* FILTERS */}
				{true && <Paper className={`filter-drawer ${showFilters && 'open'}`} elevation={2} square>
					<ClickAwayListener onClickAway={()=>setShowFilters(false)} mouseEvent={showFilters && "onMouseUp"}  >
						
						<Box p={2} minWidth={300}>
							<Stack spacing={2}>
								<FormControl disabled={!filterOpts.blood_type}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.blood_type} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, blood_type: v})}/>
										<Box mr={2} flex={1}><FormLabel  >Blood Type</FormLabel></Box>
										<Box flex={1}>
											<Select
												fullWidth
												size="small"
												value={filters.blood_type || ""}
												onChange={(e)=>setFilters({...filters, blood_type: e.target.value})}>
													<MenuItem value="">All</MenuItem>
													<MenuItem value="universal">Universal</MenuItem>
													<MenuItem value="non_universal">Non-Universal</MenuItem>
													<Divider></Divider>
													<MenuItem value="O NEG">O NEG</MenuItem>
													<MenuItem value="O POS">O POS</MenuItem>
													<MenuItem value="A NEG">A NEG</MenuItem>
													<MenuItem value="A POS">A POS</MenuItem>
													<MenuItem value="B NEG">B NEG</MenuItem>
													<MenuItem value="B POS">B POS</MenuItem>
													<MenuItem value="AB NEG">AB NEG</MenuItem>
													<MenuItem value="AB POS">AB POS</MenuItem>
											</Select>
										</Box>
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.low_titer} >
									<Stack direction="row" alignItems="center" spacing={1}>
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.low_titer || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, low_titer: v})}/>
										<Box mr={2} flex={1}><FormLabel  >Titer</FormLabel></Box>	
										<RadioGroup row value={filters.low_titer || ""} onChange={(e,v)=>{setFilters({...filters, low_titer: v})}}>
											<FormControlLabel label="Low" control={<Radio sx={{p:"4px"}} size="small" value={1}/>}/>
											<FormControlLabel label="High" control={<Radio sx={{p:"4px"}} size="small" value={0}/>}/>
										</RadioGroup>
										
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.verified}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.verified || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, verified: v})}/>
										<Box mr={2} flex={1}><FormLabel  >Verified</FormLabel></Box>	
										<RadioGroup row value={filters.verified || ""} onChange={(e,v)=>{setFilters({...filters, verified: v})}}>
											<FormControlLabel label="Yes" control={<Radio sx={{p:"4px"}} size="small" value={1}/>}/>
											<FormControlLabel label="No" control={<Radio sx={{p:"4px"}} size="small" value={0}/>}/>
										</RadioGroup>
										
									</Stack>
								</FormControl>
								
								<Divider variant="middle"></Divider>

								<FormControl disabled={!filterOpts.tactical_score}>
									<Stack direction="row" alignItems="flex-start"  spacing={1} >
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.tactical_score || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, tactical_score: v})}/>
										<Stack pt={.5} flex={1}>
											<Box mr={2} flex={1}><FormLabel  >Tactical Impact</FormLabel></Box>
											{filterOpts.tactical_score && <Box mt={1} ml={1}>
												<FactorSlider value={filters.tactical_score} minLabel="Key Leader" maxLabel="Low Impact" highColor="success" lowColor="error" disabled={!filterOpts.tactical_score} onChange={(e,v)=>setFilters({...filters, tactical_score:v})}/>
											</Box>}
										</Stack>
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.vasovagal_score}>
									<Stack direction="row" alignItems="flex-start"  spacing={1} >
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.vasovagal_score || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, vasovagal_score: v})}/>
										<Stack pt={.5} flex={1}>
											<Box mr={2} flex={1}><FormLabel  >Vasovagal Response</FormLabel></Box>
											{filterOpts.vasovagal_score && <Box mt={1} ml={1}>
												<FactorSlider value={filters.vasovagal_score} minLabel="Poor" maxLabel="Resilient" highColor="success" lowColor="error" disabled={!filterOpts.vasovagal_score} onChange={(e,v)=>setFilters({...filters, vasovagal_score:v})}/>
											</Box>}
										</Stack>
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.fill_score}>
									<Stack direction="row" alignItems="flex-start"  spacing={1} >
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.fill_score || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, fill_score: v})}/>
										<Stack pt={.5} flex={1}>
											<Box mr={2} flex={1}><FormLabel  >Fill Speed</FormLabel></Box>
											{filterOpts.fill_score && <Box mt={1} ml={1}>
												<FactorSlider value={filters.fill_score} minLabel="Slow" maxLabel="Fast" highColor="success" lowColor="error" disabled={!filterOpts.fill_score} onChange={(e,v)=>setFilters({...filters, fill_score:v})}/>
											</Box>}
										</Stack>
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.venous_score}>
									<Stack direction="row" alignItems="flex-start"  spacing={1} >
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.venous_score || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, venous_score: v})}/>
										<Stack pt={.5} flex={1}>
											<Box mr={2} flex={1}><FormLabel  >Venous Access</FormLabel></Box>
											{filterOpts.venous_score && <Box mt={1} ml={1}>
												<FactorSlider value={filters.venous_score} minLabel="Difficult" maxLabel="Easy" highColor="success" lowColor="error" disabled={!filterOpts.venous_score} onChange={(e,v)=>setFilters({...filters, venous_score:v})}/>
											</Box>}
										</Stack>
									</Stack>
								</FormControl>

								<FormControl disabled={!filterOpts.weight_score}>
									<Stack direction="row" alignItems="flex-start"  spacing={1} >
										<Checkbox disabled={false} sx={{p:"4px"}} checked={filterOpts.weight_score || false} value={true} onChange={(e,v)=>setFilterOpts({...filterOpts, weight_score: v})}/>
										<Stack pt={.5} flex={1}>
											<Box mr={2} flex={1}><FormLabel  >Body Weight</FormLabel></Box>
											{filterOpts.weight_score && <Box mt={1} ml={1}>
												<FactorSlider value={filters.weight_score} minLabel="< 130lbs (or Female)" maxLabel="> 220lbs" highColor="success" lowColor="error" disabled={!filterOpts.weight_score} onChange={(e,v)=>setFilters({...filters, weight_score:v})}/>
											</Box>}
										</Stack>
									</Stack>
								</FormControl>

								

							</Stack>
						</Box>
					</ClickAwayListener>
				</Paper>}
				
				{donors.filter(d=>d.blood_type.startsWith('O') && d.low_titer==1).map((d,i)=>{
					return  <React.Fragment key={i}>{i == 0 && <><ListSubheader key="uni" sx={{paddingTop: 2}}>Universal Donors</ListSubheader><Divider></Divider></>}
					<Donor key={i} donor={d} rosters={rosters} onSelect={handleSelect} selected={!!selected.find(x=>x==d.uuid)} onClick={()=>setShowDonor(d)} shared={d.unit_id != unit.id}></Donor></React.Fragment>;
				})}

				{donors.filter(d=>!d.blood_type.startsWith('O') || d.low_titer!=1).map((d,i)=>{
					return  <React.Fragment key={i}>{i == 0 && <><ListSubheader key="non-uni" sx={{paddingTop: 2}}>Non-Universal Donors</ListSubheader><Divider></Divider></>}
					<Donor key={i} donor={d} rosters={rosters} onSelect={handleSelect} selected={!!selected.find(x=>x==d.uuid)} onClick={()=>setShowDonor(d)} shared={d.unit_id != unit.id}></Donor></React.Fragment>;
				})}
			</List>

			{/* PAGINATION */}
			{pages > 1 && <>
				<Divider flex={1}></Divider>
				<Stack  alignItems="center">
					<Box p={2} >
						<Pagination count={pages} page={page} onChange={(e, p)=>{setPage(p); }}></Pagination>
					</Box>
				</Stack>
			</>}
		</Box>

	</Stack>;
}
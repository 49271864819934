
import algoliasearch from 'algoliasearch';
import { CallEnum } from '@algolia/transporter';

export const getSearchClient = (key) => {

	const algOptions = process.env.REACT_APP_USE_SEARCH_PROXY === "true" ? {
		// hosts: [{ protocol: window.location.protocol.replace(':',''), url: `algolia.valkyrietraining.org`, accept: CallEnum.Read}]
		hosts: [{ protocol: window.location.protocol.replace(':',''), url: `${window.location.host}/app/search`, accept: CallEnum.Read}],
		timeouts: {
			connect: 5, // connection timeout in seconds
			read: 10, // read timeout in seconds
			write: 30 // write timeout in seconds
		  }
	} : {};
	
	var client = algoliasearch('P3M95K0JOU', key, algOptions);
	return client;
	
}

//export const getSearchClient = state =>selectSearchClient(state);
import { createTheme, palette} from '@mui/material/styles';

import './themeEx.tsx';

const theme = createTheme({
  palette: {
    tonalOffset: 0.25, 
  }
});

export default createTheme(theme, {
  palette: {
    mode: 'light',
    contrastThreshold: 3,
    primary: theme.palette.augmentColor({ color:{main:'#6c782e'}}),
    // primary: theme.palette.augmentColor({ color:{main:'#607D8B'}}),
    error: theme.palette.augmentColor({ color:{main:'#d32f2f', pale: '#FFCDD2'}}), //
    secondary: theme.palette.augmentColor({ color:{main:'#455a64'}}),
    success: theme.palette.augmentColor({ color:{main:'#6c782e', pale: '#e6eedd'}}),
    info: theme.palette.augmentColor({ color:{main:'#607D8B', pale: '#CFD8DC'}}),
    warning: theme.palette.augmentColor({ color:{main:'#ed6c02', pale: '#FEE0B2'}}),
    white: { 
      main:'#ffffff', 
      dark:'#ffffff', 
      light:'#ffffff', 
      contrastText:'#000000'
    },
    black: { 
      main:'#000000', 
      dark:'#000000', 
      light:'#000000', 
      contrastText:'#ffffff'
    },
    gold: theme.palette.augmentColor({ color:{main:'#d2c412', contrastText:'#FFFFFF'}}),
    disabled: { 
      main:'#777777', 
      dark:'#333333', 
      light:'#999999', 
      contrastText:'#ffffff'
    },
    important: theme.palette.augmentColor({ color:{main:'#ffd54c'}})
  },
});
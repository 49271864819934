import { useEffect, useState } from "react";
import jax from "../helper/jax";
import { Box, Breadcrumbs, CircularProgress, Divider, IconButton, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Article, AttachFile, CoPresent, FileDownload, Folder, KeyboardReturn } from "@mui/icons-material";
import { set } from 'react-hook-form';
import { useEffectDebugger } from "../helper/util";

export default function FileExplorer(props) {
	const {root, homeFolderName, downloads} = props;

	const [path, setPath] = useState([]);
	const [files, setFiles] = useState([]);
	const [folders, setFolders] = useState([]);
	const [parent, setParent] = useState(null);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(null);
	// const [canDownload, setCanDownload] = useState(download);

	useEffect(()=>{
		if (root && path.length > 0) {
			setPath([]);
		}
	}, [root]);

	useEffect(()=>{
		var pathStr = path.join("/");
		setLoading(true);
		// setCanDownload(homeFolderName.indexOf('Reference') != 0);
		jax.get(`/app/resources/${root}/${path?.join('/') || ""}`, null, {no_feedback: true}).then((response)=>{
			setFiles(response.files.map(f=>{
				var parts = f.name.replace(/(.+)\..*?$/,'$1').split('~')
				return {
					id: f.id,
					name: parts[0].trim(),
					type: f.type,
					subtitle: parts[1]?.trim(),
					url: f.url,
					downloadUrl: f.downloadUrl
				};
			}));
			setFolders(response.folders);
			setParent(response.parent);
			setLoading(false);
		});
	}, [path, root]);

	async function preview(file) {
		var link = await jax.get(`/app/resources/${file.id}/preview`);
		window.open(link.url, file.id);
	}

	async function download(file) {
		setDownloading(file.id);
		var link = await jax.get(`/app/resources/${file.id}/download`);
		
		
		fetch(link.url, {
			method: 'GET',
			headers: {
				'Content-Type': link.type,
			},
		}).then(response => response.blob())
			.then(blob => {
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				//Create a DOMString representing the blob and point the link element towards it
				let url = window.URL.createObjectURL(new Blob([blob]));
				a.href = url;
				a.download = link.name;
				//programatically click the link to trigger the download
				a.click();
				//release the reference to the file by revoking the Object URL
				window.URL.revokeObjectURL(url);
				setDownloading(null);
			});

	}

	const fileTypes = {
		"application/pdf": <Article color="error"/>,
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": <CoPresent color="info" mr={1}/>,
	}

	return <Box> 
		<List>
			{path.length > 0 && <Box p={1}>
				<Breadcrumbs>
					{path.length && <Link sx={{cursor:"pointer"}} onClick={()=>{setPath([])}}>{homeFolderName || "Resources"}</Link>}
					{path.map((p,i)=>{
						return i == path.length-1  ? <Typography key={i}>{p}</Typography> : <Link key={i} sx={{cursor:"pointer"}} onClick={()=>{setPath(path.slice(0, i+1))}}>{p}</Link>
					})}
				</Breadcrumbs>
			</Box>
			}
			<Divider/>

			{loading ? <Box p={4}><LinearProgress></LinearProgress></Box> : <Box>
				{folders.map((folder, i)=>{
					return <ListItem disablePadding key={i}>  
						<ListItemButton onClick={()=>{setPath([...path, folder.name])}} alignItems="flex-start">
							<ListItemAvatar sx={{minWidth:0, mr:0.75, mt:0.5}}><Folder color="warning"></Folder></ListItemAvatar>
							<ListItemText primary={folder.name}></ListItemText>
						</ListItemButton>
					</ListItem>
				})}

				{files.map((file, i)=>{
					return <ListItem disablePadding key={i} secondaryAction={
						!!file.downloadUrl && downloads && <IconButton edge="end" size="small" aria-label="comments" onClick={()=>{download(file)}}>
						  {downloading == file.id ? <CircularProgress size="24px" /> : <FileDownload />}
						</IconButton>
					  }> 
						<ListItemButton onClick={()=>{preview(file)}} alignItems="flex-start">
							<ListItemAvatar sx={{minWidth:0, mr:0.75, mt:0.5}}>{fileTypes[file.type] || <AttachFile/>}</ListItemAvatar>
							<ListItemText sx={{mt:0.5}} primary={file.name} secondary={file.subtitle}></ListItemText>
						</ListItemButton>
					</ListItem>
				})}
			</Box>}
		</List>
	</Box>
}
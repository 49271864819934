import { useRef, useEffect } from 'react';
import moment from 'moment';
import  theme  from '../theme';
const utils = {
	useTraceUpdate: function(props) {
		const prev = useRef(props);
		useEffect(() => {
			const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
			}, {});
			if (Object.keys(changedProps).length > 0) {
			console.log('Changed props:', changedProps);
			}
			prev.current = props;
		});
	},
	asDate(date, fmt) {
		moment.utc();
		if (!date) return null;
		if (typeof date === 'string') return moment.utc(date).format(fmt || 'MM/DD/YYYY');
		if (typeof date === 'number') return moment.unix(date).format(fmt || 'MM/DD/YYYY');
		return moment.utc(date).format(fmt || 'MM/DD/YYYY');
	},
	today() {
		return moment.utc().format('YYYY-MM-DD');
	},
	utc_date() {
		return moment.utc().unix();
	},
	evaluate: (condition, context) => {
		return (new Function(...Object.keys(context), `return ${condition}`))(...Object.values(context));
	},
	debounce(func, timeout = 300){
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	},
	
	getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
		  let c = ca[i];
		  while (c.charAt(0) == ' ') {
			c = c.substring(1);
		  }
		  if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		  }
		}
		return "";
	},
	copyToClipboard: async function(text) {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(text);
			return;
		} else  if (window.clipboardData && window.clipboardData.setData) {
		  	// IE: prevent textarea being shown while dialog is visible
			return window.clipboardData.setData("Text", text);
		} else if (document.queryCommandSupported && 
				   document.queryCommandSupported("copy")) {
		  var textarea = document.createElement("textarea");
		  textarea.textContent = text;
		  // Prevent scrolling to bottom of page in MS Edge
		  textarea.style.position = "fixed";
		  document.body.appendChild(textarea);
		  textarea.select();
		  try {
			// Security exception may be thrown by some browsers
			return document.execCommand("copy");
		  } catch (ex) {
			console.warn("Copy to clipboard failed.", ex);
			return false;
		  } finally {
			document.body.removeChild(textarea);
		  }
		}
	  },
	tagColors: [
		'#A4C400',
		'#60A917',
		'#008A00',
		'#00ABA9',
		'#1BA1E2',
		'#0050EF',
		'#6A00FF',
		'#AA00FF',
		'#F472D0',
		'#D80073',
		'#A20025',
		'#E51400',
		'#FA6800',
		'#F0A30A',
		'#E3C800',
		'#825A2C',
		'#6D8764',
		'#647687',
		'#76608A',
		'#A0522D'
	],
	courseStatusMap: {
		"": {
			label: "None",
			icon: "Block",
			color: '#999999',
		},
		"-1" : {
			label: "Disenrolled",
			icon: "Block",
			color: theme.palette.error.main,
		},
		"1" : {
			label: "Graduated",
			icon: "TaskAlt",
			color: theme.palette.success.main,
		},
		"2" : {
			label: "Graduated w. Distinction",
			icon: "Stars",
			color: '#B28900',
		},
		"0" : {
			label: "Add'l Training Required",
			icon: "MoreTime",
			color: "#999999",
		}
	},
	maskHas(mask, value) {
		return (mask & value) === value;
	},
	maskHasAny(mask, values) {
		for (var i = 0; i < values.length; i++) {
			if ((mask & values[i]) == values[i]) return true;
		}
		return false;
	},
	maskHasAll(mask, values) {
		for (var i = 0; i < values.length; i++) {
			if ((mask & values[i]) != values[i]) return false;
		}
		return true;
	},
	unique: function(arr,key) {
		return arr.filter((v, i, self) => self.findIndex((x)=>v[key]==x[key]) === i).map(x=>x[key]);
	},
	useEffectDebugger(effectHook, dependencies, dependencyNames = []) {

		const usePrevious = (value, initialValue) => {
			const ref = useRef(initialValue);
			useEffect(() => {
			  ref.current = value;
			});
			return ref.current;
		  };

		const previousDeps = usePrevious(dependencies, []);
	  
		const changedDeps = dependencies.reduce((accum, dependency, index) => {
		  if (dependency !== previousDeps[index]) {
			const keyName = dependencyNames[index] || index;
			return {
			  ...accum,
			  [keyName]: {
				before: previousDeps[index],
				after: dependency
			  }
			};
		  }
	  
		  return accum;
		}, {});
	  
		if (Object.keys(changedDeps).length) {
		  console.log('[use-effect-debugger] ', changedDeps);
		}
	  
		useEffect(effectHook, dependencies);
	}
}
  export const {useTraceUpdate, asDate, utc_date, getCookie, today, evaluate, debounce, unique, copyToClipboard, courseStatusMap, useEffectDebugger, maskHas, maskHasAll, maskHasAny, tagColors} = utils;
import { Alert, Avatar, Box, Breadcrumbs, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, Link, List, ListItem, ListItemButton, MenuItem, Popper, Select, Skeleton, Stack, TextField, Typography } from "@mui/material"
import { StrictMode, useEffect, useRef, useState } from "react"
import jax from "../helper/jax"
import { Add,Create,  EditNote, Error, Lock, MoveUp, RemoveCircleOutline } from "@mui/icons-material";
import { set } from 'react-hook-form';
import { useSelector } from "react-redux";
import { Router, useNavigate, useParams } from "react-router-dom";

import { asDate, debounce, maskHas, maskHasAny } from "../helper/util";
import { actions, can, unitRoleNames, unitRoles } from "../features/access";
import UserResult from "../components/UserResult";
import { Link as RouterLink } from "react-router-dom";
import UserSearch from "../components/UserSearch";
import UnitSearch from "../components/UnitSearch";
import ProfileDialog from "../components/ProfileDialog";
import AccessGrantor from "../components/AccessGrantor";
import UnitSelector from "../components/UnitSelector";
import { hasUnitRole } from "../features/access";
import { useTheme } from "@emotion/react";
import Notification from "../components/Notification";


export const DetachUnit = (props)=> {
	const {unit, onClose, onFinish} = props;
	// const user = useSelector(state=>state.data.user);
	const [newParent, setNewParent] = useState(null);
	const [currentParentId, setCurrentParentId] = useState(null);
	
	const [notes, setNotes] = useState(null);
	const [error, setError] = useState(null);

	function transfer() {

		if (!newParent) {
			setError("Please select a new superior command.");
			return;
		}

		if (!notes) {
			setError("Please provide a reason for the transfer.");
			return;
		}

		if (newParent.id == currentParentId) {
			setError("A unit cannot be transferred to itself.");
			return;
		}

		if (newParent.parent_ids.includes(unit.id)) {
			setError("A unit cannot be transferred to one of its own subordinates.");
			return;
		}

		jax.post(`/app/admin/units/${unit.id}/transfer`, {
			unit_id: unit.id,
			parent_id: newParent.id,
			notes: notes
		}).then((response)=>{
			onFinish();
			onClose();
		});
	}

	useEffect(()=>{
		if (unit) {
			var current = unit.parent_ids[unit.parent_ids.length-1];
			setCurrentParentId(current);
		} else {
			setCurrentParentId(null);
			setNewParent(null);
			setNotes(null);
			setError(null)
		}
	}, [unit])

	return <Dialog open={!!unit} onClose={onClose}>
		{unit && <DialogContent dividers>
			<Stack spacing={3}>
			<h3 style={{marginTop:4}}>Transfer Request: {unit.name}</h3>
				<Box >
					
					<Typography>To transfer <b>{unit.name}</b>, please specify a new superior command.  The transfer will be effective pending acceptance by the Command Manager at the receving command.</Typography>
				</Box>
				<Box>
					<UnitSelector label="New Superior Command" onSelect={(unit)=>setNewParent(unit)} unit={newParent} managedOnly={false} />
				</Box>
				<Box>
					<FormControl size="small" fullWidth >
						<TextField label="Notes / Reason for Transfer" defaultValue={notes} onChange={(e)=>setNotes(e.currentTarget.value)} fullWidth multiline rows={4} ></TextField>
					</FormControl>
				</Box>
				{error && <Stack direction="row" color="error" >
					<Error color="error"></Error>
					<Typography color="error" alignItems="center"><Box ml={1}>{error}</Box></Typography>
				</Stack>}
			</Stack>
		</DialogContent>}
		<DialogActions>
			<Stack flex={1} px={2} pb={1} direction="row" justifyContent="space-between">
				<Button size="small" variant="text" onClick={onClose}>Cancel</Button>
				<Button size="small" variant="contained" onClick={transfer} disabled={!newParent || newParent.id == currentParentId}>Submit Transfer</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

export const UnitList = function(props) {
	const {units, parent, onSelect, onDetach, onConfirmTransfer, canModify, onCreateNew} = props;
	const nav = useNavigate();
	const {agencies} = useSelector(state=>state.data);
	const user = useSelector(state=>state.data.user);
	const theme = useTheme();

	return <Box>
		<List>
			{units.map((unit, i)=>{
				var agency = agencies.find(x=>x.id == unit.agency_id);	
				return <Box key={i} className="unit" sx={{backgroundColor: theme.palette.warning.background}}>
					<ListItem sx={{padding:0}}>
					<ListItemButton onClick={(e)=>onSelect(unit.unit_id)} disabled={parent != null && unit.transfer_new_parent_id == parent?.id} sx={{ opacity: '1 !important'}}>
						<Stack direction={{xs:"column"}} justifyContent="flex-start" alignItems={{lg:"stretch", xs: "stretch"}} spacing={1} flex={1}>
							<Stack direction="row" className={!!unit.pending_transfer ? "fade-more" : ''} sx={{flex:1}} justifyContent="space-between">
								<Box>
									<Avatar src={`/images/agency/${agency?.abbr}.png`} sx={{border:"solid 2px white", height: 30, cursor: 'pointer', width: 30, mr:1 }} />
								</Box>
								<Stack flex={1} direction={{lg:"row", xs:"column"}} justifyContent="flex-start">
									<Box>
										<Typography fontWeight={500}>{unit.unit_name}</Typography>
										<Box className="sub-title">{unit.location}</Box>
									</Box>
									{!unit.pending_transfer_id && <>
										<Box flex={1}></Box>
										<Stack direction={{xs:"row", lg:"column"}} pt={{xs:1, lg:0}} minWidth={40} spacing={{xs:2, lg:0.5}} justifyContent="center" alignItems={{xs:"center", lg:"stretch"}}>
											
											<Box flex={1} maxHeight="5px" height="5px" flexShrink={1}>
												<ReadyBar current={unit.valks} pending={unit.students} target={unit.target_valks}></ReadyBar>
											</Box>
											
											<Box flex={1} maxHeight="5px" height="5px">
												<ReadyBar current={unit.instructors} pending={unit.iuts} target={unit.target_instructors}></ReadyBar>
											</Box>
											
											<Box flex={1} maxHeight="5px" height="5px">
												<ReadyBar current={unit.verified_donors} pending={unit.unverified_donors} target={unit.target_donors}></ReadyBar>
											</Box>
										</Stack>
									</>}
								</Stack>
							</Stack>
							{parent != null && unit.pending_transfer_id && unit.transfer_new_parent_id == parent?.id && (
								<Alert sx={{padding:"4px 12px", border: `solid 1px ${theme.palette.warning.dark}`, "& .MuiAlert-icon":{padding: 0}, "& .MuiAlert-message": {padding:"2px 0"}}} icon={<MoveUp sx={{color:theme.palette.warning.dark}}/>} severity="warning">
									This command is pending transfer from <b>{unit.transfer_old_parent}</b>.<br/>
									{hasUnitRole(user, parent, unitRoles.COMMAND_MGR) && <Link onClick={(e)=>{e.stopPropagation(); onConfirmTransfer(unit.pending_transfer_id)}} sx={{pointerEvents: "auto"}}>Approve or Reject</Link>}
								</Alert>
							)}
							{parent != null && unit.transfer_old_parent_id == parent?.id && <Alert sx={{padding:"4px 12px", border: `solid 1px ${theme.palette.warning.dark}`, "& .MuiAlert-icon":{padding: 0}, "& .MuiAlert-message": {padding:"2px 0"}}} icon={<MoveUp sx={{color:theme.palette.warning.dark}}/>} severity="warning">This command is pending transfer to <b>{unit.transfer_new_parent}</b>.</Alert>}
						</Stack>
					</ListItemButton>
					</ListItem>
					{i < units.length-1 && <Divider></Divider>}
					
				</Box>;
			})}
			{canModify &&  <>
				<Divider></Divider>
				<ListItemButton onClick={onCreateNew} prefix="adsf" pl={3}>
					<Box pl={0.5}><Lock  className="primary" sx={{fontSize: "1.25rem"}}></Lock></Box>
					<Box className="primary" textAlign="center" p={1} pl={1}>Add Command</Box>
				</ListItemButton>
				</>}
		</List>
	</Box>
}

export const ReadyBar = (props) => {
	const {current, pending, target} = props;
	var max = target ? Math.max(current+pending, target) : Math.max(current+pending, 1);
	const theme = useTheme();
	var color = theme.palette.primary.main;
	if (target) {
		if ((current+pending) / target < 0.5) {
			color = theme.palette.error.main;
		} else if ((current+pending) / target < 0.75) {
			color = theme.palette.warning.main;
		}
	}
	return <Stack flex={1} height={1} width="100%" direction="row" alignItems="center" justifyContent="flex-start" sx={{background: `${color}33`}}>
		{current > 0 && <Box height="100%" width={`${Math.min(100, Math.max(0, current / max * 100))}%`} sx={{backgroundColor: color}}></Box>}
		{current > 0 && (pending > 0 || current < max)  && <Box height="100%" backgroundColor="#fff" width="2px"></Box>}
		{pending > 0 && <Box height="100%" width={`${Math.min(100, Math.max(0, (pending) / max * 100))}%`} sx={{backgroundColor: `${color}66`}}></Box>}
		{pending > 0 && (pending + current < max) && <Box height="100%" backgroundColor="#fff" width="2px"></Box>}
		
	</Stack>;
	
}

export const  ReadyChart  = (props) =>{
	const theme = useTheme();
	const {title, data, pendingText, readyText, onEditTarget} = props;
	const [editTarget, setEditTarget] = useState(null);
	const [target, setTarget] = useState(null);
	const [editTargetValue, setEditTargetValue] = useState(null);
	
	
	var draw = {current:0, pending:0, target:0};
	
	if (data) {
		
		draw = {
			current: data.current,
			pending: data.pending
		};		
		
		
	}
	
	useEffect(()=>{
		if (data) {
			setEditTargetValue(data.inferred ? '' : (data.target || ''));
			setTarget(data.target || '');
		}
	}, [data]);
	
	return <Stack p={3} direction="column" alignItems="flex-start" justifyContent="flex-start" flex={1} spacing={0.5} >
		<Box pt={0.5}>
			<h2 className="grey dark thin">{title}</h2>
		</Box>
		<Box  width="100%" height={6} position="relative">
			{data && <ReadyBar current={draw.current} pending={draw.pending} target={target}></ReadyBar>}
		</Box>
		<Stack pt={1} pb={2} direction={{xs:"row", lg:"column"}} justifyContent="space-between" alignItems="flex-start" width="100%" spacing={.25}>
			<Box >
				<Typography variant="body1" fontSize="1.05rem" textTransform="" lineHeight={1.25} className="primary"><b>{data?.current} {readyText || "Ready"}</b></Typography>
			</Box>
			<Box>
				<Typography variant="body1" fontSize="1.05rem" textTransform="" lineHeight={1.25} className="primary light"><b>{data?.pending}</b> {pendingText || "Training"}</Typography>
			</Box>
			<Box>
				<Typography variant="body1" fontSize="1.05rem" textTransform="" lineHeight={1.25} className={data?.inferred ? "chart-light" : ""}>
					Target: <b>{data?.target ? data.target : '---'}</b> 
					<IconButton size="small"  sx={{padding: '2px'}} onClick={(e)=>{setEditTarget(e.currentTarget);}}><Create sx={{fontSize:"1rem", color:"#999"}} /></IconButton>
					<Notification
						anchorEl={editTarget}
						sx={{p:0, pt:0}}
						open={!!editTarget}
						onClose={()=>setEditTarget(null)}
						ContainerProps={{padding:0}}
						PaperProps={{
							elevation: 0,
							p: 0,
							sx: {padding:0}
						}}>
							<Stack p={2} spacing={2} maxWidth={200}>
								<Box className="sub-title" >
									Set the number of {title} required by this command.  Leave empty to infer this number from subordinate commands.
								</Box>

								<form onSubmit={(e)=>{e.preventDefault(); onEditTarget(target); setEditTarget(null);}}>
								<TextField 
									label="Target"
									size="small"
									variant="outlined"
									defaultValue=""
									value={editTargetValue}
									onChange={(e)=>{setTarget(e.target.value); setEditTargetValue(e.target.value);}}
									InputProps={{
										sx: {paddingRight:"2px !important"},
										endAdornment: <Button type="submit" disabled={target == data?.target} size="small" >Save</Button>
									}}
									></TextField>
								</form>					
							</Stack>

						</Notification>
						
				</Typography>
			</Box>
		</Stack>
	</Stack>;
}

export const AuthoritativeRoles = function(props) {
	const {unit, roles, showRoles} = props;
	const unitRoles = useSelector(state=>state.data.unit_roles);
	return <Box className="sub-title">
		{unitRoles.filter(r=>maskHas(roles,r.unit_role_id) && showRoles.includes(r.unit_role_id)).map(r=>r.name).join(', ')}
		{unit && <> at <RouterLink to={`/commands/${unit.id}`} onClick={(e)=>e.stopPropagation()}> {unit.name}</RouterLink></>}
	</Box>
}

export const AdminList = function(props) {

	const {title, addText, readOnly, admins, unit, onRemove, onShowProfile, onFinish, roles, noSuperiors} = props;
	const {user} = useSelector((state) => state.data);
	const [appointUser, setAppointUser] = useState(false);
	const [editAccessUser, setEditAccessUser] = useState(null);

	const removeAdmin = async(uid, scope) => {
		var result = await jax.post(`/app/admin/units/${unit.id}/appoint`, {
			user_id: uid,
			unit_id: unit.id,
			roles: 0,
			role_scope: scope //don't close out roles outside of this role scope
		});

		if (result) {
			onRemove();
		}
	}

	
	return unit ? <Box><Stack px={1} py={0} mt={{lg:2, xs:0}} direction="row" justifyContent="space-between" alignItems="center">
		<Box py={1}>
			<h3 style={{margin:4}}>{title}</h3>
		</Box>
		
		{can(user, actions.APPOINT_UNIT_ROLES, unit.id) && !readOnly && <Button variant="text" size="small" onClick={()=>{setAppointUser(true)}} sx={{display: {xs:'none', lg: 'block'}}}>
			+ {addText}</Button>}
	</Stack>

	{can(user, actions.APPOINT_UNIT_ROLES, unit.id) && <AccessGrantor 
		unit={unit} 
		user={editAccessUser} 
		show={appointUser || editAccessUser}
		roles={editAccessUser?.roles} 
		title={editAccessUser ? "Modify Support Roles" : "Add a Support Role"}
		intro={editAccessUser ? `Change the command role(s) for <b>${editAccessUser.name}</b> to...` : `Add a support role to <b>${unit.name}</b> by selecting a role and a user.`}
		roleOptions={roles} 
		onClose={()=>{setAppointUser(null); setEditAccessUser(null)}} 
		onFinish={onFinish}></AccessGrantor>}

	<Divider></Divider>
	{!admins?.filter(x=>maskHasAny(x.roles,roles)).length && <Box p={2}>
		No support staff have been assigned to this command.
	</Box>}
	<List py={0}>
		{admins?.filter(x=>maskHasAny(x.roles,roles)).sort((a,b)=>a-b).map((admin, i)=>{
			return <ListItem key={i} sx={{padding:0,}} className={admin.authoritative_unit_id != unit.id ? "fade-unless-hovered" : ''} secondaryAction={
				can(user, actions.APPOINT_UNIT_ROLES, unit.id) && admin.uid != user.uid && !readOnly && <Stack ml={1} direction="row" alignItems="center">
					<IconButton edge="end" className="fade-unless-hovered" onClick={()=>{setEditAccessUser(admin)}} size="small"><EditNote/></IconButton>
					<IconButton edge="end" className="fade-unless-hovered" onClick={()=>{removeAdmin(admin.uid, roles.reduce((acc, val)=>acc | val, 0))}} size="small"><RemoveCircleOutline/></IconButton>
				</Stack>
			}> 
				<ListItemButton onClick={()=>onShowProfile(admin.uid)} sx={{px:1, pr:3}}>
					<UserResult user={admin} subTitle={<AuthoritativeRoles unit={admin.authoritative_unit_id != unit.id && {id: admin.authoritative_unit_id, name: admin.authoritative_unit}} showRoles={roles} roles={admin.roles}/>}></UserResult>
				</ListItemButton>
				
			</ListItem>;
		})}
		<ListItem sx={{display: {xs:'', lg: 'none'}}}>
			<ListItemButton onClick={()=>{setAppointUser(true)}} className="primary" sx={{p:1, pt:0, flex:1, display: "block", textAlign: "center"}} >
				<Box className="primary">
					+ {addText}
				</Box>
				
			</ListItemButton>
		</ListItem>
	</List></Box> : <></>;
}

export const Transfer = function(props) {
	const {id, unit_id, onClose, onFinish, cancel} = props;
	const [notes, setNotes] = useState("");
	const [error, setError] = useState(null);
	const [transfer, setTransfer] = useState(null);
	const [decision, setDecision] = useState(-1);

	function confirm() {
		
		if (!notes) {
			setError("Please provide a reason for the transfer.");
			return;
		}

		if (cancel) {
			jax.delete(`/app/admin/units/${unit_id}/transfer/${transfer.transfer_id}`, {
				notes: notes
			}).then((response)=>{
				onClose();
				onFinish();
			});
		} else {
			var action = decision == 1 ? 'approve' : 'reject';

			jax.post(`/app/admin/units/${unit_id}/transfer/${transfer.transfer_id}/${action}`, {
				notes: notes
			}).then((response)=>{
				onClose();
				onFinish();
			});
		}
	}

	useEffect(()=>{
		if (id) {
			jax.get(`/app/admin/units/${unit_id}/transfer/${id}`).then((response)=>{
				setTransfer(response.transfer);
			});
		} else {
			setTransfer(null);
			setNotes("");
			setError(null);
			setDecision(-1);
		}
	}, [id])
	return <Dialog open={!!id} onClose={()=>{props.onClose()}}>
		<DialogTitle sx={{padding:1}} typography={{variant:"h6"}} p={0}>{cancel ? "Cancel Transfer Request" : "Approve Transfer Request"}</DialogTitle>
		<DialogContent dividers>
			{!cancel && <Box>
				The following transfer of command requires your approval:
			</Box>}
			{cancel && <Box>
				Please confirm cancellation of the following transfer request:
			</Box>}
			<Stack spacing={{xs:1, lg:6}} py={2} direction={{xs:"column", lg:"row"}}>
				<Stack spacing={2} p={0}>
					<Box>
						<Box className="sub-title primary">
							Command:
						</Box>
						<Box>
							{transfer ? transfer.unit_name : <Skeleton></Skeleton>}
						</Box>
					</Box>
					<Box>
						<Box className="sub-title primary">
							Deatching from:
						</Box>
						<Box>
							{transfer ? transfer.old_parent_name : <Skeleton></Skeleton>}
						</Box>
					</Box>
					<Box>
						<Box className="sub-title primary">
							Assigning to:
						</Box>
						<Box>
							{transfer ? transfer.new_parent_name : <Skeleton></Skeleton>}
						</Box>
					</Box>
				</Stack>
				<Stack spacing={2} p={0}>
					<Box>
						<Box className="sub-title primary">
							Intiated by:
						</Box>
						<Box>
							{transfer ? `${transfer.initiator_rank} ${transfer.initiator_name}` : <Skeleton></Skeleton>}
						</Box>
					</Box>
					<Box>
						<Box className="sub-title primary">
							Intiated on:
						</Box>
						<Box>
							{transfer ? asDate(transfer.initiated_on) : <Skeleton></Skeleton>}
						</Box>
					</Box>
					<Box>
						<Box className="sub-title primary">
							Notes:
						</Box>
						<Box>
							<i>{transfer ? transfer.initiator_notes : <Skeleton></Skeleton>}</i>
						</Box>
					</Box>
				</Stack>
			</Stack>
			{!cancel && <Box mt={1}>
				<FormControl size="small" fullWidth>
					<InputLabel>Approval</InputLabel>
					<Select size="small" fullWidth label="Approval" value={decision} onChange={(e)=>setDecision(e.target.value)}>
						<MenuItem value={-1}>&nbsp;</MenuItem>
						<MenuItem value={1}>Approve</MenuItem>
						<MenuItem value={0}>Reject</MenuItem>
					</Select>
				</FormControl>
			</Box>}
			<Box mt={2}>
				<TextField label="Notes / Reason" value={notes} onChange={(e)=>setNotes(e.currentTarget.value)} fullWidth multiline rows={4} />
			</Box>
			{!!error && <Box mt={2}>
				<Typography color="error">{error}</Typography>
			</Box>}
		</DialogContent>
		<DialogActions>
			<Stack direction={{lg:"row", xs:"row"}} flex={1} spacing={1} alignItems="center" justifyContent="space-between">
				<Button size="small" variant="text" onClick={onClose}>Cancel</Button>
				<Box flex={1}></Box>
				<Button size="small" variant="contained" onClick={confirm} disabled={!cancel && decision == -1} color={decision===1 ? "success" : "error"}>{cancel ? "Cancel" : (!cancel && decision === 0 ? "Reject" : "Approve")} Transfer</Button>
			</Stack>
		</DialogActions>
	</Dialog>
}

export default function Units(props) {
		
	const [unit, setUnit] = useState(null);
	const [units, setUnits] = useState([]);
	
	const [parents, setParents] = useState([]);
	
	const {unit_id, agency_id, affiliation_id} = useParams();
	
	const [editing, setEditing] = useState(false);
	const [admins, setAdmins] = useState([]);
	const [showProfile, setShowProfile] = useState(null);
	const [showTransfer, setShowTransfer] = useState(null);
	const [cancelTransferId, setCancelTransferId] = useState(null);
	
	const [detachUnit, setDetachUnit] = useState(null);

	const {user} = useSelector((state) => state.data);
	const nav = useNavigate();
	const theme = useTheme();
	
	// const [affiliation, setAffiliation] = useState(affiliations[0].id);
	// const [agency, setAgency] = useState(agency_id || agencies[0].id);

	const load = async ()=>{
		jax.get(`/app/admin/units/${unit_id || ''}`).then((response)=>{
			response.admins.map((a)=>{
				a.roleNames = [];
				Object.values(unitRoles).map((r)=>{
					if ((a.roles & r) == r) {
						a.roleNames.push(unitRoleNames[r]);
					}
				});
			});
			setUnit(response.unit);
			setParents(response.parents);
			setUnits(response.units);
			setAdmins(response.admins.sort((a,b)=>unit_id == a.authoritative_unit_id ? -1 : 1));
		});
	}
	useEffect(()=>{
		load();
	},[affiliation_id, agency_id, unit_id])

	const confirmTransfer = async (id) => {
		setShowTransfer(id);
	}

	const cancelTransfer = async (id) => {
		setCancelTransferId(id);
	}

	return <StrictMode><Box>
		<ProfileDialog uid={showProfile} onClose={()=>{setShowProfile(null)}}/>
		<DetachUnit unit={detachUnit} onClose={()=>{setDetachUnit(null)}} onFinish={load}></DetachUnit>
		<Transfer id={showTransfer || cancelTransferId} unit_id={unit_id} cancel={!!cancelTransferId} onClose={()=>{setShowTransfer(null); setCancelTransferId(null)}} onFinish={load}></Transfer>
		<Stack direction={{xs:"column", lg:"row"}}  alignItems={{lg:"center", xs:"stretch"}} justifyContent={{lg:"space-between", xs:"flex-start"}} py={{xs:1, lg:2}}>
			<Box >
				{<Breadcrumbs separator="/" maxItems={4}>
					{unit ? <Link onClick={(e)=>{nav(`/commands`)}}>All Commands</Link> : <Typography  sx={{fontWeight:'bold'}}>All Commands</Typography>}
					{unit && parents.map((p, i)=>{
						return <Link key={i} onClick={(e)=>{nav(`/commands/${p.id}`)}}>{p.name}</Link>
					})}
					{unit && <Typography  sx={{fontWeight:'bold'}}>{unit.name}</Typography>}
				</Breadcrumbs>}
			</Box>
			
			<Box maxWidth={480}>
				<UnitSearch managedOnly={true} onSelect={(unit)=>{ unit && nav(`/commands/${unit.id}`);}} label="Find Command"/>
			</Box>
		</Stack>
		<Card className={` ${editing ? 'editing' : ''}`}>
			<Box sx={{position:'relative'}}>
				<Divider/>
				<Stack direction={{xs:"column", lg:"row"}} justifyContent="stretch"  flex={1}>
					{unit && <Box  flex={1}>
						<Box p={1}>
							<h3 style={{margin:4}}>{unit.name}</h3>
						</Box>
						<Divider></Divider>
						<Stack spacing={2} p={2}>
							{!!unit.pending_transfer_unit_id && <Alert  severity="warning" icon={<MoveUp sx={{color:theme.palette.warning.dark}}/>} sx={{border: `solid 1px ${theme.palette.warning.dark}`, "& .MuiAlert-icon":{padding: 0}, "& .MuiAlert-message": {padding:"2px 0"}}}>This 	 is currently pending transfer to <b>{unit.pending_transfer_unit}</b>.  
							{hasUnitRole(user, unit, unitRoles.COMMAND_MGR) && <span>  Please  <Link className="inherit" onClick={()=>cancelTransfer(unit.pending_transfer_id)}>cancel this request</Link> if it is no longer appropriate.</span>}</Alert>}
							<Box>
								<div className="sub-title">Location</div>
								<div>{unit.location}</div>
							</Box>
							<Box>
								<div className="sub-title">{unit.unit_identifier}</div>
								<div>{unit.identifier}</div>
							</Box>
							{!!parents?.length && <Stack flex={1} direction={{xs:"column", lg:"row"}} alignItems={{lg: "center", xs:"flex-start"}} justifyContent={{lg:"space-between", xs:"flex-start"}} >
								<Box>
									<div className="sub-title">Parent Command</div>
									<div>
										<RouterLink to={`/commands/${unit.parent_ids[unit.parent_ids.length-1]}`}>{unit.parent_names[unit.parent_names.length-1]}</RouterLink>
										{/* &nbsp;(<Link onClick={(e)=>{detach(unit)}}>Transfer this Command</Link>) */}
									</div>
								</Box>
								
								{hasUnitRole(user, unit, unitRoles.COMMAND_MGR) && !unit.pending_transfer_unit_id && <Box pt={{xs:2, lg:0}}><Button size="small" variant="outlined" onClick={()=>{setDetachUnit(unit)}}>Transfer Command</Button></Box>}
							</Stack>}
						</Stack>

						
						<AdminList admins={admins} title="Support Staff" unit={unit} onRemove={load} onShowProfile={uid=>setShowProfile(uid)} onFinish={load} addText="Add Support" roles={[unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.PROGRAM_ADMIN]}></AdminList>

						<AdminList admins={admins} title="Command Management" unit={unit} onRemove={load} onShowProfile={uid=>setShowProfile(uid)} onFinish={load} addText="Add Manager" roles={[unitRoles.COMMAND_MGR,unitRoles.PERSONNEL_MGR,unitRoles.DONOR_MGR]}></AdminList>				
						
						
					</Box>}
					<Divider orientation="vertical" flexItem />
					<Box flex={1.5} mt={{xs:1, lg:0}}>
						<Box p={1}>
							<h3 style={{margin:4}}>{unit ? 'Subordinate Commands' : 'All Commands'}</h3>
						</Box>
						<Divider></Divider>
						{!!units?.length && <><UnitList parent={unit} units={units} onConfirmTransfer={confirmTransfer} onSelect={(unit)=>{ nav(`/commands/${unit}`); }} /><Divider></Divider></>}
						{!units?.length && <Box flexGrow={0} flexShrink={0} p={2} textAlign="center">
							There are no subordinate available.
						</Box>}
					</Box>
				</Stack>
			</Box>
		</Card>
	</Box></StrictMode>
}
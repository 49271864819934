import { Alert, Box, Button, Divider, FormControl, IconButton, InputLabel, Link, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { DetachUnit, Transfer, UnitList, ReadyBar, ReadyChart, AdminList } from "./Commands";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { hasAnyUnitRole, hasRole, hasUnitRole, roles, unitRoles } from "../features/access";
import { useTheme } from "@emotion/react";
import { Create, Lock, MoveUp } from "@mui/icons-material";
import AddCommand from "./AddCommand";

import jax from "../helper/jax";




export default function CommandOverview(props)  {
	const {unit, subUnits, parents, onReload, onChanges, admins, onShowProfile} = props;
	const {config} = useSelector((state) => state.data);

	const [valkData, setValkData] = useState(null);
	const [instrData, setInstrData] = useState(null);
	const [donorData, setDonorData] = useState(null);

	const [editUnit, setEditUnit] = useState(null);
	const [createUnit, setCreateUnit] = useState(false);
	const [showProfile, setShowProfile] = useState(null);
	const [showTransfer, setShowTransfer] = useState(null);
	const [initialData, setInitialData] = useState(null);
	const [cancelTransferId, setCancelTransferId] = useState(null);
	const [detachUnit, setDetachUnit] = useState(null);
	const user = useSelector((state) => state.data.user);

	const theme = useTheme();
	const nav = useNavigate();

	useEffect(()=>{
		var vData = {
			name:'Valkyries', 
			current: unit.valks, 
			pending: unit.students, 
			target:  unit.target_valks,
			inferred: unit.infer_valks
		};

		var iData = {
			name:'Instructors', 
			current: unit.instructors, 
			pending: unit.iuts, 
			target:  unit.target_instructors,
			inferred: unit.infer_instructors
		};

		var dData = {
			name:'Donors', 
			current: unit.verified_donors, 
			pending: unit.unverified_donors, 
			target:  unit.target_donors,
			inferred: unit.infer_donors			
		};

		setValkData(vData);
		setInstrData(iData);
		setDonorData(dData);

		if (!initialData) {
			setInitialData(unit);
		}
	},[unit]);

	const confirmTransfer = async (id) => {
		setShowTransfer(id);
	}

	const cancelTransfer = async (id) => {
		setCancelTransferId(id);
	}

	const changeTarget = async (changes) => {
		var post = {
			target_valks: unit.infer_valks ? null : unit.target_valks,
			target_instructors: unit.infer_instructors ? null : unit.target_instructors,
			target_donors: unit.infer_donors ? null : unit.target_donors,
			infer_donors: unit.infer_donors,
			infer_instructors: unit.infer_instructors,
			infer_valks: unit.infer_valks
		};

		var update = {
			
		};

		Object.keys(changes).map((k)=>{
			post[`target_${k}`] = changes[k];
			post[`infer_${k}`] = isNaN(changes[k]);		
			
			update[`infer_${k}`] = !changes[k] ? true : false;
			if (changes[k]) {
				update[`target_${k}`] = changes[k];
			} else {
				update[`target_${k}`] = subUnits.reduce((a,c)=>a+c[`target_${k}`], 0);
			}
			
		});

		onChanges(update);

		await jax.post(`/app/admin/units/${unit.id}/targets`, post);
		
		
	}

	return <Stack direction={{xs:"column", lg:"row"}} justifyContent="stretch" alignItems={{xs:"stretch", lg: "stretch"}} >
		
		<Stack direction="column" alignItems="stretch" flex={5}>
			{!!unit.pending_transfer_unit_id && <Alert  severity="warning" icon={<MoveUp sx={{color:theme.palette.warning.dark}}/>} sx={{ borderRadius: 0, borderBottom: `solid 1px ${theme.palette.warning.dark}`, "& .MuiAlert-icon":{padding: 0}, "& .MuiAlert-message": {padding:"2px 0"}}}>This command is currently pending transfer to <b>{unit.pending_transfer_unit}</b>.  
					{hasUnitRole(user, unit, unitRoles.COMMAND_MGR) && <span>  Please  <Link className="inherit" onClick={()=>cancelTransfer(unit.pending_transfer_id)}>cancel this request</Link> if it is no longer appropriate.</span>}</Alert>}
				{false && <Box py={1}>
					
				<Stack spacing={2} p={2} direction={{lg:"row", xs:"column"}} justifyContent="space-evenly" >
					
					<Box textAlign="center" flex={1} lineHeight={1.5}>
						<div className="sub-title primary">{unit.unit_identifier}</div>
						<div>{unit.identifier}</div>
					</Box>
					<Box textAlign="center" flex={1} lineHeight={1.5}>
						<div className="sub-title primary">Location</div>
						<div>{unit.location}</div>
					</Box>
					
					
					{!!parents?.length &&
						<Box textAlign="center" flex={1} lineHeight={1.5}>
							<div className="sub-title primary">Parent Command</div>
							<div>
								<RouterLink to={`/admin/${unit.parent_ids[unit.parent_ids.length-1]}`}>{unit.parent_names[unit.parent_names.length-1]}</RouterLink>
								
							</div>
						</Box>}

					
				</Stack>
			</Box>}

			{/* <Divider></Divider> */}
			
			<Stack p={0} direction={{xs:"column", lg:"row"}} alignItems="stretch">
				<ReadyChart data={valkData} title="Valkyries" onEditTarget={(v)=>changeTarget({valks: isNaN(v) ? null : parseInt(v)})}/>
				<Divider sx={{mx:0}} flexItem orientation="vertical"></Divider>
				<ReadyChart data={instrData} title="Instructors" onEditTarget={(v)=>changeTarget({instructors: isNaN(v) ? null : parseInt(v)})}/>
				<Divider sx={{mx:0}} flexItem orientation="vertical"></Divider>
				<ReadyChart data={donorData} title="Donors" readyText="Approved" pendingText="Under Review" onEditTarget={(v)=>changeTarget({donors: isNaN(v) ? null : parseInt(v)})}/>					
			</Stack>
			
			<Divider textAlign="right">
				
			</Divider>

			<Stack direction={{xs:"column", lg:"row"}}>
				<Box flex={1} >
					<AdminList admins={admins?.filter(x=>x.authoritative_unit_id == unit?.id)} readOnly={true} onShowProfile={onShowProfile} title="Command Management" unit={unit} roles={[unitRoles.COMMAND_MGR, unitRoles.MEDICAL_OFFICER, unitRoles.PERSONNEL_MGR, unitRoles.DONOR_MGR]}></AdminList>				
				</Box>
				<Divider orientation="vertical" flexItem></Divider>
				<Box flex={1} >
					<AdminList admins={admins?.filter(x=>x.authoritative_unit_id == unit?.id)} readOnly={true} onShowProfile={onShowProfile} title="Support Staff" unit={unit} roles={[unitRoles.BLOOD_DRIVE_COORD, unitRoles.BLOOD_SPECIALIST, unitRoles.PROGRAM_ADMIN]}></AdminList>
				</Box>
			</Stack>

			<Divider></Divider>
			<Stack direction="row" spacing={1} p={2} justifyContent="flex-end" alignItems="flex-start" flex={1}>
				{hasRole(user, roles.ADMIN) && <Box ><Button  size="small" variant="outlined" onClick={()=>{setEditUnit(unit)}}><Lock sx={{fontSize: "1rem", marginRight:1}}></Lock> Modify Command Details</Button></Box>}
				{!!parents?.length && hasUnitRole(user, unit, unitRoles.COMMAND_MGR) && !unit.pending_transfer_unit_id && <Button size="small" variant="outlined" onClick={()=>{setDetachUnit(unit)}}>Transfer This Command</Button>}
			</Stack>

			{hasRole(user, roles.ADMIN) &&  <AddCommand unit={editUnit} parent_id={unit.id} open={editUnit || createUnit} onClose={()=>{setEditUnit(null); setCreateUnit(false)}} onAdd={(id)=>nav(`/admin/${id}`)} onUpdate={onReload} />}

			<DetachUnit unit={detachUnit} onClose={()=>{setDetachUnit(null)}} onFinish={onReload}></DetachUnit>
			<Transfer id={showTransfer || cancelTransferId} unit_id={unit.id} cancel={!!cancelTransferId} onClose={()=>{setShowTransfer(null); setCancelTransferId(null)}} onFinish={onReload}></Transfer>
		</Stack>	
		<Divider flexItem orientation="vertical"></Divider>
		<Box flex={3}>
			<Box p={1} pl={2}><Typography variant="h6">Subordinate Commands</Typography></Box>
			<Divider></Divider>
			<UnitList parent={unit} units={subUnits} onSelect={(unit)=>nav(`/admin/${unit}`)} canModify={hasRole(user, roles.ADMIN)} onCreateNew={()=>setCreateUnit(unit)} onConfirmTransfer={confirmTransfer} />
		</Box>
	</Stack>
}
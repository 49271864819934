
import { useTheme } from '@mui/material/styles';
import { Box, Paper, Stack, Typography, alpha } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Bar, BarChart, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ProgramGradsProps {
	data: Array<any>,
	height?: number
}

const CustomTooltip = (props) => {
	const {payload, label, active} = props;
	if (active && payload && payload.length) {
		var course_name = payload[0].payload.name;

		var data = payload[0].payload;
		return (
			
			<Paper elevation={3} sx={{p:1}}>
				<Box className='sub-title xs'>{moment(label).format('yyyy-MM-DD')}</Box>
				<Box>
					<Typography sx={{fontSize: '1rem', fontWeight:"bold"}}>{course_name}</Typography>
				</Box>
				
				<Stack sx={{fontSize:'0.8rem', textTransform:'capitalize'}} mt={1} spacing={0.5}>
				
				{payload?.map((p,i)=>{
					if (!p.name || !p.value) {
						return null;
					}
					return <Stack direction="row" spacing={1} alignItems="center">
						<Box height={18} width={18} borderRadius="2px" bgcolor={p.color} />	
						<Box color="#666" key={i}><b>{p.name}: {p.value}</b></Box>
					</Stack>;
				})}
					
				
				</Stack>
				
			</Paper>
		);
	}
  
	return null;
  };

const ProgramGrads: React.FC<ProgramGradsProps> = (props) => {
	
	const [data, setData] = React.useState<Array<any>>([]);
	const [domain, setDomain] = React.useState<Array<number>>([moment().valueOf(),0]);
	
	const theme = useTheme();
	useEffect(() => {

		
		var mapped=props.data?.map((item:any)=>{
			return {
				...item,
				sDiff: item.students-item.graduates,
				iDiff: item.IUTs-item.instructor_graduates,
				uDate: moment(item.date).valueOf(),
				tooltip: [
					{label: "Students", value: item.students},
					{label: "Graduates", value: item.graduates},
					{label: "IUTs", value: item.IUTs},
					{label: "Instructor Grads", value: item.instructor_graduates},
				]
			}
		});

		var dmn = mapped?.reduce((acc, item) => {
			if (item.uDate < acc.min) { 
				acc.min = item.uDate;
			}
			if (item.uDate > acc.max) {
				acc.max = item.uDate;
			}
			return acc;
		}, {min: domain[0], max: domain[1]});

		setData(mapped);
		dmn && setDomain([dmn.min, dmn.max]);
	},[props.data]);

	return (
		<Box p={2} position="relative">
			<ResponsiveContainer width="100%" height={props.height || 400} >
				<BarChart  data={data}>
					<Tooltip content={<CustomTooltip />} cursor={{fill:'#00000011'}}/>
					<XAxis className='sub-title xs' interval="preserveStartEnd" tickCount={3} padding={{left: 30, right: 30}} dataKey="uDate"  type="number" domain={domain} tickFormatter={(v)=>moment(v).format("MM/DD/yyyy")} />
						<Bar name="Graduates"  label="Graduates" dataKey="graduates" stackId="v" fill={theme.palette.primary.main} />
						<Bar name="Non-Graduates" tooltipType="none" dataKey="sDiff" stackId="v" fill={alpha(theme.palette.primary.main, 0.25)} />
						<Bar name="Instructor Grads." label="Instructors" dataKey="instructor_graduates" stackId="i" fill={theme.palette.info.main}/>
						<Bar name="Instructor Non-Grads." dataKey="iDiff" stackId="i" fill={alpha(theme.palette.info.main, 0.25)} />
					<YAxis className='sub-title xs'/>
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default ProgramGrads;
import cookies from 'js-cookie';
import {getAuth} from 'firebase/auth';
import app from './firebase';

const issue_request = async function(method, url, data, config = {}) {
	
	var token = null;
	var provider = null;
	var auth = getAuth(app);
	if (auth && auth.currentUser) {
		token = await auth.currentUser.getIdToken();
		provider = auth.currentUser.providerId;
	}
	// token = cookies.get('id_token');
	
	return await new Promise((res,rej) => {
		config.method = method;
		config.headers = config.headers || {};
		config.headers['Accept'] = 'application/json';
		config.headers['Content-Type'] = 'application/json';    
		if (token) {
			config.headers['x-id-token'] = token;    
			config.headers['x-id-provider'] = provider;
		}
		var csrf = cookies.get('csrf');
		config.headers["xsrf-token"] = csrf;
		if (data) {
			config.body = JSON.stringify(data);
		}
		config.credentials = 'same-origin';

		if (!config.no_feedback) {
			let event = new CustomEvent("loading_start", {detail: url});
			window.dispatchEvent(event);
		}

		fetch(url, config).then((resp) => {
			if (!resp.ok) {
				throw resp;
			}
			resp.json().then((data)=> {
				if (resp.status < 200 || resp.status >= 400) {
					handleError(rej,data);
					return;
				}
				res(data);
			}).catch(err => {
				handleError(rej,err);
				return;
			});                    
		}).catch(err => {
			handleError(rej,err);
		}).finally(()=>{
			if (!config.no_feedback) {
				let event = new CustomEvent("loading_complete", {detail: url});
				window.dispatchEvent(event);
			}
		});
	});    
}

const handleError = (rej, error) => {
	if (error?.status == 403 && window.location.pathname != '/login') {
		let event = new Event("bad_ticket");
  		window.dispatchEvent(event);
	} else if (error?.status == 401) {
		let event = new Event("unauthorized");
  		window.dispatchEvent(event);
	} else if (error?.status == 500) {
		error.json().then((data)=> {
			window.dispatchEvent(new Event("unexpected_error"));
			try {
				//rej({code: error.status, data: JSON.parse(data)});
			} catch (e) {
				//rej({code: error.status, message: data});
			}
		});
	} else {
		error.text().then((data)=> {
			try {
				rej({code: error.status, data: JSON.parse(data)});
			} catch (e) {
				rej({code: error.status, message: data});
				return;
			}
		}).catch((err) => {
			rej({code: error.status, message: error});
		});
			
		
	}
}

const get = function(url, params, options) {
	if (params) {
		var vars = Object.keys(params).map((k,i)=>{
			return k+'='+encodeURIComponent(params[k]);
		});

		var query = vars.join('&');

		url += (url.indexOf('?') > 10 ? '&' : '?')+query;
	}

	return issue_request('GET',url, null, options);
}



export default {
    get: get,
	post: function(url, data) {
		return issue_request('POST',url, data);        
	},
	put: function(url, data) {
		return issue_request('PUT',url, data);        
	},
	patch: function(url, data) {
		return issue_request('PATCH',url, data);        
	},
	delete: function(url, data) {
		return issue_request('DELETE',url, data);        
	}
}

import { Button, Stack } from '@mui/material';
import {Box} from '@mui/system';
import { useState } from 'react';
import Eval, { EvalOutro } from '../components/eval/Eval';
import { Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

export default function SafetyReport(props) {
	
	const [showReport, setShowReport] = useState(false);
	
	const {course_code} = useParams();

	return <Stack alignItems="center">
			<Box maxWidth={800} px={2}>
				<Box sx={{color: "black"}} mt={3} mb={4} alignItems="center" textAlign="center">
					<img src="/images/logo_colorized.png" style={{height: 180, maxWidth: '90%'}}></img>
					<h2>Valkyrie Safety Incident Report</h2>
				</Box>
				<Box>If you experience or observe a safety incident in the course of your training, please complete a safety report.  These reports are provided to the Valkyrie team as well as senior military personnel.  Your identity is kept anonymous unless you elect to be contacted regarding your responses.</Box>
				<Box p={4} textAlign="center">
					
					<Button color="error" variant="contained"  startIcon={<Edit/>}  onClick={()=>setShowReport(true)}>Safety Incident Report</Button>
				</Box>
				{showReport && <Eval show={showReport} onClose={()=>setShowReport(false)} course_code={course_code || "-"} eval_type="SAFETY_REPORT_V5">
					<EvalOutro>
						<Box p={2}>
							Thank you for taking time to submit this incident report.  Your feedback helps us improve our training and ensure the safety of our students and instructors.
						</Box>
					</EvalOutro>
				</Eval>}
			</Box>
		</Stack>;
}
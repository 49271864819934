import { Dialog, Skeleton, Tabs, Tab, Divider, useMediaQuery, Link, Accordion, AccordionSummary, AccordionDetails, DialogActions, Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import jax from "../helper/jax";
import { Avatar } from "@mui/material";
import { Box } from "@mui/system";
import { DialogContent } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import Profile from '../training/Profile';
import {FlexTabs, FlexTab} from "./FlexTabs";

import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { roleNames, roles } from "../features/access";
import { asDate } from "../helper/util";
import { hasRole } from "../features/access";
import { Delete, Lock } from "@mui/icons-material";
import Notification from "./Notification";	
import { useTheme } from "@emotion/react";
import AgencyAvatar from "./AgencyAvatar.tsx";
import TrainingHistory from "./TrainingHistory";


export default function ProfileDialog(props) {
	const {uid, onClose, onUpdate, onDeleteUser} = props;
	const [user, setUser] = useState({});
	const [training, setTraining] = useState([]);
	const [certs, setCerts] = useState([]);
	const [unitRoles, setUnitRoles] = useState([]);
	const [assignedAgency, setAssignedAgency] = useState(null);
	const admin = useSelector((state) => state.data.user);
	const [confirmDelete, setConfirmDelete] = useState(null);
	
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const {agencies} = useSelector((state) => state.data);
	const theme = useTheme();

	useEffect(()=>{
		setUser(null);
		setTraining([]);
		setCerts([]);
		if (uid) {
			jax.get(`/app/profile/${uid}`).then((response)=>{
				setUser(response.profile);
				setTraining(response.training);
				setCerts(response.certs);
				setUnitRoles(response.unit_roles);
				var agency = agencies.find((a)=>a.id == response.profile.unit_agency_id);
				setAssignedAgency(agency);
			});
		}
	}, [uid]);

	function deleteUser(uid) {
		setConfirmDelete(null);
		jax.delete(`/app/admin/personnel/${uid}`).then(()=>{
			!!onDeleteUser && onDeleteUser(uid);
			onClose();
		});
	}

	function updateTraining(t,profile) {
		setTraining(t);
		onUpdate(profile);
	}

	return <Dialog open={!!uid} onClose={onClose} fullWidth maxWidth="md"  keepMounted={false}>
		<DialogContent sx={{padding: 0}}>
			<Box p={0} minHeight={600} maxHeight="90%">
				<Stack p={2} mb={1} pb={0} direction={{xs:"column", lg:"row"}} spacing={4} alignItems={{xs:"center", lg:"flex-start"}}>
					<Stack direction="row" alignItems="center" flex={1}>
						<AgencyAvatar agency_id={user?.agency_id} size={48}/>
						<Box ml={1} >
							<Typography variant={isMobile ? "h5" : "h4"}>{user ? `${user.title}` : <Skeleton/>}</Typography>
							<Typography  style={{fontWeight: 'normal', margin:"0", lineHeight:"1.4rem"}}>{user?.rank}</Typography>
						</Box>
					</Stack>
					
				</Stack>
				{user && <Box mt={0}>
					<FlexTabs>
						<FlexTab label="Profile">
							<Stack py={2} direction={{xs:"column", lg:"row"}} spacing={{xs: 3, lg: 6}} p={4}>
								<Box flex={1}>
									<Box sx={{paddingTop:"80%", position:"relative"}} >
										<Box sx={{position:"absolute", top:0, left:0, right:0, bottom:0, display:"block", alignItems:"center", justifyContent:"center"}}>
											{user ? <Avatar variant="rounded" src={user.photo} sx={{border:"solid 2px white",  width:"80%", height:"100%" }} /> : <Skeleton variant="rounded" sx={{ width:"80%", height:"100%", maxWidth: "none"}}><Avatar sx={{width:"80%", height:"100%"}}></Avatar></Skeleton>}
										</Box>
									</Box>
								</Box>
								
								<Stack spacing={2} flex={3}>
									<div>
										<div className="sub-title">
											Title
										</div>
										<div>
											{user.title}
										</div>
									</div>
									<div>
										<div className="sub-title">
											Affliation
										</div>
										<div>
											{user.affiliation} {user.agency}
											{!user.agency && <i>---</i>}
										</div>
									</div>
									<div>
										<div className="sub-title">
											Current Assigned Unit
										</div>
										{user.unit_id ? <Box pt={0.5}>
											<Stack direction="row" alignItems="center">
												<img style={{height: '20px', width: '20px', verticalAlign: 'middle', display:'inline', marginRight:8}} src={`/images/agency/${assignedAgency.abbr}.png`}/>
												<RouterLink className="primary" to={`/commands/${user.unit_id}`}>{user.unit_name}</RouterLink>
											</Stack>
										</Box> : <div>
											<i>None</i>
										</div>}
									</div>
									<div>
										<div className="sub-title">
											Valkyrie Role
										</div>
										<div>
											{user.role_name || <i>---</i>}
										</div>
									</div>
									<div>
										<div className="sub-title">
											Contact Phone #
										</div>
										<div>
											{user.phone || <i>None</i>}
										</div>
									</div>
									<div>
										<div className="sub-title">
											Contact Email
										</div>
										<div>
											{user.email ?  <Link  href={`mailto:${user.email}`}>{user.email}</Link> : <i>None</i>}
										</div>
									</div>
								</Stack>
							</Stack>
						</FlexTab>
						
						{/* <FlexTab label="Certifications">
							Cerifications
						</FlexTab>  */}
						<FlexTab label="Training History">
							<Box sx={{maxHeight:"500px", overflowY:'auto'}}>
								<TrainingHistory records={training} user_id={user.uid} onTrainingUpdate={updateTraining}></TrainingHistory>
							</Box>
						</FlexTab>
						<FlexTab label="Command Roles">
							<Stack flex={1}>
								{unitRoles.map((r, i)=>{
									return <Box key={i}>
										<Stack p={2} direction="row" spacing={1} alignItems="center">
											<AgencyAvatar agency_id={r.agency_id} size={32}/>
											<Box flex={1}>
												<Box>{r.unit_name}</Box>
												<Box className="sub-title " flex={1}>{r.role_names.join(', ')}</Box>
											</Box>
										</Stack>
										<Divider></Divider>
									</Box>
								})}
							</Stack>
						</FlexTab>
						
					</FlexTabs>
				</Box>}
			</Box>
		</DialogContent>
		{user && hasRole(admin, roles.ADMIN) && admin.uid != user.uid && <DialogActions>
			<Button size="small" startIcon={<Lock></Lock>} color="error" variant="outlined" onClick={(e)=>{setConfirmDelete(e.currentTarget)}}>Delete This User</Button>
			<Notification
				anchorEl={confirmDelete}
				open={!!confirmDelete}
				maxWidth={300}
				background={theme.palette.error.main}
				color="white"
				onClose={()=>setConfirmDelete(null)}>
					Are you sure?  All history of this user will be deleted permanently.
					<Box pt={1} textAlign="center"><Button size="small" color="white" variant="outlined" onClick={()=>deleteUser(uid)}>Yes, continue</Button></Box>
			</Notification>
		</DialogActions>}
	</Dialog>
}
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, OutlinedInput, IconButton, Avatar, Box, Stack } from '@mui/material';
import { Clear } from '@mui/icons-material';
import UserSearch from './UserSearch';


export default function UserSelector(props) {
	const {user, onSelect, index, managedOnly} = props;
	const {agencies} = useSelector((state) => state.data);
	const config = useSelector(state=>state.data.config);

	const [unitLogo, setUnitLogo] = useState(null);

	useEffect(()=>{
		var abbr = agencies.find((x)=>x.id == user?.agency_id)?.abbr;
		if (abbr) {
			setUnitLogo(`/images/agency/${abbr}.png`);
		}
	}, [user]);

	return user ? <FormControl flex={1}  size="small" fullWidth className="user-selector-filled">
		<InputLabel focused shrink variant="outlined" htmlFor="user-holder" sx={{backgroundColor:'white', "& label":{backgroundColor:"white"}}}>Search Personnel</InputLabel><OutlinedInput sx={{paddingRight:"4px", justifyContent:'space-between', flex:1}} label="Find Personnel" slots={{input: (props)=><Box ref={props.inputRef} flex={1} pl={1.5} pr={1.75} py={0.75} >
		<Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1} width="100%">
			<Avatar src={unitLogo} sx={{border:"solid 2px white", height: 24, cursor: 'pointer', width: 24, mr:1 }} />
			<Box>{user.name}</Box>
		</Stack>
	</Box>
	}} endAdornment={<IconButton size="small" onClick={()=>onSelect && onSelect(null)}><Clear fontSize="inherit"/></IconButton>} /> </FormControl>
	: <UserSearch onSelect={(user) => {onSelect && onSelect(user)} } managedOnly={managedOnly} index={index || config.personnel_index} user={user} />
}
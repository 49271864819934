import Typography from "@mui/material/Typography"
import Breadcrumbs  from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { signOut } from "../features/data";
import { getAuth} from 'firebase/auth';
import { useState, createElement } from "react";
import { setShowProfile, setShowFeedback, setPageTitle } from "../features/app";
import { useEffect } from "react";
import system from "../systems";

import MenuItem  from "@mui/material/MenuItem";
import Menu  from "@mui/material/Menu";

import MenuList  from "@mui/material/MenuList";
import Divider  from "@mui/material/Divider";

import Campaign from "@mui/icons-material/Campaign";
import Logout from "@mui/icons-material/Logout";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import ListAlt from "@mui/icons-material/ListAlt";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import icons from "../icons";
import Icon from "./Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Can from "./Can";
import { actions, can, hasAnyUnitRole, hasRole, hasUnitRole, resources } from "../features/access";
import UserAvatar from "./UserAvatar";
import {getCookie} from '../helper/util';
import { Alert, AlertMessage, AlertTitle, AppBar, Badge, Button, Collapse, Fade, LinearProgress, ListItemIcon, ListItemText, styled, Toolbar } from '@mui/material';
import Notification from "./Notification";
import { ArrowDropDown, Lock, Notifications, NotificationsNoneTwoTone, NotificationsOutlined, Science, Spa } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import useCookie from "../helper/cookies";
import { AlertDialog, AlertMenu } from "./Alerts.tsx";


const AlertBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
	  right: 8,
	  top: 13,
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '0 4px',
	},
  }));

function Crumbs(props) {
	
	var crumbs = props.crumbs;
	return <Breadcrumbs color="#ffffffcc" aria-label="breadcrumb" >
		{
			crumbs.map((c,i)=> {
				return <Link
					key={i}
					color="inherit"
					to={c["url"]}
					sx= {{display: 'flex', alignItems: 'center', lineHeight: 1, fontSize: { xs: '0.85rem', md: '1rem'}}}
					component={RouterLink}
				>
					{c["icon"] ? <Icon name={c["icon"]} sx={{ mr: c["text"] ? '0.5rem' : '', height:'1.3rem', width: "1.3rem"}}/>: ''}
					{c["text"]}
				</Link>
			})
		}
		
	</Breadcrumbs>	
}

function ProfileMenu(props) {
	const user = props.user;
	const [open,setOpen] = useState(false);
	const dispatch = useDispatch();
	const nav = useNavigate();
	const handleClose = function() {
		setOpen(false);
	}
	
	function sign_out() {
		getAuth().signOut().then(()=>{
			dispatch(signOut());
			nav('/')
		});	
	}

	

	return <Box sx={{zIndex: 11}} py={0.5}>
		{user && <UserAvatar sx={{border:"solid 2px white", height: {xs: 36, lg: 44}, cursor: 'pointer', width: {xs: 36, lg: 44} }} onClick={(e)=>{setOpen(e.currentTarget); return false}}/>}
			<Notification
				anchorEl={open}
				right={true}
				sx={{p:0, pt:0}}
				open={!!open}
				onClose={handleClose}
				onClick={handleClose}
				ContainerProps={{padding:0}}
				PaperProps={{
					elevation: 0,
					p: 0,
					sx: {padding:0}
				}}
			>
				<Stack direction="row" alignItems="center" p={1} px={2}>
					<UserAvatar user={user} sx={{height: "24px", width: "24px", mr:1.5 }}/>
					<Box>
						<Box>{user?.title}</Box>
						<Box><Typography variant="caption sub-title">{user?.email}</Typography></Box>
					</Box>
				</Stack>
				<Divider sx={{my:"0 !important"}}/>
				<MenuItem onClick={()=>{nav('/profile')}}>
					<ManageAccounts sx={{mr:1.5, color: '#444'}}></ManageAccounts> Profile / Training History
				</MenuItem>
				
				<Divider sx={{my:"0 !important"}}/>
				{/* <MenuItem onClick={()=>{dispatch(setShowFeedback(true))}}>
				 	<Campaign sx={{mr:1.5, color: '#444'}}></Campaign>Share Feedback
				</MenuItem>
				<Divider sx={{my:"0 !important"}}/> */}
				<MenuItem onClick={sign_out}>
					<Logout sx={{mr:1.5, color: '#444'}}></Logout> Sign Out
				</MenuItem>
			
		</Notification>
		</Box>
}

const PageTab = (props) => {
	const {selected, adminOnly, ...other} = props;
	return <Tab {...other} color={selected ? "secondary" : "primary"} component={RouterLink} iconPosition="start" icon={adminOnly && <Lock sx={{fontSize:"1rem"}}/>} sx={{minHeight:'0 !important'}} />
}

export default function PageTitle(props) {
	const {loading, title} = props;
	const titleStyle = {whiteSpace: "nowrap", fontWeight:'500',  overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis", marginBottom: '0px', fontSize: { xs: '1.25rem', md: '2rem'}, textAlign:'left', mb: 0, color: 'black'};
	var nav = useNavigate();
	
	const dispatch = useDispatch();
	const pageTitle =useSelector(state => state.app.page_title);
	
	const crumbs = useSelector(state => state.app.crumbs);
	const user = useSelector(state=>state.data.user);
	const is_mobile = useSelector(state=>state.app.is_mobile);
	const config = useSelector(state=>state.data.config);

	const {alerts} = useSelector(state=>state.data);
	
	const [my_nav, setMyNav] = useState([]);
	const [showAlerts, setShowAlerts] = useState(false);
	const [no_unit, setNoUnit] = useState(false);

	const [imp, setImp] = useState(false);
	const [alertEl, setAlertEl] = useState(null);

	const [section, setSection] = useState(system[0]?.location);
	const theme = useTheme();

	const [hideSystemInfo,setHideSystemInfo] = useCookie('hide_system_info', false); 
	
	const handleNav = (e, v) => {
		setSection(v);
		nav(v);
	}

	useEffect(()=>{
		if (user){
			setNoUnit(user.unit_id == null && !user.unit_name);
		}
	}, [user])

	useEffect(()=>{
		if (user) {
			var nv = system.filter(n=>{
				
				var okay = !n.roles || !n.roles.length || hasRole(user, n.roles);
				return okay && (!n.unitRoles || !n.unitRoles.length || hasAnyUnitRole(user, n.unitRoles));
			});
			setMyNav(nv);
		}
	}, [user]);

	//try to find the current section using the URL
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(()=>{
		var urlSegs = location.pathname.split('/');
		var n = system.reduce((memo,r) =>{
			var navSegs = r.location.split('/');
			var matches = urlSegs.reduce((memo, seg, i)=>{
				if (i>=navSegs.length) return memo;
				if (seg == navSegs[i]) {
					return memo+1;
				}
			}, 0);
			if (matches > memo.m) {
				return {m: matches, section: r.location}
			}
			return memo;
			
		}, {m:0, section: null});
		
		if (n.section) {
			setSection(n.section);
		}

		if (location.search.indexOf('alerts') > -1) {
			setShowAlerts(true);
		}
	}, [location]);

	const hideSystemBanner = ()=>{
		//for one hour
		var now = new Date().getTime();
		now += 3600*1000;
		setHideSystemInfo(true, {expires:new Date(now)});
	}

	const system_colors = {
		"dev": "info",
		"test": "info",
		"staging": "warning",
	} 

	const hideAlerts = ()=>{
		setShowAlerts(false);
		const queryParams = new URLSearchParams(location.search)
		
		if (searchParams.has('alerts')) {
			searchParams.delete('alerts');
			setSearchParams(searchParams);
		}
	}

	return <Stack direction="column" mb={2}>
		
		<AlertDialog open={showAlerts} onClose={hideAlerts}  />	

		{no_unit && <Alert severity="error" variant="outlined" mb={1} sx={{mb:2}} >
			<AlertTitle>Profile Update Required</AlertTitle>
			To ensure proper management of your Valkyrie account, please <Link className="inherit" color="inherit" onClick={()=>nav('/profile')}>update your profile</Link> to include your assigned command.
		</Alert>}
 
		{config?.system != "prod" && hideSystemInfo !== 'true' && hideSystemInfo !== true && <Alert severity={config?.system ? system_colors[config?.system] : "info"} variant="filled" icon={<Science/>} mb={1} sx={{marginBottom:0}} action={<Button size="small" color="inherit" onClick={()=>hideSystemBanner()}>HIDE</Button>}>
			Viewing the <span style={{textTransform:"uppercase"}}><b>{config?.system}</b></span> system as <b>{user?.title}</b>
		</Alert>}

		<Stack direction="row" alignItems="center"><Crumbs crumbs={crumbs} /></Stack>

		<Stack mt={{xs:0, lg:1}} direction="row" alignItems="center" className="page-title" >

			<Box sx={{paddingRight:{xs:'12px', md:'16px', overflow: 'hidden', flex: '1'}}}>
				<Stack direction="row" justifyContent="start" alignItems={user ? "center" : "center"} flex={1}>
					
					<Stack direction="row" alignItems="center" spacing={1.5} flex={1}>
						<RouterLink to="/">
							<img src="/images/logo_colorized_sm.png" height={is_mobile ? 36 : 48} className="logo"></img>
						</RouterLink>
						
						<Stack direction={{xs:"column", lg:"row"}} alignItems="center" flex={1}>
							<Typography variant="h1" mb="0" sx={titleStyle}>{title || "Valkyrie Training"}</Typography>
							
							<Box flex={1}></Box>
						</Stack>
						
					</Stack>
				</Stack>				
			</Box>
			
			{props.children && <Box sx={{pr:"1rem"}}>{props.children}</Box>}
			{!!alerts?.length && <Box mr={2} >
				<AlertBadge badgeContent={alerts.length} color="error" sx={{color: 'black'}}>
					<IconButton size="small" className="pulse" onClick={(e)=>setAlertEl(e.currentTarget)} color="inherit" >
						<NotificationsNoneTwoTone color="error" sx={{fontSize:'32px'}} mr={2}/>
					</IconButton>
					<AlertMenu el={alertEl} onClose={()=>setAlertEl(null)} ContainerProps={{sx:{maxHeight:400, overflowY:'auto'}}}></AlertMenu>
				</AlertBadge>
			</Box>}
			
			{user && <ProfileMenu user={user} open></ProfileMenu>}
		</Stack>
		{user && <Box mx={{xs:-2, lg:0}} pb={my_nav?.length < 2 ? 1 : 0} sx={{ borderBottom: 0, borderColor: 'divider', position: 'relative'}}>
			<LinearProgress variant={loading.length > 0 ? "indeterminate" : "determinate"} value={0} sx={{position: 'absolute', height: "2px", bottom:0, left:0, right:0}} />
			{my_nav?.length > 1 &&  <Tabs indicatorColor="#000" value={section} onChange={handleNav} variant="scrollable" scrollButtons={false} sx={{"& .MuiTabs-indicator": {backgroundColor:'#000'}, "& .MuiTab-root.Mui-selected": {color: `black !important`}}}>
				{my_nav.map((n, i)=>{

					return <PageTab key={i} label={n.title} adminOnly={n.adminOnly} to={n.location} value={n.location} selected={n.location==section} />
				})}
			</Tabs>}
		</Box>}
		
		<Box mt={"1px"}>
			
		</Box>
	</Stack>
}
import { Tooltip, ClickAwayListener } from "@mui/material";
import { useState, useEffect } from "react";

export default function MobileTooltip(props) {
	const {title} = props;
	const [open, setOpen] = useState(false);

	const handleClose = function() {
		setOpen(false);
	}

	useEffect(()=>{
		
	}, [open]);
	return <ClickAwayListener onClickAway={handleClose}>
		<Tooltip 
			title={title}
			arrow
			disableFocusListener
			disableTouchListener
			placement="top"
			PopperProps={{
				disablePortal: true,
				
			}}
			onClose={handleClose}
			open={open}
			>
			<div onMouseEnter={()=>setOpen(true)} onClick={()=>setOpen(true)}><div style={{pointerEvents:'none'}}>{props.children}</div></div>
		</Tooltip>
	</ClickAwayListener>
}
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notification from './Notification';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Link, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { Add, ArrowForward, NotificationsNoneTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddTraining from './AddTraining';
import { hasRole, roles } from '../features/access';
import { updateLocalUser } from '../features/data';
import jax from '../helper/jax';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { useTheme } from '@mui/material';

interface Props {
	// Define your component's props here
}

const AlertList: React.FC<Props> = (props : any) => {
	const {ContainerProps, onClose} = props;
	const {alerts} = useSelector((state: any)=>state.data);
	const [addTraining, setAddTraining] = React.useState(false);
	const user = useSelector((state: any) => state.data.user);
	const nav = useNavigate();
	const dispatch = useDispatch();
	
	const refreshAlerts = () =>{
		let event = new CustomEvent("load_alerts");
		window.dispatchEvent(event);
	}

	const onSubmitVerification = (data:any) => {
		console.log(data);
		dispatch(updateLocalUser({pending_verification: true}));
		refreshAlerts();
		onClose();
	}

	const confirmTraining = async (uid:number, id:number) => {
		try {
			await jax.post(`/app/training/refresher/confirm/${uid}/${id}`);
			refreshAlerts();
			onClose();
		} catch (e) {
			console.log(e);
		}
	}

	const rejectTraining = async (uid:number, id:number) => {
		try {
			var result = await jax.post(`/app/training/refresher/deny/${uid}/${id}`);
			let event = new CustomEvent("load_alerts");
			window.dispatchEvent(event);
		} catch (e) {
			console.log(e);
		}
	}

	return <Box {...ContainerProps}>

		<Timeline className="sub-title" sx={{'& .MuiTimelineOppositeContent-root': {display: 'none'}}}>
			{alerts.map((alert:any, index:number) => (
				<TimelineItem key={index} >
					<TimelineOppositeContent></TimelineOppositeContent>
					<TimelineSeparator>
						<Box sx={{marginTop:"3px", "& svg": {fontSize: 24}}}><NotificationsNoneTwoTone color='error'/></Box>
						{index < alerts?.length-1 && <TimelineConnector color='disabled' sx={{opacity:0.5}} />}
					</TimelineSeparator>
					<TimelineContent sx={{paddingTop: "2px !important"}}>
						<Box>
							<Box mb={1}>
								<Typography variant="body1" color="error"><b>{alert.title}</b></Typography>
							</Box>
							<Box><Typography variant="body2"><span dangerouslySetInnerHTML={{__html: alert.content}}></span></Typography></Box>
							{alert.type == "CONFIRM_REFRESHER_INSTRUCTOR" && !!user.verified && <Box my={2}><Stack my={1} direction="row" justifyContent="flex-end" spacing={2}>
								<Button size="small" variant="contained" color="primary" onClick={()=>confirmTraining(alert.arg2, alert.arg1)}>Confirm Training</Button>
								<Button size="small" variant="text" color="disabled" onClick={()=>rejectTraining(alert.arg2, alert.arg1)}>Deny</Button>
							</Stack></Box>}
							{alert.type == "CONFIRM_REFRESHER_INSTRUCTOR" && !user.verified && <Box my={2}>
								<Typography variant="body2" color="error">
								You are not able to confirm this event until your training as an <b>Instructor</b> has been verified. Please <Link color="error" onClick={()=>setAddTraining(true)}>submit prior training information</Link> for review.
								</Typography>
							</Box>}
							{alert.type == "CONFIRM_REFRESHER_GRAD" && <Stack mt={2} mb={1} direction="row" justifyContent="flex-end" spacing={2}>
								<Button size="small" variant="text" color="primary" startIcon={<ArrowForward/>} onClick={()=>{onClose(); nav(`/profile`); }}>View Training History</Button>
							</Stack>}
							{alert.type == "CLOSE_COMPLETED_COURSE" && <Stack mt={2} mb={1} direction="row" justifyContent="flex-end" spacing={2}>
								<Button size="small" variant="text" color="primary" endIcon={<ArrowForward/>} onClick={()=>{onClose(); nav(`/courses/${alert.arg1}`); }}>Go to Course</Button>
							</Stack>}
							{alert.type == "LEGACY_VERIFICATION_REQUIRED" && <Stack mt={2} mb={1} direction="row" justifyContent="flex-end" spacing={2}>
								<Button size="small" variant="text" color="primary" startIcon={<Add/>} onClick={()=>{setAddTraining(true);}}>Submit Prior Training</Button>
							</Stack>}
						</Box>	
						{index < alerts?.length-1 && <Divider sx={{mb:1}} />}
					</TimelineContent>
				</TimelineItem>
			))}

		</Timeline>

		<AddTraining open={addTraining} user={user} lock_role={hasRole(user, roles.INSTRUCTOR) ? roles.INSTRUCTOR : roles.GRADUATE} onClose={()=>setAddTraining(false)} onSubmit={onSubmitVerification}></AddTraining>
	</Box>;
};

const AlertMenu: React.FC<Props> = (props:any) => {

	const {el, onClose, ...rest} = props;
	return (
		<Notification anchorEl={el} open={!!el} onClose={onClose} right={true} ContainerProps={{p:0, maxWidth:"400px",  maxHeight:400, overflowY:'auto'}}>
			<AlertList {...rest} onClose={onClose} />
		</Notification>
	);
}

const AlertDialog: React.FC<Props> = (props:any) => {
	const {open, onClose, ...rest} = props;

	const {alerts} = useSelector((state: any)=>state.data);
	const theme = useTheme();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll='paper' >
			<DialogTitle sx={{padding:0}}>
				<Box className="card-header" bgcolor={theme.palette.error.main} ><Typography variant="body1"><b>You have {alerts?.length > 0 ? alerts.length : "no"} notification{alerts.length == 1 ? '' : 's'}</b></Typography></Box>
			</DialogTitle>
			<DialogContent sx={{padding:0, maxHeight:600}}>
				{!alerts?.length && <Box textAlign="center" p={4} >You currently have no notifications...</Box>}
				{!!alerts?.length && <AlertList {...rest} onClose={onClose}/>}
				
			</DialogContent>
		</Dialog>
	)
}


export {AlertMenu, AlertList, AlertDialog};


import React from 'react';
import { Avatar, Box, Button, Stack, FormControl, Select, InputLabel, MenuItem, TextField, InputAdornment, IconButton, OutlinedInput, Typography, Tooltip } from '@mui/material';
import UserAvatar from './UserAvatar';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import jax from '../helper/jax';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { AssignmentTurnedIn, Block, Clear, ContentPaste, ContentPasteOff, MoreTime, Pending, PendingActions, Stars, TaskAlt } from '@mui/icons-material';
import MobileTooltip from './MobileTooptip';
import Icon from './Icon';
import { courseStatusMap } from '../helper/util';

export default function Member(props) {
	const {member, canEdit, isClosed, userIsAdmin} = props;
	const [comments, setComments] = React.useState(member.comments);
	const [showComments, setShowComments] = React.useState(false);
	const theme = useTheme();

	var status = "";
	if (member.graduated) {
		status = member.distinction ? "2" : "1";
	} else if (member.graduated===0 && member.withdrew) {
		status = "-1";
	} else if (member.graduated===0 && member.withdrew === 0) {
		status = "0";	
	} else {
		status="";
	}	

	const [gradStatus, setGradStatus] = React.useState(status);

	useEffect(()=>{
		switch (gradStatus) {
			case "": 
				member.graduated = null;
				member.distinction = false;	
				member.withdrew = null;
				break;
			case "-1": 
				member.graduated = false;
				member.distinction = false;	
				member.withdrew = true;
				break;
			case "0":
				member.graduated = false;
				member.distinction = false;
				member.withdrew = false;
				break;
			case "1":
				member.graduated = true;
				member.distinction = false;
				member.withdrew = false;
				break;
			case "2":
				member.graduated = true;
				member.distinction = true;
				member.withdrew = false;
				break;
		}
			
		
		if (canEdit && props.onChange && (gradStatus != status || comments != member.comments)) {
			member.comments = comments;
			props.onChange(member);
		}
	}, [gradStatus, comments]);

	const makePrimary = async function() { 
		try {
			await jax.post(`/app/courses/${member.course_id}/primary_instructor`, {uid: member.uid});

			if (props.onMakePrimary) {
				props.onMakePrimary(member.uid);
			}
		} catch (err) {
			
		}
	}

	return <Box p={2} py={3} flex={1} typography="body1" bgcolor={props.index % 2 == 1 ? '#f6f6f6' : ''} sx={{color: canEdit && false && !member.approved_by ? '#999' :''}}>
		<Stack direction={{xs:"column", lg:"row"}} justifyContent="space-between"  >
			<Stack direction="row" spacing={2} flex={1} alignItems="center">
				<UserAvatar user={member} sx={{ bgcolor: '#999', width: 32, height: 32 }}/>
				<Stack sx={{flex:1}} spacing="2px">
					<Stack direction="row" alignItems="center" spacing={0.5} style={{lineHeight: 1}}><div ><b>{member.title}</b></div>{userIsAdmin && !!member.evaluated ? <MobileTooltip placement="top" disableFocusListener title="Evaluation Submitted"> <Box color={theme.palette.primary.main} ><AssignmentTurnedIn ></AssignmentTurnedIn></Box></MobileTooltip> : userIsAdmin && <MobileTooltip placement="top" disableFocusListener title="Evaluation Pending"><Box color="#999999" ><ContentPasteOff></ContentPasteOff></Box></MobileTooltip>}</Stack>
					<div >{member.primary ? 'Lead ' : ''}{member.course_role}</div> 
				</Stack>
				{isClosed && canEdit ? <Stack alignItems="flex-end" spacing={0.5}>
					{isClosed && userIsAdmin && member?.course_role_id != 4 && <Stack direction="row" alignItems="center">
						<Icon name={courseStatusMap[gradStatus]?.icon} fontSize="small"  sx={{mr:0.5, color:courseStatusMap[gradStatus]?.color}}></Icon> <Typography lineHeight={1} color={courseStatusMap[gradStatus]?.color}>{courseStatusMap[gradStatus]?.label}</Typography>
						
						{/* {member.graduated && <>{member.distinction ? <Stars fontSize="small" sx={{mr:0.5}}></Stars> : <TaskAlt fontSize="small" sx={{mr:0.5}}></TaskAlt>} <b>Graduated</b></>}
						{member.distinction && <><b>&nbsp;w. Distinction</b></>}
						{!member.graduated && !member.withdrew && <><MoreTime fontSize="small" sx={{mr:0.5}}></MoreTime> <i>Add'l Training Required</i></>}
						{!member.graduated && member.withdrew && <><Block fontSize="small" color='error' sx={{mr:0.5}}></Block> <Typography lineHeight={1} color="error">Disenrolled</Typography></>} */}
						
					</Stack>}	
					
				</Stack> : <></>}
			</Stack>

			{/* For non-primary instructors, show a button to make them primary */}
			{canEdit && (member.course_role_id == 4 && !member.primary) && !isClosed ? <Box textAlign={{lg:"right", xs:"center"}} pt={{xs:1, lg:0}}>
				<Button size="small" variant="outlined" color="primary" sx={{mt:0}} onClick={makePrimary}>Make Lead</Button>
			</Box> : <></>}

			{canEdit && member.course_role_id != 4 && !isClosed ? <Box >
				
					<FormControl fullWidth sx={{  width:{lg:220}, mt: {xs:3, lg:0} }} size="small" required >
						<InputLabel id="enroll-label">Completion Status</InputLabel>			
						<Select size="small" labelId="enroll-label" label="Course Outcome" value={gradStatus} required onChange={(e)=>setGradStatus(e.target.value)}>
							<MenuItem value="">&nbsp;</MenuItem>
							<MenuItem value="1">Graduate</MenuItem>
							{member.course_role_id == 1 && <MenuItem value="2">Graduate w/ Distinction</MenuItem>}
							<MenuItem value="0">Add'l Training Required</MenuItem>
							<MenuItem value="-1">Disenrolled</MenuItem>
						</Select>
					</FormControl>
				</Box> : <></>
			}

			
			{props.showRole ? <Box>
				{canEdit && !member.approved && <Button size="small"  variant="contained" color="primary" sx={{mt:0}} text="">Approve Enrollment</Button>}
				{canEdit && !member.approved && <Button size="small"  variant="outlined" color="error" sx={{ml:1}} text="">Disapprove</Button>}
			</Box> : <></>}
		</Stack>
		{!isClosed && canEdit && member.course_role_id != 4 && (comments || showComments || gradStatus == "-1") ? <Box sx={{mt:2}}>
			<FormControl fullWidth sx={{  mt: {xs:0, lg:0} }} size="small" required={gradStatus === "-1"}  >
				<InputLabel htmlFor="comments">Comments</InputLabel>
				<OutlinedInput fullWidth error={gradStatus === "-1" && (!comments || !comments.trim())} label="Comments" size="small" value={comments} onChange={(e)=>setComments(e.target.value)}
					endAdornment={gradStatus != -1 && <InputAdornment position="end" helperText={gradStatus === "-1" ? "Please supply comments for this participant":""}>
							<IconButton onClick={()=>{setComments(null); setShowComments(false)}} size="small" color="primary" aria-label="toggle comments" edge="end"><Clear></Clear></IconButton>
						</InputAdornment>}></OutlinedInput>
			</FormControl>
		</Box> : <></>}
		{!isClosed && canEdit && member.course_role_id != 4 && (!comments && !showComments) && gradStatus != "-1" ? <Box sx={{mt:0}} textAlign="right">
			<Button size="small" sx={{width:{lg:220}}}  onClick={()=>setShowComments(true)}>+ Add Comments</Button>
		</Box> : <></>}
	</Box>;
}
import { Stack } from "@mui/material";
import UserAvatar from "./UserAvatar";


export default function UserResult(props) {
	const {user, subTitle, subTitle2} = props;	
	
	return <Stack direction="row" alignItems="center" spacing={1} p={1}>
		<UserAvatar user={user} sx={{ bgcolor: '#999', width: 32, height: 32 }}/>
		<Stack direction="column" spacing={0}>
			<div><b>{user.title}</b></div>
			{!!subTitle && <div className="sub-title">{subTitle}</div>}
			{!!subTitle2 && <div className="sub-title">{subTitle2}</div>}
		</Stack>
	</Stack>
}
import { useEffect, useState } from "react";
import jax from "../helper/jax";
import { Router, Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Skeleton, Link, Checkbox, Menu, Typography, CircularProgress, LinearProgress, FormControl, InputLabel, Select, MenuItem, Stack, FormControlLabel, Card, CardContent, CardHeader, Popover, Box, CardActions, CardActionArea, Paper, Avatar, colors, Button, TextField, Alert, AlertTitle, List, ListItemButton, Snackbar, useMediaQuery, Divider, Breadcrumbs } from "@mui/material";
import { asDate, copyToClipboard} from "../helper/util";

import Icon from "../components/Icon";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import icons from "../icons";
import { AssignmentLate, BarChart, Cancel, CheckCircleOutline, DoDisturb, Edit, Error, ExpandLess, ExpandMore, HealthAndSafety, Lock, RateReview, School, TaskAlt, Verified, VerifiedOutlined, Warning, WarningAmber, WorkspacePremium } from "@mui/icons-material";
import Can from "../components/Can";
import { actions, roles, can, hasRole, resources } from "../features/access";
import {QRCodeSVG} from 'qrcode.react';
// import theme from "../theme";
// import CloseCourse from "../components/CloseCourse";
import CourseMember from "../components/CourseMember";
import Eval, { EvalFooter, EvalIntro, EvalOutro, EvalHeader } from "../components/eval/Eval";
import Notification from "../components/Notification";
import { courseStatusMap } from "../helper/util";

const CourseStatus = function(props) {
	const course = props.course;
	const user = useSelector((state) => state.data.user);
	var me = course.members.find((m)=>m.uid == user.uid);
	const roles = useSelector((state) => state.data.roles);
	const [confirmDisenroll, setConfirmDisenroll] = useState(false);
	const theme = useTheme();
	var isAdmin = hasRole(user, roles.ADMIN) || (user.programs || []).map(p=>p.id).includes(course.program_id) ;
	
	var m = moment();
	var todayEnd = m.clone().add(1,'d').format('YYYY-MM-DD');
	var today = m.clone().format('YYYY-MM-DD');

	

	const canEnroll = (!me &&  !course.closed_on);// && course.is_open;
	const isPending =!isAdmin && me && me.approved===null;
	const isEnrolled = !!me && (me.approved===true || me.approved===1);
	const isGraduate = !isAdmin && me && me.graduated;
	const withdrew = !isAdmin && me && me.withdrew;

	const [enrollAs, setEnrollAs] = useState("1"); //1=student, 3=IUT, 4=Instructor

	const onCourseChange = props.onCourseChange;
	const onError = props.onError;

	var el = null;
	const enroll = async function(e) {
		try {
			var data = await jax.post(`/app/courses/${course.id}/enroll`, {role: enrollAs, skip_approval: true});
			if(onCourseChange) {
				onCourseChange(data);
			}
		} catch (err) {
			if (err.code == 417 && onError) { //Not eligible
				onError(err.message);
			}
		}
	};

	const disenroll = async function(e) {
		try {
			setConfirmDisenroll(null);
			var data = await jax.post(`/app/courses/${course.id}/disenroll`);
			if(onCourseChange) {
				onCourseChange(data);
			}
		} catch (err) {
			if (err.code == 417 && onError) { //Not eligible
				onError(err.message);
			}
		}
	};	

	if (!isEnrolled) {
		if (!course.closed_on) {
			if (course.enrollment_start_date > todayEnd) {
				//Enrollment hasn't started
				el = <Alert p={2}  icon={false} severity="warning" variant="filled">
					<Stack direction="row" spacing={1} alignItems="flex-start">
						<Icon name="LockClock"   ></Icon>
						<Typography whiteSpace="normal" >
							Enrollment begins {asDate(course.enrollment_start_date)}
						</Typography>
					</Stack>
				</Alert>;
			} else if (course.enrollment_end_date < today) {
				el = <Alert p={2} icon={false} severity="info" variant="filled">
					<Stack direction="row" spacing={1} alignItems="flex-start">
						<Icon name="Lock"   ></Icon>
						<Typography whiteSpace="normal">
							Enrollment closed on {asDate(course.enrollment_end_date)}
						</Typography>
					</Stack>
				</Alert>;
			} else if (canEnroll) {
				el = <Stack direction="column" justifyContent="stretch">
						<Box className="card-header">This course is open for enrollment</Box>
					{canEnroll ? <Stack flex={1} justifyContent="stretch" alignItems="stretch" className="success-bg" p={2}>
						
						<FormControl sx={{ my: 1 }} size="small" fullWidth>
							<InputLabel id="enroll-label">Enroll As</InputLabel>		
							<Select size="small" labelId="enroll-label" fullWidth label="Enroll As" value={enrollAs} onChange={(e)=>setEnrollAs(e.target.value)}>
									<MenuItem value="1">Student</MenuItem>
									<MenuItem value="3">Instruction Under Training (IUT)</MenuItem>
									<MenuItem value="4">Instructor</MenuItem>
							</Select>
						</FormControl>
						
						<Button variant="contained" color="primary" onClick={enroll} text="">Enroll</Button>
					</Stack> : <></>}
				
					</Stack>;
			}
		}
	} else { //Enrolled
		if (course.closed_on) {
			var msg = <>You were a{[3,4].includes(me.course_role_id) && 'n'} {roles.find(r=>r.id==me.course_role_id)?.name} in this course</>
			
			//Course is closed - grad status
			if (me.graduated) {
				el = <Alert p={2}  icon={<Icon name={courseStatusMap[1].icon}/>} severity="success" variant="filled">
					<Stack direction="row" spacing={1} alignItems="flex-start">
						<Typography >
							{msg} and graduated.
						</Typography>
					</Stack>
				</Alert>;
			} else if (me.withdrew) {
				el = <Alert p={2}  icon={<Icon name={courseStatusMap[-1].icon}/>} severity="error" variant="filled" sx={{background: '#444'}}>
					<Stack direction="row" spacing={1} alignItems="flex-start">
						<Typography >
							{msg}, but were disenrolled.
						</Typography>
					</Stack>
				</Alert>
			} else {
				if (me.course_role_id == 4) {
					el = <Alert p={2}  icon={false} severity="success"  variant="filled" >
						<Stack direction="row" spacing={1} alignItems="flex-start">
							<Typography >
								{msg}
							</Typography>
						</Stack>
					</Alert>
				} else {
					el = <Alert p={2} icon={<Icon name={courseStatusMap[0].icon}/>} severity="warning" variant="filled">
						<Stack direction="row" spacing={1} alignItems="flex-start">
							<Typography >
								{msg}; however, more training is required to complete certification.
							</Typography>
						</Stack>
					</Alert>
				}
			}
		} else {
			//You are enrolled as...
			el = <Alert  p={2}  icon={<TaskAlt/>} severity="success" variant="filled">
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Typography >
					You are enrolled as a{[3,4].includes(me.course_role_id) && 'n'} {roles.find(r=>r.id==me.course_role_id)?.name}.
					&nbsp;&nbsp;
					{course.enrollment_end_date >= today && <Link onClick={(e)=>setConfirmDisenroll(e.currentTarget)} className="inherit">Cancel Enrollment</Link>}
					<Notification
						anchorEl={confirmDisenroll}
						sx={{p:0, pt:0}}
						open={!!confirmDisenroll}
						onClose={()=>setConfirmDisenroll(null)}
						ContainerProps={{padding:0}}
						PaperProps={{
							elevation: 0,
							p: 2,
							sx: {padding:0}
						}}><Box p={2}>
								Are you sure you would like to <br/>disenroll from this course?
								<Box textAlign="center" mt={1}>
									<Button size="small" color="error" variant="outlined" onClick={disenroll}>Yes, disenroll</Button>
								</Box>
							</Box>
						</Notification>
				</Typography>
			</Stack>
		</Alert>;
		}
	}

	if (el) {
		return <Card variant="outlined" elevation={0} className="enrollment-status">{el}</Card>;
	}
	return <></>
}

export default function Course(props) {
	const [loading, setLoading] = useState(true);
	const { course_id, program_id } = useParams();
	const [ course, setCourse ] = useState(null);
	const [editing, setEditing] = useState(false);
	const [enrollError, setEnrollError] = useState(null);
	const [registerUrl, setRegisterUrl] = useState(null);
	const user = useSelector((state) => state.data.user);
	const theme = useTheme();
	const [isAdmin, setIsAdmin] = useState(false);
	const [isInstructor, setIsInstructor] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const [myRegistration, setMyRegistration] = useState(null);
	const [isEvaluating, setIsEvaluating] = useState(false);
	const [showSafetyReport, setShowSafetyReport] = useState(false);
	const [isPM, setIsPM] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [isEnrollmentWindow, setIsEnrollmentWindow] = useState(false);
	const [isEvalWindow, setIsEvalWindow] = useState(false);
	const [showEvalAlertText, setShowEvalAlertText] = useState(true);
	const [lastLoad, setLastLoad] = useState(0);
	const [saving, setSaving] = useState(false);
	const [closeError, setCloseError] = useState(null);
	const [canClose, setCanClose] = useState(false);
	const [evaluationsPending, setEvaluationsPending] = useState(0);
	const [confirmCancelation, setConfirmCancelation] = useState(null);
	const config = useSelector((state) => state.data.config);
	
	const nav = useNavigate();
	var m = moment();
	var todayEnd = m.clone().add(1,'d').format('YYYY-MM-DD');
	var today = m.clone().format('YYYY-MM-DD');


	const {control , handleSubmit, getFieldState, watch, reset, formState} = useForm({
		mode:"onChange",
		defaultValues: {
			name: '',
			end_date: null,
			start_date: null,
			expected_end_date: null,
			enrollment_start_date: null,
			enrollment_end_date: null,
			eval_start_date: null,
			eval_end_date: null,
			is_open: false,
			instructions: ''
		}
	});
	
	const {touchedFields} = getFieldState("eval_start_date", formState);
	
	const load = async function() {
		var data = await jax.get(`/app/courses/${course_id}`);
		var me = data.members.find((m)=>m.uid == user.uid);
		setMyRegistration(me);
		const pm = user.programs.some(p=>p.id == data.program_id );
		setIsPM(pm);

		setCourse({...data, members: data.members});

		setLoading(false);
		setLastLoad(Date.now());
		setHasChanges(false);
		setEvaluationsPending(data.enrollees-data.evaluated);

		

		setIsEnrollmentWindow(!data.closed && today >= asDate(data.enrollment_start_date, 'YYYY-MM-DD') &&  today <= asDate(data.enrollment_end_date, 'YYYY-MM-DD'));
		setIsEvalWindow(today >= asDate(data.eval_start_date, 'YYYY-MM-DD') &&  today <= asDate(data.eval_end_date, 'YYYY-MM-DD'));
	}

	const save_statuses = async () => {
		var stats = course.members.filter(m=>m.course_role_id != 4).map(m=>{
			return {
				uid: m.uid,
				graduated: m.graduated,
				distinction: m.distinction,
				comments: m.comments,
				withdrew: m.withdrew
			}
		});
		
		try {
			setSaving(true);
			var results = await jax.post(`/app/courses/${course.id}/save`, {stats: stats});
			setHasChanges(false);
			return true;
		} catch (err) {
			debugger;
		} finally {
			setSaving(false);
		}
	}

	const close_course = async () => {
		try {
			setSaving(true);
			var results = await jax.post(`/app/courses/${course.id}/close`);
			setCourse(results);
			setHasChanges(false);
			return true;
		} catch (err) {
			return false;
		} finally {
			setSaving(false);
		}
	}

	const check_valid = function() {
		var valid = course.members.every(x=>x.course_role_id == 4 || (x.graduated !== null && ((x.graduated === false && x.withdrew && x.comments && x.comments.length) || !x.withdrew)));
		setCanClose(valid);
		return valid;
	}

	useEffect(()=>{
		if (course && course.members && user) {
			var admin = hasRole(user,roles.ADMIN) || (user.programs || []).map(p=>p.id).includes(course.program_id) ;
			var instr = hasRole(user,roles.ADMIN) || course.members.some((m)=>m.uid == user.uid && m.course_role_id == roles.INSTRUCTOR);
			setIsAdmin(admin);
			setIsInstructor(instr);
			setRegisterUrl(window.location.href);
			reset({
				name: course.name,
				is_open: course.is_open,
				start_date: course.start_date ? moment(course.start_date).utc().format('YYYY-MM-DD') : null,
				expected_end_date: course.expected_end_date ? moment.utc(course.expected_end_date).utc().format('YYYY-MM-DD') : null,
				enrollment_start_date: course.start_date ? moment.utc(course.enrollment_start_date).utc().format('YYYY-MM-DD') : null,
				enrollment_end_date: course.start_date ? moment.utc(course.enrollment_end_date).utc().format('YYYY-MM-DD') : null,
				eval_start_date: course.start_date ? moment.utc(course.eval_start_date).utc().format('YYYY-MM-DD') : null,
				eval_end_date: course.start_date ? moment.utc(course.eval_end_date).utc().format('YYYY-MM-DD') : null,
				instructions: course.instructions
			})
		}
	}, [course]);

	useEffect(()=>{
		load();
	},[]);

	const makePrimary = function(uid) {
		var members = course.members.map((m)=>{
			if (m.uid == uid) {
				m.primary = true;
			} else {
				m.primary = false;
			}
			return m;
		});
		setCourse({...course, members: members});
	}

	const save_course_info = async function(data) {
		try {
			setLoading(true);
			console.log(touchedFields);
			data.eval_start_date = getFieldState("eval_start_date", formState).isDirty ? data.eval_start_date : null;
			data.eval_end_date = getFieldState("eval_end_date", formState).isDirty ? data.eval_end_date : null;
			data.enrollment_start_date = getFieldState("enrollment_start_date", formState).isDirty ? data.enrollment_start_date : null;
			data.enrollment_end_date = getFieldState("enrollment_end_date", formState).isDirty ? data.enrollment_end_date : null;
			
			var data = await jax.put(`/app/courses/${course.id}`, data);
			setCourse(data);
			
		} catch (err) {

		} finally {
			setEditing(false);
			setLoading(false);
		}
	}

	const begin_closing = async function(e, saveAndContinue) {
		if (!check_valid()) {
			setCloseError(e.currentTarget);
		} else if (hasChanges) {
			if (saveAndContinue) {
				await save_statuses(false);
				setCloseError(null);
				setIsEvaluating(true);
			} else {
				setCloseError(e.currentTarget);
			}
		} else {
			setIsEvaluating(true);
		}
	}

	const onEvalSubmit = async function() {
		if (myRegistration) {
			setMyRegistration({...myRegistration, evaluated: moment().format('YYYY-MM-DD')});
		}
		if (isPM) {
			await close_course();
		}
	}

	

	const cancel_course = async function(e) {
		if (e.currentTarget) {
			setConfirmCancelation(e.currentTarget);
			return;
		}

		if (e !== true) {
			return
		}

		setConfirmCancelation(false);
		try {
			setLoading(true);
			var data = await jax.delete(`/app/courses/${course.id}`, data);
			setCourse(data);
		} catch (err) {

		} finally {
			setLoading(false);
		}
	}

	const resetCourse = async ()=> {
		try {
			setLoading(true);
			var data = await jax.post(`/app/courses/${course.id}/reset`);
			setCourse(data);
		} catch (err) {

		} finally {
			setLoading(false);
		}
	}

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

	if (course && course.canceled_on) {
		return <Box>
			<Alert severity="error" icon={<Cancel/>} variant="filled">
				This course was canceled on {asDate(course.canceled_on)}.
			</Alert>
			</Box>
	}

	return course && <div>
		
		{/* SAFETY REPORT */}
		{showSafetyReport && <Eval course_code={course.code} eval_type="SAFETY_REPORT_V5" role_id={isAdmin ? roles.PROGRAM_MGR : myRegistration?.course_role_id} show={showSafetyReport} onClose={()=>setShowSafetyReport(false)} finishText={"Submit Responses"}>
			<EvalIntro>
				<Box p={2}>
				<p>This information may be consolidated for publishing reports to commanders and scientific journals regarding the safety of autologous training.</p>

				<p>Do NOT include any Personally Identifiable Information (PII) in this report. </p>

				</Box>
			</EvalIntro>
			<EvalOutro>
				<Box  p={8} pb={2} textAlign="center">
					Thank you for taking time to complete this safety report.
					
				</Box>
			</EvalOutro>
		</Eval>}
		
		{/* COURSE EVALUATION */}
		{isEvaluating && (myRegistration || isAdmin) && <Eval course_code={course.code} role_id={myRegistration ? myRegistration?.course_role_id : roles.PROGRAM_MGR} is_lead={myRegistration?.primary} show={isEvaluating} onClose={(takeSafetyReport)=>{setIsEvaluating(false); setShowSafetyReport(takeSafetyReport===true)}} onSubmitted={onEvalSubmit} finishText={isPM ? "Certify Results" : "Submit Responses"}>
			{isPM && !course.closed_on && course.members.some((m)=>(m.course_role_id == roles.STUDENT || m.course_role_id == roles.IUT) && m.graduated && !m.evaluated) && (
				<EvalHeader>
					<Alert severity="error"  sx={{py:0.5,  px:2, borderRadius:0, border:`solid ${theme.palette.error.dark}`, borderWidth: '0 0 1px' }} action={showEvalAlertText ? <ExpandLess sx={{marginTop:0.25}} onClick={()=>setShowEvalAlertText(!showEvalAlertText)}/> : <ExpandMore sx={{marginTop:0.25}} onClick={()=>setShowEvalAlertText(!showEvalAlertText)}/>}>
						<AlertTitle sx={{pb:0, mb:0}} onClick={()=>setShowEvalAlertText(!showEvalAlertText)}>Incomplete Evaluations</AlertTitle>
						{showEvalAlertText && <Typography whiteSpace="normal" variant="body2">One or more graduates have not completed their end-of-course evaluation.  The deadline for submission is <b>{moment(course.eval_end_date).format("M/d/yyyy")}</b>.  You may continue to close this course, but please ensure all graduates complete their assessments to ensure accurate course reporting.</Typography>}
					</Alert>
				</EvalHeader>
			)}
			{isPM && !course.closed_on && <EvalIntro>
				<Box>
					<Stack backgroundColor="#f6f6f6" sx={{p:2}} direction="row" spacing={2}>
						<Box><School fontSize="large"></School></Box>
						<Box>The following students will graduate the course and will be awarded a certificate of completion.  Their Valkyrie statuses will be upgraded as appropriate.</Box>
					</Stack>
				</Box>
				
				<Stack>
					{course.members && course.members.filter((m)=>(m.course_role_id == roles.STUDENT || m.course_role_id == roles.IUT) && m.graduated).map((m,i)=> 
						<CourseMember member={m} key={i} showRole={true} isClosed={false} canEdit={false} index={i} userIsAdmin={isAdmin}/>
					)}
					{course.members && !course.members.some((m)=>(m.course_role_id == roles.STUDENT || m.course_role_id == roles.IUT) && m.graduated) && <Box p={4}>No students will graduate this course.</Box>}
				</Stack>
				
			</EvalIntro>}
			<EvalOutro>
				<Box  p={8} pb={2} textAlign="center">
					Thank you for taking time to complete this assessment.
					<Stack alignItems="center" sx={{mt:4}}>
						<Alert   p={2} icon={false} severity="error" variant="outlined" sx={{background:'#fdeded', mt:2, maxWidth:'400px'}}>
							<Stack direction="row" spacing={1} alignItems="flex-start">
								<Typography >
									If you have a safety concern during or after this course, please submit a Safety Incident Report.
								</Typography>
							</Stack>
							<Box pt={1} textAlign="center">
								
								<Button color="error" variant="outlined" size="small" onClick={()=>{setIsEvaluating(false); setShowSafetyReport(true); }} startIcon={<HealthAndSafety/>}>Safety Incident Report</Button>
							</Box>
						</Alert>
					</Stack>
				</Box>
			</EvalOutro>
		</Eval>}

		<Stack spacing={1} mb={8}>

			<Box pb={2}>
				<Breadcrumbs separator="›">
					<RouterLink to="/">Home</RouterLink>
					{can(user,resources.PROGRAM, actions.MANAGE_COURSES) && <RouterLink to="/courses">Courses</RouterLink>}
					{course && <Typography>{course.name}</Typography>}
				</Breadcrumbs>
			</Box>

			{ course ? <Box mb={2} >

				{/* EVAL REMINDER */}
				{(!isPM || course.closed_by) && isEvalWindow && myRegistration && !myRegistration?.evaluated && (!myRegistration?.primary || course.closed_by) && <Card variant="outlined" className="warning-stripes" elevation={0} sx={{p:2, mb:2, border:'solid 1px', borderColor: theme.palette.warning.main, }}  >
					<Stack direction={{xs:'column', lg:'row'}} spacing={2} alignItems="center" justifyContent="space-between">
						<Box flex={1}>
							<Stack direction="row" alignItems="center" mb={1}>
								<WarningAmber mr={2} sx={{fontSize:'3rem', color:theme.palette.warning.main}} color={theme.palette.warning.main}/>
								<Stack ml={1} spacing={0.5}>
									<b>All Participants:</b>
									<div>To complete your training, please remember to submit your course evaluation. </div>
								</Stack>
							</Stack>
							
						</Box>
						<Box textAlign="right"><Button variant="contained" flex={1} color="warning" onClick={()=>setIsEvaluating(true)} sx={{alignSelf:'center'}} text="">Start Course Evaluation</Button></Box>
					</Stack>
					
				</Card>}

				{/* INSTRUCTOR REMINDER */}
				{!isPM && isInstructor && !!myRegistration?.primary && !myRegistration?.evaluated && <Card variant="outlined" className="warning-stripes" elevation={0} sx={{p:2, mb:2, border:'solid 1px', borderColor: theme.palette.warning.main, }}  >
					<Stack direction={{xs:'column', lg:'row'}} spacing={2} alignItems="center" justifyContent="space-between">
						<Box flex={1}>
							<Stack direction="row" alignItems="center" mb={1}>
								<WarningAmber mr={2} sx={{fontSize:'3rem', color:theme.palette.warning.main}} color={theme.palette.warning.main}/>
								<Stack ml={1} spacing={0.5}>
									<b>Instructor:</b>
									<div>At the conclusion of this course, please make sure to submit an end-of-course report. </div>
								</Stack>
							</Stack>
						</Box>
						<Box textAlign="right"><Button variant="contained" flex={1} color="warning" size="small" onClick={()=>setIsEvaluating(true)} sx={{alignSelf:'center'}} text="">Complete Report</Button></Box>
					</Stack>
					
				</Card>}

				{/* PM REMINDER */}
				{isPM && !course.closed_by && <Card variant="outlined" className="warning-stripes" elevation={0} sx={{p:2, mb:2, border:'solid 1px', borderColor: theme.palette.warning.main, }}  >
					<Stack direction={{xs:'column', lg:'row'}} spacing={2} alignItems="center" justifyContent="space-between">
						<Box flex={1}>
						<Stack direction="row" alignItems="center" mb={1}>
								<WarningAmber mr={2} sx={{fontSize:'3rem', color:theme.palette.warning.main}} color={theme.palette.warning.main}/>
								<Stack ml={1} spacing={0.5}>
									<b>Program Managers / Directors:</b>
									<div>At the conclusion of this course, please make sure to certify course results to promote Valkyrie graduates.</div>
								</Stack>
							</Stack>
							
						</Box>
						<Box textAlign="right"><Button variant="contained" flex={1} color="warning" size="small" onClick={begin_closing} sx={{alignSelf:'center'}} text="">Certify Course Results</Button></Box>
						
						<Notification
							right={!isMobile}
							anchorEl={closeError}
							open={!!closeError}
							maxWidth={300}
							background={theme.palette.error.main}
							color="white"
							onClose={()=>setCloseError(null)}>
								{!canClose ? 
									<Box>One or more students have incomplete statuses or missing comments.  This course cannot be closed until all student results are complete.</Box>
									: <Stack spacing={2} alignItems="center">
										<div>Some changes have not been saved.  Do you want to save them before continuing?</div>
										<div><Button size="small" color="white" sx={{color: theme.palette.error.main}} variant="contained" severity="error" onClick={(e)=>begin_closing(e,true)}>Save Changes and Continue</Button></div>
									</Stack>
								}
								
						</Notification>
					</Stack>
					
				</Card>}

				
				<Box >
					<Stack direction={{xs:"column", lg:"row"}} spacing={2} alignItems={{xs:"stretch", lg: "flex-start"}} >

						{/* SIDEBAR NOTICES */}
						<Stack direction="column" spacing={2} flex={1}>
							
							{/* COURSE DETAILS */}
							<Card variant="outlined" p={0} sx={{flex:1}} className="course-details">

								{/* MY COURSE STATUS */}
								<CourseStatus course={course} onCourseChange={(data)=>setCourse(data)} onError={(error)=>setEnrollError(error)}/>

								{/* COURSE DETAILS / MODIFY COURSE */}
								<Box >
									
									{!editing ? <Stack spacing={1.5} p={2} >
											<div>
												<div className="sub-title">Course Title:</div>
												<div>{course.name}</div>
											</div>
											<div>
												<div className="sub-title">Course Code:</div>
												<div>{course.code}</div>
											</div>
											<div>
												<div className="sub-title">Valkyrie Program:</div>
												<div>{course.program_name}</div>
											</div>
											<div>
												<div className="sub-title">Course Dates:</div>
												<div>{asDate(course.start_date)} {course.expected_end_date || course.end_date ? `- ${asDate(course.end_date || course.expected_end_date)}` : ''}</div>

												<Stack className="course-dates"  mt={1.5}>
													<Box >
														<div className="sub-title">Enrollment:</div>
														<div>{asDate(course.enrollment_start_date)} - {asDate(course.enrollment_end_date)}</div>
													</Box>
													<Box >
														<div className="sub-title">Evaluation Window:</div>
														<div>{asDate(course.eval_start_date)} - {asDate(course.eval_end_date)}</div>
													</Box>
												</Stack>
											</div>
											

											{!!course.syllabus && <div>
												<div className="sub-title">Valkyrie Syllabus</div>
												<div>{course.syllabus}</div>
											</div>}

											{course.instructions && <div>
												<div className="sub-title">Notes / Instructions</div>
												<div>{course.instructions.split('\n').map(l=><>{l}<br/></>)}</div>
											</div>}
										
									</Stack> :
										<Box p={2}>
											<form onSubmit={handleSubmit(save_course_info)}>
												<Stack spacing={2}>
													<Controller name="name" control={control}  render={({ field }) =>
														<TextField label="Course Name" size="small" required {...field} ></TextField>
													}/>
													
													{!course.closed_on && <>
														<Divider className="sub-title" sx={{fontWeight:'600'}}>Course Dates</Divider>
														<Stack direction="row" alignItems="center" spacing={1}>
															<Controller name="start_date" control={control}  render={({ field }) =>
																<TextField label="Start Date" size="small" type="date" required {...field} ></TextField>
															}/>
															<Controller name="expected_end_date" control={control}  render={({ field }) =>
																<TextField label="Expected Completion" type="date" required size="small" {...field} ></TextField>
															}/>
														</Stack>
														<Box pt={2}><Divider className="sub-title" sx={{fontWeight:'600'}}>Enrollment</Divider></Box>
														<Stack direction="row" alignItems="center" spacing={1}>
															<Controller name="enrollment_start_date" control={control}  render={({ field }) =>
																<TextField label="Begins" size="small" type="date" required {...field} ></TextField>
															}/>
															<Controller name="enrollment_end_date" control={control}  render={({ field }) =>
																<TextField label="Closes" type="date" required size="small" {...field} ></TextField>
															}/>
														</Stack>
													</>}
													<Box pt={2}><Divider className="sub-title" sx={{fontWeight: '600'}}>Evaluation Window</Divider></Box>
													<Stack direction="row" alignItems="center" spacing={1}>
														<Controller name="eval_start_date" control={control}  render={({ field }) =>
															<TextField label="Begins" size="small" type="date" required {...field} ></TextField>
														}/>
														<Controller name="eval_end_date" control={control}  render={({ field }) =>
															<TextField label="Closes" type="date" required size="small" {...field} ></TextField>
														}/>
													</Stack>
													<Box pt={2}>
														<Controller control={control} name="instructions" render={({ field }) => <TextField label="Notes / Instructions" size="small" multiline rows={4} fullWidth {...field} ></TextField>}/>
													</Box>
													{/* <FormControlLabel label="Open for Enrollment" control={<Controller name="is_open" control={control} render={({ field }) => {
														return <Checkbox checked={!!field.value}  {...field}  size="small"  />}}  />
													}/> */}
													<Stack pt={2} justifyContent="center" direction="row" spacing={2}>
														<Button variant="contained" size="small" color="primary" type="submit" text="">Save Changes</Button>
														<Button variant="outlined" size="small" onClick={(e)=>{e.preventDefault(); setEditing(false)}} text="">Cancel</Button>
													</Stack>
													{!course.closed_on && <Box textAlign="center">
														<Button color="error" size="small" onClick={cancel_course} text="">Cancel This Course</Button>
														<Notification
															anchorEl={confirmCancelation}
															open={!!confirmCancelation}
															maxWidth={300}
															background={theme.palette.error.main}
															color="white"
															onClose={()=>setConfirmCancelation(null)}>
																Are you sure you want to cancel this course? This action cannot be undone.
																<Box pt={1} textAlign="center"><Button size="small" color="white" variant="outlined" onClick={()=>cancel_course(true)}>Yes, continue</Button></Box>
														</Notification>
														
													</Box>}
												</Stack>
											</form>
										</Box>
									}
								</Box>

								<Can action={actions.MODIFY_PROGRAM_COURSE} id={course.program_id}>
									{!editing ? <Box className="card-footer" pb={2} textAlign="center">
										<Button variant="outlined" size="small" onClick={(e)=>{e.preventDefault(); setEditing(true)}}>Modify Course</Button>
									</Box> : <></>}
								</Can>
								
								{/* OVERALL COURSE STATUS */}
								{course.closed_on && <Box className="primary">
									<Divider></Divider>
									<Stack direction="row"  p={2} alignItems="center" spacing={1}>
										<Box><VerifiedOutlined fontSize="large"></VerifiedOutlined></Box>
										<Typography>This course was closed and results certified on {asDate(course.closed_on)}.</Typography>
									</Stack>
								</Box>}
								
								{enrollError ? <Box className="card-footer" p={2} textAlign="center" ><Typography color="error">{enrollError}</Typography></Box> :<></>}
							</Card>
							
							

							{/* SAFETY REPORT */}
							<Alert  p={2} icon={false} severity="error" variant="outlined" sx={{background:'#fdeded'}}>
								<Stack direction="row" spacing={1} alignItems="flex-start">
								<Box>
									<HealthAndSafety size="large" color="error" sx={{fontSize:"3rem"}}></HealthAndSafety>
								</Box>
									<Stack alignItems="flex-start">
										<Typography >
											If you have a safety concern during or after this course, please submit a Safety Incident Report.
										</Typography>
										<Box pt={1} textAlign="center">
											<Button color="error" variant="outlined" size="small" startIcon={<Edit/>}  onClick={()=>setShowSafetyReport(true)}>Safety Incident Report</Button>
										</Box>
									</Stack>
									
								</Stack>
								
							</Alert>

							{/* ENROLLMENT QR CODE */}
							{isInstructor && !course.closed_on && isEnrollmentWindow && <Card variant="outlined">
								<Box className="card-header">Scan to enroll</Box>
								<Box px={4} py={2} textAlign="center">
									<QRCodeSVG value={registerUrl} border={2} size="80%"/>
								</Box>
								<Box p={2} textAlign="center">
									<Button variant="outlined" size="small" onClick={(e)=>{e.preventDefault(); 	copyToClipboard(registerUrl);}}>Copy Registration URL</Button>
								</Box>
							</Card>}

							{/* EVALUATION STATUSES */}
							{(isAdmin || (isInstructor && !!myRegistration?.primary)) && course.eval_start_date <= today && <Card variant="outlined">
								<Box className="card-header">Course Evaluations</Box>
								<Stack px={2} py={1} alignItems="flex-end">
									<Box py={1} className="sub-title" sx={{fontWeight: '600'}}>{course?.evaluated} / {course?.enrollees} evaluations completed </Box>
									
									
									{course?.students > 0 && <LinearProgress sx={{width: '100%', height: 4, mt: 1, mb:0.5}} variant="determinate" value={course?.student_evals/course?.students*100}/>}
									{course?.students > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.student_evals} / {course?.students} Students </Box>}
									
									{course?.iuts > 0 &&  <LinearProgress sx={{width: '100%', height: 4, mt: 1, mb:0.5}} variant="determinate" value={course?.iut_evals/course?.iuts*100} />}
									{course?.iuts > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.iut_evals} / {course?.iuts} IUTs </Box>}

									{course?.instructors > 0 && <LinearProgress sx={{width: '100%', height: 4, mt: 1, mb:0.5}} variant="determinate" value={course?.instructor_evals/course?.instructors*100} />}
									{course?.instructors > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.instructor_evals} / {course?.instructors} Instructors </Box>}

									{course?.closed_on && <Box pt={2} pb={1} textAlign="center" width="100%">
										<Button variant="outlined" size="small" onClick={(e)=>{nav(`/courses/${course.code}/report`)}} startIcon={<BarChart/>}>View Course Report</Button>
									</Box>}
								</Stack>
							</Card>}

							{/* RESET COURSE (FOR ADMINS ONLY) */}
							{hasRole(user, roles.ADMIN) && config.system != "prod" && <Card variant="outlined">
								<Box p={2}>
									Reset this course.  Evaluations will be removed, all graduation statuses will be reset, and the course will be reopened.
									<Box p={2} pb={0} textAlign="center">
										<Button startIcon={<Lock/>} variant="contained" size="small" color="error" onClick={resetCourse}>Reset course</Button>
									</Box>
								</Box>
							</Card>}
						</Stack>

						{/* COURSE ROSTER */}
						<Stack spacing={2} sx={{flex:2}}>
							
							<Card variant="outlined">
								<div className="card-header">
									<div style={{float:'right'}}>
										{course.members.filter((m)=>m.course_role_id == roles.INSTRUCTOR).length} total
									</div>
									Instructor(s)
								</div>
								<List sx={{padding:'0 !important'}}>
									{course?.members.filter((m)=>m.course_role_id == roles.INSTRUCTOR).length < 1 && <Box p={2} textAlign="center">No instructors have been assigned to this course.</Box>}
									{course.members && course.members.filter((m)=>m.course_role_id == roles.INSTRUCTOR).sort((a,b)=>b.primary-a.primary).map((m,i)=> 
										<CourseMember member={m} key={i} showRole={false} isClosed={!!course.closed_by} canEdit={isPM} userIsAdmin={isAdmin} index={i} onMakePrimary={makePrimary}/>
									)}
								</List>
							</Card>
							<Card variant="outlined">
								<div className="card-header">
									<div style={{float:'right'}}>
										{course.members.filter((m)=>m.course_role_id == roles.IUT).length} total
									</div>
									Instructors Under Training
								</div>
								<Stack sx={{padding:'0 !important'}}>
									{course?.members.filter((m)=>m.course_role_id == roles.IUT).length < 1 && <Box p={2} textAlign="center">No Instructors Under Training have enrolled in this course.</Box>}
									{course.members && course.members.filter((m)=>m.course_role_id == roles.IUT).map((m,j)=> {
										return <CourseMember key={lastLoad+j} member={m}  showRole={false} userIsAdmin={isAdmin} isClosed={!!course.closed_by} canEdit={isAdmin || isPM || myRegistration?.primary} index={j} onChange={()=>setHasChanges(true)} />;
									})}
								</Stack>
							</Card>
							<Card variant="outlined">
								<div className="card-header">
									<div style={{float:'right'}}>
										{course.members.filter((m)=>m.course_role_id != roles.INSTRUCTOR && m.course_role_id != roles.IUT).length} total
									</div>
									Students
								</div>
								<List sx={{padding:'0 !important'}}>
									{course?.members.filter((m)=>m.course_role_id != roles.INSTRUCTOR && m.course_role_id != roles.IUT).length < 1 && <Box p={2} textAlign="center">No students have enrolled in this course.</Box>}
									{course.members && course.members.filter((m)=>m.course_role_id != roles.INSTRUCTOR && m.course_role_id != roles.IUT).map((m,j)=> {
										return <CourseMember member={m} key={lastLoad+j} showRole={false} isClosed={!!course.closed_by}  userIsAdmin={isAdmin} canEdit={isAdmin || isPM || myRegistration?.primary} index={j} onChange={()=>setHasChanges(true)}/>;
									})}
								</List>
							</Card>

						</Stack>
						
					</Stack>
				</Box>

			</Box> : <></>}

		</Stack>
		<Snackbar open={hasChanges} autoHideDuration={6000} anchorOrigin={{vertical:'bottom',horizontal:'center'}} >
			<Paper elevation={6}  sx={{flex:1, display:'block'}} >
				<Alert severity="error" variant="filled" sx={{border:"solid 1px white", display:'block'}} icon={false}>
					<Stack direction={{xs:'column', lg: 'row'}} alignItems="center" justifyContent="space-between" spacing={2} flex={1}>
						<Box mr={4} flex={1}>You have unsaved changes.</Box>
						<Stack direction="row" spacing={2}>
							<Button disabled={saving} size="small" color="white" sx={{color: theme.palette.error.main}} variant="contained" onClick={()=>save_statuses(false)}>{saving ? <CircularProgress size={24}></CircularProgress> : <>Save Changes</>}</Button>
							<Button size="small" color="inherit" variant="outlined" onClick={load}>Undo Changes</Button>
						</Stack>
					</Stack>
				</Alert>
			</Paper>
		</Snackbar>
	</div> ;
}
import { useEffect, useState } from "react";
import jax from "../helper/jax";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { Card, List, ListItemButton, Stack, Divider, Button, Menu, MenuItem, Box, Link, ListItem, IconButton, ListSubheader, styled, Rating } from "@mui/material";
import UserResult from "../components/UserResult";
import { Add, Cancel, Clear, Delete, DoDisturb, RemoveCircleOutline, Star, Timeline } from "@mui/icons-material";
import  { roles as userRoles, roleNames, unitRoles } from "../features/access";
import { useSelector } from "react-redux";
import UserSearch from "../components/UserSearch";
import CourseResult from "../components/CourseResult";
import Courses from './Courses';
import { Breadcrumbs, Typography } from "@mui/material";
import {can, resources, actions, hasRole, hasUnitRole} from '../features/access';
import CourseList from "../components/CourseList";
import ProfileDialog from "../components/ProfileDialog";
import ProgramGrads from "../components/reports/program_grads.tsx";
import { Tab, tabClasses } from '@mui/base/Tab';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import EvalHistory from "../components/reports/eval_history.tsx";


const TabValue = styled(Box)`
	text-align: center;
	font-size: 1.4rem;
	font-weight: bold;
`;

const SectionTab = styled(Tab)`
	text-align: center;
	font-size: 1.2em;
	border: solid 1px #ccc;
	border-width: 0px 1px 1px 0px;
	cursor: pointer;
	background-color: #f6f6f6;
	& * {
		opacity: 0.5;
	}
	flex:1;
	padding: 1rem 0;
	&:not(.${tabClasses.selected}):hover {
		background-color: #fcfcfc;
		& * {
			opacity: 1;
		}
	}
	&.${tabClasses.selected} {
		background-color: white;
		border-color: #ccc;
		& * {
			opacity: 1;
		}
		border-width: 0px 1px 0px 0px;
	}
	&:last-child {
		border-right-width: 0px;
	}
`;

const SectionTabs = styled(Tabs)`
	
`;

const SectionTabsList = styled(TabsList)`
	display: flex;
`;


export default function Program(props) {
	const params = useParams();
	const code = params.code || props.code;
	const [program, setProgram] = useState(null);
	const [courses, setCourses] = useState([]);
	const [reports, setReports] = useState({});
	const [roles, setRoles] = useState([]);
	const [leaderMenuBtn, setLeaderMenuBtn] = useState(null);
	const [addLeaderType, setAddLeaderType] = useState(false);
	const [showProfile, setShowProfile] = useState(null);
	const [rating, setRating] = useState(0);
	const user = useSelector((state) => state.data.user);

	const isAdmin = hasUnitRole(user, unitRoles.PROGRAM_ADMIN, program?.unit_id);

	useEffect(()=>{
		jax.get(`/app/programs/${code}`).then((data)=>{
			setProgram(data.program);
			setRating(data.program.overall_rating || 0);
			setRoles(data.roles);
			setCourses(data.courses);
			setReports(data.reports);
		});
	}, [code]);

	const addLeader = async function(user) {
		try {
			var result = await jax.post(`/app/programs/${program.code}/leaders`, {uid: user.uid, role_id: addLeaderType});
			setAddLeaderType(null);
			setRoles([...result.roles]);
		} catch (error) {
			console.error(error);
		}
	}

	const removeLeader = async function(uid, role) {
		try {
			var result = await jax.delete(`/app/programs/${program.code}/leaders/${uid}`, {
				role_id: role
			});
			setRoles([...result.roles]);
		} catch (error) {
			console.error(error);
		}
	}

	const nav = useNavigate();
	return <Stack spacing={2}>
		
		{program && <Box p={2}><Breadcrumbs separator="›">
			<RouterLink to=".." relative="path">Training Programs</RouterLink>
			<Typography>{program.name}</Typography>
		</Breadcrumbs></Box>}
		<Divider sx={{margin: "0 !important"}}></Divider>
		<Stack direction={{xs:"column", lg:"row"}} sx={{margin: "0 !important"}} alignItems={{xs:"stretch", lg: "flex-start"}} >
		
			<Stack spacing={0} flex={4}>
				{!!program && <Box >
					<Box p={2} style={{whiteSpace:'nowrap', textOverflow:'ellipsis'}}><h3>{program.name}</h3></Box>
					<Stack alignItems="flex-start" flex={{xs:12, lg:4}} mt={0} mb={{xs:1, lg:0}} px={2} pb={2}>
						<Stack direction={{xs:'column'}} spacing={0.5}>
							<div >{program.command}</div>
							<div >{program.location}</div>
						</Stack>
					</Stack>
				</Box>}
				<Divider sx={{margin:0}}></Divider>
				<Box pb={2}>
					<Box p={2} pb={0}>
						<h3>Program Leadership</h3>
					</Box>
					<Stack p={0}>
						<List py={0}>
							<ListSubheader>
									<Stack alignItems="center" direction="row"  justifyContent="space-between">
										<Box className="primary">Medical Director(s)</Box>
										{!!isAdmin && !addLeaderType && <Button size="small" onClick={()=>setAddLeaderType(userRoles.MED_DIR)}><Add fontSize="small"></Add> <Typography lineHeight={1} variant="body2">Add</Typography></Button>}
									</Stack>
							</ListSubheader>

							{roles.filter((r)=>r.program_role_id == userRoles.MED_DIR).map((r, i)=>(
								<ListItem sx={{ px: 0,  padding: "0 !important"}} key={i}>
									<ListItemButton sx={{padding: 0, paddingLeft:2}} onClick={()=>setShowProfile(r.uid)}>
										<Stack alignItems="center" direction="row" pl={2}  justifyContent="space-between" flex={1}>
											<Box flex={1}>{r.name}</Box>
											<Box pr={3} visibility={addLeaderType ? "hidden" : "visible"}>
												{!!isAdmin && <IconButton size="small" onClick={(e)=>{e.stopPropagation(); removeLeader(r.uid, r.program_role_id)}}><RemoveCircleOutline fontSize="small"></RemoveCircleOutline></IconButton>}
											</Box>
										</Stack>
									</ListItemButton>
								</ListItem>)
							)}

							{addLeaderType == userRoles.MED_DIR && isAdmin && <ListItem ><Stack p={0} flex={1} pl={2} pr={3}  direction="row" justifyContent="space-between" alignItems="center">
								<Box flex={1}>
									<UserSearch onSelect={addLeader}></UserSearch>
								</Box>
								<Box ml={1}>
									<IconButton size="small" onClick={(e)=>{e.stopPropagation(); setAddLeaderType(null);}}><Clear></Clear></IconButton>
								</Box>
							</Stack></ListItem>}
							
							<ListSubheader>
									<Stack alignItems="center" direction="row"  justifyContent="space-between">
										<Box className="primary">Program Director(s)</Box>
										{!!isAdmin && !addLeaderType && <Stack alignItems="center" justifyContent="flex-end" direction="row" className="primary">
											<Button size="small" onClick={()=>setAddLeaderType(userRoles.PROGRAM_DIR)}><Add fontSize="small"></Add> <Typography lineHeight={1} variant="body2">Add</Typography></Button>
										</Stack>}
									</Stack>
							</ListSubheader>

							{roles.filter((r)=>r.program_role_id == userRoles.PROGRAM_DIR).map((r, i)=>(
								<ListItem sx={{px: 0, padding: "0 !important"}}>
									<ListItemButton sx={{padding: 0, paddingLeft:2}} onClick={()=>setShowProfile(r.uid)}>
										<Stack alignItems="center" direction="row" pl={2}  justifyContent="space-between" flex={1}>
											<Box flex={1}>{r.name}</Box>
											<Box pr={3} visibility={addLeaderType ? "hidden" : "visible"}>
												{!!isAdmin && <IconButton size="small" onClick={(e)=>{e.stopPropagation(); removeLeader(r.uid, r.program_role_id)}}><RemoveCircleOutline fontSize="small"></RemoveCircleOutline></IconButton>}
											</Box>
										</Stack>
									</ListItemButton>
								</ListItem>)
							)}

							{addLeaderType == userRoles.PROGRAM_DIR && isAdmin && <ListItem><Stack p={0} flex={1} pl={2} pr={3}  direction="row" justifyContent="space-between" alignItems="center">
								<Box flex={1}>
									<UserSearch onSelect={addLeader}></UserSearch>
								</Box>
								<Box ml={1}>
									<IconButton size="small" onClick={(e)=>{e.stopPropagation(); setAddLeaderType(null);}}><Clear></Clear></IconButton>
								</Box>
							</Stack></ListItem>}

							<ListSubheader>
									<Stack alignItems="center" direction="row"  justifyContent="space-between">
										<Box className="primary">Program Manager(s)</Box>
										{!!isAdmin && !addLeaderType && <Stack alignItems="center" justifyContent="flex-end" direction="row" className="primary">
											<Button size="small" onClick={()=>setAddLeaderType(userRoles.PROGRAM_MGR)}><Add fontSize="small"></Add> <Typography lineHeight={1} variant="body2">Add</Typography></Button>
										</Stack>}
									</Stack>
							</ListSubheader>

							{roles.filter((r)=>r.program_role_id == userRoles.PROGRAM_MGR).map((r, i)=>(
								<ListItem sx={{px: 0, padding: "0 !important"}}>
									<ListItemButton sx={{padding: 0, paddingLeft:2}} onClick={()=>setShowProfile(r.uid)}>
										<Stack alignItems="center" direction="row" pl={2}  justifyContent="space-between" flex={1}>
											<Box flex={1}>{r.name}</Box>
											<Box pr={3} visibility={addLeaderType ? "hidden" : "visible"}>
												{!!isAdmin && <IconButton size="small" onClick={(e)=>{e.stopPropagation(); removeLeader(r.uid, r.program_role_id)}}><RemoveCircleOutline fontSize="small"></RemoveCircleOutline></IconButton>}
											</Box>
										</Stack>
									</ListItemButton>
								</ListItem>)
							)}

							{addLeaderType == userRoles.PROGRAM_MGR && isAdmin && <ListItem><Stack p={0} flex={1} pl={2} pr={3} pb={2} direction="row" justifyContent="space-between" alignItems="center">
								<Box flex={1}>
									<UserSearch onSelect={addLeader}></UserSearch>
								</Box>
								<Box ml={1}>
									<IconButton size="small" onClick={(e)=>{e.stopPropagation(); setAddLeaderType(null);}}><Clear></Clear></IconButton>
								</Box>
							</Stack></ListItem>}				
						</List>						
					</Stack>
				</Box>
			</Stack>
			<Divider orientation="vertical" flex={1} flexItem></Divider>
			<Stack spacing={4} flex={8}>
				<Box>
					<form>
						<Tabs defaultValue={1}>
							<SectionTabsList sx="display:flex">
								<SectionTab value={1}>
									<TabValue >{program?.graduates}</TabValue>
									<Box className="sub-title xs">Graduates</Box>
								</SectionTab>
								<SectionTab value={2}>
									<TabValue>{courses?.length}</TabValue>
									<Box className="sub-title xs">Courses</Box>
								</SectionTab>
								<SectionTab value={3}>
									{/* <Box lineHeight={0.8}> */}
									{/* <Box className="" pt={0.5} pb={0.25}><Rating size="small" name="overall_rating" icon={<Star fontSize="inherit" color="black"/>} sx={{"& *": {opacity:1}}} value={rating.toFixed(2)} defaultValue={rating.toFixed(2)} precision={0.5} readOnly/></Box> */}
									{/* <Timeline sx={{fontSize: "1.9rem"}}></Timeline> */}
									<TabValue>{rating.toFixed(1)} / 5</TabValue>
									<Box className="sub-title xs">Evaluation</Box>
									{/* </Box> */}
									
								</SectionTab>
								
							</SectionTabsList>
							<TabPanel value={1}>
								<Stack direction="row" mt={3} spacing={2} justifyContent="space-evenly">
									<Card variant="outlined" >
										<Box padding={2}>
											<Box className="sub-title xs">Graduates Trained</Box>
											<TabValue >{program?.graduates}</TabValue>
										</Box>
									</Card>
									<Card variant="outlined" >
										<Box padding={2}>
											<Box className="sub-title xs">Instructors Trained</Box>
											<TabValue >{program?.iuts}</TabValue>
										</Box>
									</Card>
								</Stack>
								<ProgramGrads  data={reports.students} height={300}/>
							</TabPanel>
							<TabPanel value={2}>
								<CourseList courses={courses}></CourseList>
							</TabPanel>	
							<TabPanel value={3}>
								<EvalHistory data={reports.evals} ></EvalHistory>
							</TabPanel>	
						</Tabs>
					</form>
				</Box>
			</Stack>
			<ProfileDialog uid={showProfile} onClose={()=>setShowProfile(null)}></ProfileDialog>
		</Stack>
	</Stack>;
}
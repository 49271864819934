import { Card, CardContent, TextField, Stack, Button, Box, Link, Typography, LinearProgress, Dialog, Divider, Alert, AlertTitle, CircularProgress, Skeleton } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import fb_app from '../helper/firebase'
import {signInWithEmailAndPassword, sendPasswordResetEmail, getAuth, confirmPasswordReset, getIdTokenResult} from 'firebase/auth';
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import jax from "../helper/jax";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import FeedbackButton from "../components/FeedbackButton";
import { signOut } from "../features/data";
import { setTenantInfo } from "../features/app";
import { hasFeature } from "../store";

const auth = getAuth(fb_app);
export default function Login(props) {
	var {authState} = props; 
	const [initializing, setInitializing] = useState(true);
	const [loginError, setLoginError] = useState(null);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [showPasswordReset, setShowPasswordReset] = useState(false);
	const [passwordResetEmail, setPasswordResetEmail] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setPasswordConfirmation] = useState("");
	const [newPasswordError, setNewPasswordError] = useState(null);
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null);
	const [confirmVerification, setConfirmVerification] = useState(false);
	const [loading, setLoading] = useState(false);
	const path = useLocation();
	const nav = useNavigate();
	const systemName = useSelector(state=>state.data.systemName);
	const tenantInfo = useSelector(state=>state.app.tenantInfo);

	const dispatch = useDispatch();
	
	const {control , handleSubmit, watch, formState: { errors }} = useForm({
		email: '',
		password: ''
	});

	window.addEventListener('bad_ticket', function() {
		getAuth().signOut().then(()=>{
			dispatch(signOut());
			setLoading(false);
		});
	});

	var params = useParams();
	const email = watch('email');
	
	const login = async function(data) {
		setLoading(true);
		try {
			auth.tenantId = tenantInfo.t;
			var userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);

			// if (!userCredential.user.emailVerified) {
			// 	await getAuth().signOut();
			// 	setRequiresVerification(true);
			// 	setLoading(false);
			// 	return;
			// }

			if (path.pathname.startsWith("/login")) {
				nav('/', {replace: true});
			}
		} catch(error)  {
			setLoading(false);
			console.log(error);
			if (error.code == 'auth/invalid-email' ) {
				setLoginError('The email you entered is invalid.');
			} else if (error.code == 'auth/user-not-found' || error.code == 'auth/invalid-login-credentials' || error.code == 'auth/invalid-credential') {
				setLoginError('The email and password combination you entered is invalid.');
			} else if (error.code == 400) {
				setLoginError("The email and password combination you entered is invalid.");
			}
		} finally {
			
		
		}
	};

	
	const send_password_email = async function() {
		auth.tenantId = tenantInfo.t;
		sendPasswordResetEmail(auth, passwordResetEmail).then(() => {
			setShowForgotPassword(false);
			setLoginError('A password reset email has been sent to the address you provided.');
		}).catch((error) => {
			console.log(error);
			if (error.code == 'auth/invalid-email') {
				setLoginError('The email you entered is invalid.');
			} else if (error.code == 'auth/user-not-found') {
				setLoginError('The email you entered is not associated with an account.');
			}
			
		});
	}

	const reset_password = async function() {
		if (newPassword != confirmNewPassword) {
			setConfirmNewPasswordError('Passwords do not match');
			return;
		}
		setConfirmNewPasswordError(null);
		setNewPasswordError(null);
		let params = new URL(document.location).searchParams;
		auth.tenantId = tenantInfo.t;
		confirmPasswordReset(auth,params.get('oobCode'), newPassword).then(() => {
			setShowPasswordReset(false);
			setLoginError('Your password has been reset.  Please login with your new password.');
		}).catch((error) => {
			console.log(error);
			if (error.code == 'auth/expired-action-code') {
				setNewPasswordError('The password reset link you used has expired.  Please request a new one.');
			} else if (error.code == 'auth/invalid-action-code') {
				setNewPasswordError('The password reset link you used is invalid.  Please request a new one.');
			} else if (error.code == 'auth/user-disabled') {
				setNewPasswordError('Your account has been disabled.  Please contact the administrator.');
			} else if (error.code == 'auth/user-not-found') {
				setNewPasswordError('The email you entered is not associated with an account.');
			} else {
				var segs = error.message.split(':');
				if (segs.length > 1) {
					setNewPasswordError(segs[1].trim().replace(/\s\(.+\/.+/, ""));
				} else {
					setNewPasswordError(error.message);
				}
			}
			
		});
	}

	useEffect(()=>{
		
		let params = new URL(document.location).searchParams;
		let mode = params.get("mode");
		let verified = params.get("v");

		if (!authState) {
			setLoginError(null);
			
			if (mode == 'resetPassword') {
				setShowPasswordReset(true);
			} else if (verified == '1') {
				setConfirmVerification(true);
			}
		}
		
	}, [authState]);

	useEffect(()=>{
		
		jax.get('/app/system').then((data)=>{
			dispatch(setTenantInfo(data));
			setInitializing(false);
			
		});
	}, []);

	return <Stack p={2} className="full-height" direction="column" alignItems="center" justifyContent="center" sx={{position:'absolute', top:0,left:0, bottom:0, right:0}} >
		<Box sx={{color: "black"}} height="100%" mt={3} pb={4} alignItems="center" textAlign="center">
			<img src="/images/logo_colorized.png" style={{width: 320, maxWidth: '90%', visibility: initializing ? 'hidden' : 'visible'}}></img>
			{!authState && !showPasswordReset ? 
				<Stack mt={5} alignItems="center">
					<Card sx={{maxWidth: 400}}>
						<form onSubmit={handleSubmit(login)}>
							<CardContent sx={{visibility: initializing ? 'hidden' : 'visible'}}>
								<Stack spacing={2}>
									<Box pb={2} textAlign="left"><Typography>Welcome to the Valkyrie Emergency Whole Blood Transfusion Training portal.  Please sign in below....</Typography></Box>
									<Controller name="email" control={control} rules={{required: 'Required'}} render={({ field }) => <TextField  autoComplete="username" inputProps={{type:"email"}} label="Email" disabled={loading} helperText={errors.email?.message} error={!!errors.email} size="small" {...field} />}/>
									<Controller name="password" control={control} rules={{required: 'Required'}} render={({ field }) => <TextField type="password" autoComplete="current-password" label="Password" disabled={loading} helperText={errors.password?.message} error={!!errors.password} size="small" {...field} />}/>
									<Stack direction="row" spacing={2} justifyContent="flex-end">
										<FeedbackButton variant="contained" color="primary" type="submit" loading={loading} disabled={loading}>Login</FeedbackButton>
										{/* <Button variant="contained" color="primary" type="submit" disabled={loading}>Login</Button> */}
									</Stack>
								</Stack>
								<Stack direction={{xs:'column', lg:'row'}} spacing={1} mt={4} justifyContent="center" flex={1}>
									<RouterLink to={`/register/${params["*"]}`}>Register an Account</RouterLink>
									<Box mx={1}><Divider orientation="vertical"   /></Box>
									<Link onClick={()=>setShowForgotPassword(true)}>Forgot Your Password?</Link>
								</Stack>
								
							</CardContent>
						</form>
					</Card>
					{loginError && <Box pt={2}><Typography color="red">{loginError}</Typography></Box>}
				</Stack> : <Box sx={{mt:'1rem', px: '2rem', color: 'black'}}><LinearProgress color="inherit"/></Box>}
			</Box>
			<Dialog open={showForgotPassword} onClose={()=>setShowForgotPassword(false)} >
				<Box className="card-header">Reset Your Password</Box>
				<Stack maxWidth={500} spacing={2} p={2}>
					<Box>Please enter the emaill address for your account.  An email with a link to reset your password will be sent to this address if it is a known account. </Box>
					<Box><TextField fullWidth size="small" value={passwordResetEmail} onChange={(e)=>setPasswordResetEmail(e.currentTarget.value)} label="Email Address"></TextField> </Box>
					<Stack direction="row" justifyContent="space-between" textAlign="right">
						<Button size="small" onClick={()=>setShowForgotPassword(false)}>Cancel</Button>
						<Button variant="contained" size="small" disabled={!passwordResetEmail} onClick={send_password_email}>Send Reset Email</Button>
					</Stack>
				</Stack>
			</Dialog>
			<Dialog open={showPasswordReset} onClose={(e,reason) => {if (reason != 'backdropClick') setShowPasswordReset(false)}} >
				<Box className="card-header">Reset Your Password</Box>
				<Stack maxWidth={500} spacing={2} p={2}>
					<Box>Please enter a new password for your account.</Box>
					<Box><TextField fullWidth type="password" size="small" value={newPassword} onChange={(e)=>setNewPassword(e.currentTarget.value)} label="New Password"></TextField> </Box>
					<Box><TextField fullWidth type="password" size="small" value={confirmNewPassword} onChange={(e)=>setPasswordConfirmation(e.currentTarget.value)} label="Re-enter Password" error={!!confirmNewPasswordError} helperText={confirmNewPasswordError}></TextField> </Box>
					{newPasswordError && <Box ><Typography variant="body2" color="error">{newPasswordError}</Typography></Box>}
					<Stack direction="row" justifyContent="space-between" textAlign="right">
						<Button size="small" onClick={()=>setShowPasswordReset(false)}>Cancel</Button>
						<Button variant="contained" size="small" disabled={!newPassword} onClick={reset_password}>Set My Password</Button>
					</Stack>
				</Stack>
			</Dialog>
			<Dialog open={confirmVerification} onClose={(e,reason) => {setConfirmVerification(false)}} >
			<Box className="card-header">Email Verified</Box>
			<Stack maxWidth={500} spacing={2} p={2}>
				<Box>Thank you for verifying your email address.  You are now able to access administrative functions associated with your account.</Box>
				<Stack direction="row" justifyContent="center">
					<Button size="small" onClick={()=>setConfirmVerification(false)} endIcon={<ArrowForward/>}>Continue</Button>
				</Stack>
			</Stack>
		</Dialog>
		</Stack>;

}
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Box, Stack, Button, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FeedbackButton from './FeedbackButton';
import jax from '../helper/jax';
import { getAuth } from 'firebase/auth';
import app from '../helper/firebase';
import { useNavigate } from 'react-router-dom';


export function VerifyEmail(props) {
	const user = useSelector((state) => state.data.user);

	const [requiresVerification, setRequiresVerification] = useState(false);
	const [sendingVerificationEmail, setSendingVerificationEmail] = useState(false);
	const [verificationEmailSent, setVerificationEmailSent] = useState(false);
	const nav = useNavigate();

	async function reverify() {
		try {
			//await sendEmailVerification(authUser);
			setSendingVerificationEmail(true);
			await jax.post('/app/auth/verify', {email: user.email});
			setSendingVerificationEmail(false);
			setVerificationEmailSent(true);
			//sign_out();
		} catch(err) {
			setSendingVerificationEmail(false);
			setVerificationEmailSent(false);
		}
	}

	useEffect(()=>{
		if (!requiresVerification) {
			setSendingVerificationEmail(false);
			setVerificationEmailSent(false);
		}
	}, [requiresVerification]);

	useEffect(()=>{
		var auth = getAuth(app);
		if (auth && !auth.currentUser?.emailVerified) {
			setRequiresVerification(true);
		}
		
	}, []);

	return <>
		
		<Dialog open={requiresVerification}>
			{requiresVerification ? <Alert severity={!verificationEmailSent ? "warning" : "success"} sx={{maxWidth: 600}}>
				<AlertTitle>{verificationEmailSent ? "Verification Email sent" : "Administrative Email Address Unverified"}</AlertTitle>
				<Stack spacing={1} flex={1}>
					{!verificationEmailSent && <Box py={2}>Administrative access requires your email address to be verified.  To verify your email address, please click the button below.  An email will be sent promptly to your address (<b>{user.email}</b>) with instructions to confirm access to the account.</Box>}
					{verificationEmailSent && <Box py={2}>An verification email has been sent to {user.email}.  Please check your inbox and use the link within to continue.</Box>}
					{!verificationEmailSent && <Stack flex={1} direction={{lg:"row", xs:"column"}} spacing={1} justifyContent="space-between">
						<Button size="small" onClick={()=>{ nav('/');}} startIcon={<ArrowBack />}>Home</Button>
						<FeedbackButton variant="contained" size="small" loading={sendingVerificationEmail} onClick={reverify}>Send Verification Email</FeedbackButton>
					</Stack>}
					{verificationEmailSent && <Box textAlign="center">
						<Button size="small" onClick={()=>{setRequiresVerification(false); setVerificationEmailSent(false)}}>Close</Button>
					</Box>}
				</Stack> 
			</Alert> : <>
				<CircularProgress  size="160px" thickness={1} sx={{'& circle': {stroke:'#f0dfbc'}}}></CircularProgress>
				<Stack alignItems="center" justifyContent="center" sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '1.5rem'}}>
					<img src="/images/logo_colorized.png" height="140px" width="140px"></img>
				</Stack>
			</>}
		</Dialog>
	</>
}
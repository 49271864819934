import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import {  List, ListItem, ListItemButton, Stack, Box, Divider, Chip, Card } from '@mui/material';
import { asDate, today, utc_date } from '../helper/util';
import { roles } from '../features/access';
import { AccessTime, CalendarMonth, Cancel, CheckCircle, CheckCircleOutline, LockOpen, MoreTime, Pending } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTheme } from '@emotion/react';

const CourseStatus = (props) => {
	const {course} = props;
	const theme = useTheme();
	if (course.canceled_on) {
		return <Chip size="small" className="" icon={<Cancel/>} color="error" label="Canceled" />;
	} else if (course.closed_on) {
		//If the role_id is present, this result is in the context of a student. Show their course outcome
		if (course.role_id == roles.STUDENT || course.role_id == roles.IUT) {
			if (course.graduated === 1) {
				return <Chip size="small" className="" icon={<CheckCircle/>} color="success" label="Graduate" ></Chip>;
			} else if (course.graduated === 0) {
				return <Chip size="small" className="" icon={<Cancel/>} color="error" label="Disenrolled" ></Chip>;
			} else {
				return <Chip size="small" className="" icon={<MoreTime/>} color="warning" label="Continue Training" ></Chip>;
			}
		}
		return <Chip size="small" className="" icon={<CheckCircle/>} color="success" label="Concluded" ></Chip>;
	} else if (course.start_date && course.start_date > today()) {
		if (course.enrollment_end_date && course.enrollment_end_date > today()) {
			return <Chip size="small" className="" sx={{px:0.25}} icon={<LockOpen/>}  color="warning" label="Enrolling"></Chip>;
		} else {
			return <Chip size="small" className="" icon={<AccessTime/>}  label="Upcoming"></Chip>;
		}	
	} else if (course.start_date && course.start_date <= today()) {
		return <Chip size="small" color="disabled" className="" icon={<Pending/>}  label="In Progress"></Chip>;
	} 

	return <>{today()}</>;
}

export default function CourseList(props) {
	const {courses, showRole, showInstructor, enableSelection, onSelect} = props;
	const nav = useNavigate();
	
	const [selected, setSelected] = React.useState(null);

	const handleClick = (id, code) => {
		if (enableSelection) {
			setSelected(id);
			onSelect(id, code)
		} else {
			nav(`/courses/${code}`)
		}
	}

	return <List p={0} py={0} sx={{paddingBottom: '0 !important'}} className="course-list">
		{courses && courses.map((c,i)=>{
			return <Box key={i} className={`${selected==c.id ? 'selected' : ''}`} typography="body1"><ListItemButton selected={selected==c.id} onClick={()=>handleClick(c.id, c.code)} >
				<Stack flex={1} key={i} direction="row" justifyContent="space-between"  >
					<Stack spacing={0.5}>
						<div><b>{c.name}</b></div>
						<div className="sub-title">{c.program_name}</div>
						<div>{c.role_name}</div>
					</Stack>
					<Box textAlign="right">
						<Stack direction="row" alignItems="center" mb={0.75} sx={{color:'#666'}}>
							<CalendarMonth color='#ccc' fontSize="16px" sx={{mr:0.25}}></CalendarMonth>
							<div className="sub-title">
								{asDate(c.start_date)}
								{/* {c.end_date ? <span> - {asDate(c.end_date)}</span> : ''} */}
							</div>
						</Stack>
						<div>
							<CourseStatus course={c}></CourseStatus>
						</div>
						
					</Box>
				</Stack>
			</ListItemButton>
			{i < courses.length-1 && <Divider sx={{my: 0}}/> }
			</Box>
		})}
	</List>
}
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system';
import jax from '../helper/jax';
import { Card, Divider, List, ListItem, ListItemButton, Paper, Stack } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { asDate } from '../helper/util';
import { useNavigate, useParams } from 'react-router-dom';
import SafetyReport from './SafetyReport';

export default function SafetyReports(props) {

	const {unit} = props;

	const {object_id, tab} = useParams();

	const [reports, setReports] = useState();
	useEffect(function() {
		if (object_id) return;
		if (unit) {
			jax.get(`/app/admin/units/${unit.id}/safety_reports`).then(eval_data => {
				setReports(eval_data.reports);
			});
		} else {
			jax.get(`/app/safety_reports`).then(eval_data => {
				setReports(eval_data.reports);
			});
		}
		
	}, [object_id]);

	const nav = useNavigate();

	const columns  = [{
		field: 'submitted',
		headerName: 'Submitted',
		valueGetter: (params) => asDate(params.row.submitted),
		flex: 1
	}, {
		field: 'type',
		headerName: 'Report',
		flex: 1
	}, {
		headerName: 'Program',
		field: 'program_name',
		flex: 1
	}, {
		field: 'incident_type',
		headerName: 'Incident Type',
		flex: 1,
		valueGetter: (params) => params.row.incident_code == "OTHER" ? "Other..." : params.row.incident_type,
	}]

	return object_id && (!unit || tab == 'safety')  ? <SafetyReport uuid={object_id}/> : <Box p={0}>
			{!!reports?.length && <List>
				<ListItem>
					<Stack direction="row" spacing={2} flex={1} py={1} fontWeight={600}>
						<Box flex={1} >Date</Box>
						<Box flex={2} >Type</Box>
						<Box flex={3} >Program</Box>
						<Box flex={3} >Description</Box>
					</Stack>
				</ListItem>
				<Divider />
				{reports.map((r, i)=><ListItem key={i} sx={{p:0}} divider>
					<ListItemButton onClick={()=>nav(`${r.uuid}`)}>
						<Stack direction="row" spacing={2} flex={1} py={1}>
							<Box flex={1} >{asDate(r.submitted)}</Box>
							<Box flex={2} >{r.type}</Box>
							<Box flex={3} >{r.program_name}</Box>
							<Box flex={3} >{r.incident_type}</Box>
						</Stack>
					</ListItemButton>
				</ListItem>)} 
			</List>}
			
			
			
			{/* <DataGrid rows={reports} columns={columns} getRowId={(row)=>row.uuid} getRowClassName={()=>"data-grid-row-clickable"} disableColumnMenu={true} sx={{border:0}} onRowClick={(e)=>nav(`${e.id}`)} />} */}
		</Box>
	
}
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Box, Divider, IconButton, Backdrop } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function Expando(props) {
	
	const {open, children, disableFor, onToggle} = props;
	const [show, setShow] = useState(false);
	var disable = useMediaQuery(theme => theme.breakpoints.up(disableFor));

	
	return <Box sx={{position: "relative"}}>
		<Backdrop sx={{zIndex: 6}} open={show} onClick={()=>setShow(false)} invisible></Backdrop>
		<Box className={`${!disable && 'expando'} ${!disable && open && "shown"}`}>
			<Box className="expando-content">
				<Box>{children}</Box>
			</Box>
			{!disable && open && <Box textAlign="center" alignSelf="center" variant="outlined" width="100%"><Divider><IconButton sx={{p:0}} onClick={()=>onToggle(!open)}><ExpandLess/> </IconButton></Divider></Box>}	
		</Box>
		{!disable && !open && <Box textAlign="center" alignSelf="center" variant="outlined" width="100%"><Divider><IconButton sx={{p:0}} onClick={()=>onToggle(!open)}><ExpandMore/></IconButton></Divider></Box>}
	</Box>
}
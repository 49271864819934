import Group from "@mui/icons-material/Group";
import Home from "@mui/icons-material/Home";
import Settings from "@mui/icons-material/Settings";
import TaskAlt from "@mui/icons-material/TaskAlt";
import DoDisturb from "@mui/icons-material/DoDisturb";
import { Lock, LockClock, PendingActions } from "@mui/icons-material";
import { Forward, Add, AssignmentTurnedIn, Block, Clear, ContentPaste, ContentPasteOff, MoreTime, Pending, Stars } from "@mui/icons-material";

export default {
	"Home": Home,
	"Group": Group,
	"Settings": Settings,
	"TaskAlt": TaskAlt,
	"DoDisturb": DoDisturb,
	"PendingActions": PendingActions,
	"AssignmentTurnedIn": AssignmentTurnedIn,
	"ContentPasteOff": ContentPasteOff,
	"Forward": Forward,
	"Add": Add,
	"MoreTime": MoreTime,
	"Stars": Stars,
	"TaskAlt": TaskAlt,
	"Pending": Pending,
	"Block": Block,
	"Lock": Lock,
	"LockClock": LockClock
}

import { useEffect, useState } from "react";
import jax from "../helper/jax";
import {Card, List, ListItemButton, Divider, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Pagination, Breadcrumbs, Typography} from "@mui/material";
import { asDate } from "../helper/util";
import { useNavigate } from "react-router-dom";
import { Icon, Stack, Button, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import NewCourse from "../components/NewCourse";
import CourseResult from "../components/CourseResult";
import CourseList from "../components/CourseList";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link as RouterLink } from "react-router-dom";
import { hasRole } from "../features/access";
import { getSearchClient } from "../helper/search";

export default function Courses(props) {
	
	var nav = useNavigate();
	const [courses, setCourses] = useState([]);
	const [creating, setCreating] = useState(false);
	const [includeComplete, setIncludeComplete] = useState(false);
	const [includeCanceled, setIncludeCanceled] = useState(false);
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(false);
	const user = useSelector((state) => state.data.user);

	const [program, setProgram] = useState("");
	const {config} = useSelector((state) => state.data);
	const tenantInfo = useSelector(state=>state.app.tenantInfo);
	const search_client = getSearchClient(tenantInfo?.search_key);
	
	const index = search_client.initIndex(config.course_index);

	useEffect(()=>{
		var programs = null;
		var q = [];
		var tags = [];
		if (program) {
			q.push(`(program_id=${program})`);
		} else {
			
				
				programs = user.programs.map((x)=>`(program_id=${x.id})`);
				q.push(`(${programs.join(' OR ')})`);
			
		}

		if (!includeCanceled) {
			tags.push('-canceled');
		}
		if (!includeComplete) {
			tags.push('-closed');
		}
		
		var query = undefined;
		if (q.length) {
			query = q.join(' AND ');
		}

		index.search("*", {
			//attributesToRetrieve: ['uid', 'name', 'role_id','affiliation_id', 'agency_id', 'rank_id', 'photo'],
			filters: query,
			hitsPerPage: 25,
			page: page-1,
			tags: tags
		  }).then(( resp ) => {
			var c = resp.hits.map(h=>{
				h.start_date = asDate(h.start_date, 'YYYY-MM-DD');
				h.expected_end_date = asDate(h.expected_end_date, 'YYYY-MM-DD');
				h.end_date = asDate(h.end_date, 'YYYY-MM-DD');
				h.enrollment_end_date = asDate(h.enrollment_end_date, 'YYYY-MM-DD');
				h.canceled_on = asDate(h.canceled_on, 'YYYY-MM-DD');
				h.closed_on = asDate(h.closed_on, 'YYYY-MM-DD');
				return h;
			})
			setCourses(c);
			setPages(resp.nbPages);
		  });

		 //jax.get('/app/courses').then((data)=>setCourses(data));
	}, [program, includeComplete, includeCanceled, page]); 

	return <>
		<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{pb: 2}}>
			
			<Breadcrumbs separator="›">
				<RouterLink to="/">Home</RouterLink>
				<Typography>Courses</Typography>
			</Breadcrumbs>

			<Button variant="contained" onClick={()=>setCreating(true)}>
				<Add ></Add>
				&nbsp;New Course
			</Button>
		</Stack>
		<Card>
			<Box className="card-header">All Courses</Box>
			<Box p={2}>
				<form onSubmit={(e) => {  }}>
					<Grid container spacing={2} alignItems="start">

						<Grid item="true" xs={12} lg={4}>
							<FormControl size="small" fullWidth={true} sx={{ minWidth: 120 }} >
								<InputLabel id="affiliation-label">Program</InputLabel>
								<Select
									labelId="program-label"
									id="program"
									
									label="program"
									value={program}
									onChange={(e) => setProgram(e.target.value)}
								>
									<MenuItem key="All" selected value="" >(All)</MenuItem>
									{user.programs.map((pgm) => <MenuItem key={pgm.id} value={pgm.id}>{pgm.name}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item="true" xs={12} lg={6}>
							<Grid container >
								<Grid xs={12} lg="auto">
									<FormControlLabel label="Show Concluded" control={<Checkbox checked={includeComplete} onChange={()=>setIncludeComplete(!includeComplete)} size="small"  />}  />
								</Grid>
								<Grid xs={12} lg="auto">
									<FormControlLabel label="Show Canceled" control={<Checkbox checked={includeCanceled} onChange={()=>setIncludeCanceled(!includeCanceled)} size="small"  />}  />
								</Grid>
							</Grid>	
						</Grid>
						
					</Grid>

				
				</form>
			</Box>
			<Divider></Divider>
			<CourseList courses={courses}/>
			{pages > 1 && <>
				<Divider flex={1}></Divider>
				<Stack  alignItems="center">
					
					<Box p={2} >
						<Pagination count={pages} page={page} onChange={(e, p)=>setPage(p)}></Pagination>
					</Box>
				</Stack>
			</>}
		</Card>
		<NewCourse open={creating} onCancel={()=>setCreating(false)} onCreate={(code)=>nav(`/courses/${code}`)}></NewCourse>
	</>
}


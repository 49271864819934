import { Box, Typography, Stack, FormControlLabel, Table, Divider, TableBody, TableHead, Radio, TextField, TableCell, TableRow, RadioGroup, useMediaQuery, Checkbox } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import {Unstable_NumberInput as NumberInput} from '@mui/base/Unstable_NumberInput';
import { set } from 'react-hook-form';
import { useEffect, useState } from "react";
import { evaluate } from "../../helper/util";


export default function(props) {

	const {section, invalid, answers, onAnswer, is_lead} = props;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [blocks, setBlocks] = useState(section.blocks);
	

	const filterBlocks = () => {			
		var blks = section.blocks.filter(b=>{
			return !b.condition || evaluate(b.condition, { ...answers.answers, _course_code: answers.course_code, _role: answers.role_id, _is_lead: is_lead});
		})
		setBlocks(blks);
	}

	const recordAnswer = (e) => {	
		var block_id = e.target.closest('[block]').getAttribute('block');
		var ans = e.target.value;
		if (e.target.type == 'radio' && !isNaN(ans)) {
			ans = parseInt(ans);
		} else if (e.target.type == 'checkbox') {
			var val = e.target.value;
			var current = (answers.answers[block_id][e.target.name] || '').split('|');
			if (e.target.checked) {
				current.push(val);
			} else {
				current = current.filter(x=>x!=val);
			}
			ans = current.join('|');
		}

		if (onAnswer)	{
			onAnswer({block_id: block_id, answer_id: e.target.name, answer: ans})
		}
	}

	useEffect(()=>{
		filterBlocks();
	}, [answers, section]);

	var likert = ["Poor", "Needs Attention", "Average", "Good", "Excellent"];
	return section ? <Stack direction="column" spacing={0} pb={2}>
		
		{blocks.map((block, i) => {
			return <Box pb={0} key={i} block={block.block_id}>
				
				{/* {i > 0 &&<Divider sx={{mb:2}}></Divider>} */}
				
				{block.type == "likert" && <Box pt={1}>
					<Box p={1} pt={3}><Typography ml={1} className="prompt">{block.prompt}</Typography></Box>
					{!isMobile && <Divider></Divider>}
					<Table className="likert-table">
						{!isMobile && <TableHead sx={{verticalAlign:"bottom"}}>
							<TableRow >
								<TableCell>
									{block.prompt && <Box sx={{fontWeight: 'bold', px:0}}>
										
									</Box>}
								</TableCell> 
								{(block.likert_labels?.split('|') || likert).map((label, j) => {
									return !isMobile && <TableCell width="10%" key={j} align="center" sx={{verticalAlign:"bottom"}} >{label}</TableCell>;
								})}
							</TableRow>

						</TableHead>}
						<TableBody>
						
							{block.questions.map((question, i) => {
								
								return <TableRow key={i} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] ? 'invalid-answer' : ''} sx={{backgroundColor: i % 2 ?'#f6f6f6' : ''}}>
									<TableCell>
										<Typography sx={{pl:1}} fontWeight="bold">{question.prompt}</Typography>
										{isMobile && <Box > 
										
											{(block.likert_labels?.split('|') || likert).map((label, j) => {
											return <Box key={j} sx={{display:"flex", alignItems:"center", justifyContent:"flex-start", py:0}}>
												<FormControlLabel control={<Radio
													checked={answers.answers[block.block_id][question.answer_id] == j+1}
													onChange={recordAnswer}
													value={j+1}
													name={question.answer_id}
													sx={{py:0.5, pl:6, pr:1}}
												/>} label={label} />
												
											</Box>;
										})}</Box>}
									</TableCell>
									
									{!isMobile && (block.likert_labels?.split('|') || likert).map((label, j) => {
										
										return <TableCell key={j} align="center">
											<Radio
												checked={answers.answers[block.block_id][question.answer_id] == j+1}
												onChange={recordAnswer}
												value={j+1}
												name={question.answer_id}
											/>
										</TableCell>;
									})}	
									
								</TableRow>
							})}
						</TableBody>
					</Table>
				</Box>}
				
				{block.type != "likert" && <Stack direction="column" spacing={1}>
					{block.questions.map((question, j) => {
						return <Box py={1.5} mt="0 !important" key={question.id}>
							{question.type=="multiline" && <Box sx={{px:2}} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								
								{question.prompt && <Typography>{question.prompt}</Typography>}
								<TextField multiline error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"}  rows={4} sx={{width: "100%", mt:1}} name={question.answer_id} value={answers.answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
							</Box>}

							{question.type == "date" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								<Box sx={{px:2}} >
										{question.prompt && <Typography className="prompt">{question.prompt}</Typography>}
										<TextField size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} type="date" sx={{width: 180, mt:1}} name={question.answer_id} value={answers.answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
									</Box>
							</Stack>}

							{question.type == "text" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								<Box sx={{px:2}} >
										{question.prompt && <Typography className="prompt">{question.prompt}</Typography>}
										<TextField size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} sx={{width: "100%", mt:1}} name={question.answer_id} value={answers.answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
									</Box>
							</Stack>}

							{question.type == "number" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								<Box sx={{px:2}} >
										{question.prompt && <Typography className="prompt">{question.prompt}</Typography>}
										<TextField size="small" error={invalid[block.block_id] && invalid[block.block_id][question.answer_id]} helperText={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && "Required"} inputProps={{"type": "number"}} sx={{width: 90, mt:1}} name={question.answer_id} value={answers.answers[block.block_id][question.answer_id]} onChange={recordAnswer}></TextField>
									</Box>
							</Stack>}

							{question.type == "radio" && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								{question.prompt &&<Box sx={{px:2}} >
									 <Typography className="prompt">{question.prompt}</Typography>
								</Box>}

								<Box sx={{px:2, pl:4, mt: '0 !important'}} mt={0} >
									<Stack direction="column" className="radio">
										{question.choices.map((choice, k) => {
											return <FormControlLabel key={k} sx={{mt:1}} control={<Radio
												checked={answers.answers[block.block_id][question.answer_id] == choice.code}
												onChange={recordAnswer}
												value={choice.code}
												name={question.answer_id}
												sx={{py:0.5, pr:1}}
											/>} label={choice.label} />
										})}
										{!!question.allow_other && <Stack direction="row" sx={{mt:1}} >
											<FormControlLabel  control={<Radio
													checked={answers.answers[block.block_id][question.answer_id] == "OTHER"}
													onChange={recordAnswer}
													value="OTHER"
													name={question.answer_id}
													sx={{py:0.5, pr:1}}
												/>} label="Other" />
												{answers.answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
													<TextField size="small" className="other-text" sx={{mt: 1, width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={answers.answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
												}
											</Stack>}
										
									</Stack>
								</Box>
							</Stack>}

							{(question.type == "yes_no" || question.type == "yes-no") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								<Box sx={{px:2}} >
									{question.prompt && <Typography className="prompt">{question.prompt}</Typography>}
									<Stack direction="row" className="yes-no">
										<FormControlLabel control={<Radio
												checked={answers.answers[block.block_id][question.answer_id] == "No"}
												onChange={recordAnswer}
												value="No"
												name={question.answer_id}
												sx={{py:0.5, pr:1}}
											/>} label="No" />
										<FormControlLabel control={<Radio
												checked={answers.answers[block.block_id][question.answer_id] == "Yes"}
												onChange={recordAnswer}
												value="Yes"
												name={question.answer_id}
												sx={{py:0.5, pr:1}}
											/>} label="Yes" />
									</Stack>
								</Box>
							</Stack>}

							{(question.type == "multi-select") && <Stack direction="column" spacing={2} key={j} className={invalid[block.block_id] && invalid[block.block_id][question.answer_id] && 'invalid-answer'}>
								<Box sx={{px:2}} >
									{question.prompt && <Typography className="prompt">{question.prompt}</Typography>}
									<Box sx={{px:2, pl:2}} >
										<Stack direction="column" className="checkbox">
											{question.choices.map((choice, k) => {
												return <FormControlLabel sx={{mt:1}} key={k} control={<Checkbox
													checked={answers.answers[block.block_id][question.answer_id]?.split('|').includes(choice.code)}
													onChange={recordAnswer}
													name={question.answer_id}
													sx={{py:0.5, pr:1}}
													inputProps={{value: choice.code}}
												/>} label={choice.label} />
											})}
											{!!question.allow_other && <Stack sx={{mt:1}} direction="row">
												<FormControlLabel control={<Checkbox
													checked={answers.answers[block.block_id][question.answer_id]?.split('|').includes("OTHER")}
													onChange={recordAnswer}
													inputProps={{value: "OTHER"}}
													name={question.answer_id}
													sx={{py:0.5, pr:1}}
												/>} label="Other" />
												{answers.answers[block.block_id][question.answer_id]?.split('|').includes("OTHER") &&
													<TextField size="small" className="other-text" placeholder="Please describe..." sx={{width: 90, mt:1}} name={`${question.answer_id}_OTHER`} value={answers.answers[block.block_id][question.answer_id+'_OTHER']} onChange={recordAnswer}></TextField>
												}
											</Stack>}
										</Stack>
									</Box>
								</Box>
							</Stack>}

							
						</Box>;
					})}
				</Stack>}

				
				
			</Box>
		})}

		
	</Stack> : <></>;
}
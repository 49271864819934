import { HelpOutline } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import React from 'react';
import { stat } from "fs";

export default function AgencyAvatar(props) {

	const {agency_id, rank_id, size, sx, ...other} = props;
		
	var agencies = useSelector((state: React.ComponentState ) => state.data.agencies);
	var ranks = useSelector((state : React.ComponentState) => state.data.ranks);
	var rank_agency = null;

	if (rank_id && !agency_id) {
		var rank = ranks.find((r)=>r.id == rank_id);
		if (rank) {
			rank_agency = rank.agency_id;
		}
	}
	var src : string | null = null;
	var agency = agencies.find((a)=>a.id == (agency_id || rank_agency));
	

	if ( agency) {
		src = "/images/agency/"+agency.abbr+".png";
	} else {
		src = "/images/agency/unknown.png";
	}

	var style={...sx};
	if (size) {
		style.height = size;
		style.width = size;
	}

	return <Avatar {...other} alt={agency?.name} src={src} sx={style} ><HelpOutline /></Avatar>
}
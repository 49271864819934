
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Link, Button, Stack, TextField, Select, Menu, MenuItem, FormControl, FormControlLabel, Input, InputLabel, Checkbox, Box, Alert, Divider, Slider, Icon, Tabs, Tab, Fab, AlertTitle, Collapse, IconButton, Backdrop } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, Form, set, useForm } from 'react-hook-form';
import jax from '../helper/jax';
import { useSelector } from 'react-redux';
import DonorIndicators from './DonorIndicators';
import { Add, AirlineSeatFlatAngled, ArrowBack, ArrowCircleDown, ArrowCircleUp, AssignmentLate, AssignmentTurnedIn, CheckCircleOutline, Close, ContentPasteSearch, KeyboardReturnRounded, MilitaryTech, Restore, Speed, Update, Vaccines } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { asDate } from '../helper/util';

import FeedbackButton from './FeedbackButton';
import { actions, can } from '../features/access';
import Can from './Can';


export const FactorSlider = (props) => {

	const {title, value, minLabel, maxLabel, highColor, lowColor, onChange, icon, disabled} = props;

	const color = value < 0 ? (lowColor || "error") : value > 0 ? (highColor || "success") : "disabled";

	const theme = useTheme();
	return <Stack direction="row" alignItems="center" spacing={3}>
		{icon && <Box color="#999">
			<Icon color={color}>{icon}</Icon>
		</Box>}
		<Box flex={1}>
			{title && <Box className="sub-title">{title}</Box>}
			<Slider size="small" sx={{paddingTop:1, paddingBottom: 0.25}} min={-1} max={1} valueLabelDisplay="off" value={value} disabled={disabled} color={color} valueLabelFormat={(x)=> x<0 ? minLabel : (x>0 ? maxLabel : "Standard")} onChange={onChange} />
			<Stack direction="row" justifyContent="space-between">
				<Box className="sub-title xxs" sx={{color: theme.palette[color].main}}>{minLabel}</Box>
				<Box className="sub-title xxs" sx={{color: theme.palette[color].main}}>{maxLabel}</Box>
			</Stack>
		</Box>
	</Stack>;
}

function Screenings(props) {
	const {donor_id, unit_id, isActive, showAdd, onToggleAddScreening} = props;

	const [rendered, setRendered] = useState(false);
	const [screenings, setScreenings] = useState([]);
	const theme = useTheme();
	

	useEffect(()=>{
		if (isActive && !rendered) {
			setRendered(true);
			jax.get(`/app/admin/donors/${unit_id}/${donor_id}/screenings`).then((res)=>{
				setScreenings(res.screenings);
			});
		}
	}, [isActive]);

	const {control, register, watch, reset, setValue, handleSubmit, formState: { errors }} = useForm({
		defaultValues: {
			donor_no: "",
			blood_type: "",
			low_titer: false,
			collection_date: ""
		}
	});

	async function add(data) {
		var result = await jax.put(`/app/admin/donors/${unit_id}/${donor_id}/screenings`, data);
		setScreenings([result.screening, ...screenings]);
		onToggleAddScreening(false);
	}

	var blood_type = watch('blood_type');

	return rendered && <>
		{!showAdd && screenings != null && <Can action={actions.RECORD_UNIT_DONOR_SCREENING} id={unit_id}><Fab color="info" size="medium" variant="extended" sx={{position: 'absolute', top:"auto", bottom: 20, left: '50%', transform: "translateX(-50%)"}} onClick={()=>onToggleAddScreening(true)}><Add mr={1}/> Add Screening Data</Fab></Can>}
		{/* NEW SCREENING */}
		<Box sx={{position: 'absolute', zIndex: 2, bottom:0, left:0, right: 0}}>
				<Collapse in={showAdd}>
					<Divider/>
					<Alert square icon={false} color="info"  sx={{".MuiAlert-message": {flex: 1}}} action={
						<IconButton
							color="inherit"
							size="small"
							sx={{position:"absolute", top: "8px", right: "16px"}}
							onClick={() => {
								onToggleAddScreening(false);
								reset();
							}}>
							<Close fontSize="inherit" />
						</IconButton>
					}>
						
						Add new screening data for this donor.  This data will require verification from another party.

						<form onSubmit={handleSubmit(add)} noValidate>
							<Stack pt={2} direction="row" flex={1} spacing={2} alignItems="center">
								
								<Box >
									<Controller	control={control} name="donor_no" rules={{required: 'Required'}}  render={({field})=><TextField size="small" error={errors?.donor_no} {...field} label="Donor #" InputLabelProps={{shrink:true}} variant="outlined" fullWidth/>}/>
								</Box>

								<Box flex={0} width={150}>
									<Controller	control={control} name="collection_date" rules={{required: 'Required'}} render={({field})=><TextField type="date" error={errors?.collection_date} {...field} size="small" label="Collection Date" InputLabelProps={{shrink:true}} variant="outlined" fullWidth/>}/>
								</Box>
								
								<Box flex={1} minWidth={125}>
									<FormControl size="small" fullWidth >
										<InputLabel size="small" id="bt-label" variant="outlined" fullWidth >Blood Type</InputLabel>
										<Controller	control={control} name="blood_type" rules={{required: 'Required'}} render={({field})=>
											<Select  {...field} size="small"  labelId='bt-label' label="Blood Type" error={!!errors.blood_type}>
												<MenuItem value="O POS">O POS</MenuItem>
												<MenuItem value="O NEG">O NEG</MenuItem>
												<MenuItem value="A POS">A POS</MenuItem>
												<MenuItem value="A NEG">A NEG</MenuItem>
												<MenuItem value="B POS">B POS</MenuItem>
												<MenuItem value="B NEG">B NEG</MenuItem>
												<MenuItem value="AB POS">AB POS</MenuItem>
												<MenuItem value="AB NEG">AB NEG</MenuItem>
											</Select>
										}/>
									</FormControl>
								</Box>
								<Box> 
									<FormControlLabel fullWidth size="small" label="Low Titer" sx={{whiteSpace:"nowrap"}} disabled={!blood_type?.startsWith('O')} control={<Controller name="low_titer" control={control} render={({field:props})=>(
										<Checkbox
											checkedValue={true}
											color="primary"
											label="Low Titer"
											
											disabled={!blood_type?.startsWith('O')}
											{...props}
											
											sx={{px:0.5, py:0}}/>)}
											/>}
									/>
								</Box>
								<Box flex={0}>
									<FormControlLabel fullWidth  size="small" label="OK to donate" sx={{whiteSpace:"nowrap"}} control={<Controller name="okay_to_donate" control={control} render={({field:props})=>(
										<Box ><Checkbox
											checkedValue={true}
											
											color="primary"
											label="OK to donate"
											{...props}
											
											sx={{px:0.5, py:0}}/>
											
											</Box>)}
											/>}
											
									/>
								</Box>
								
								<Button color='info' variant='contained' sx={{whiteSpace:"nowrap"}} type="submit">Record</Button>
							</Stack>
						</form>
					</Alert>
					
				</Collapse>
			</Box>
		<Box sx={{position:'absolute', top:0, left: 0, right: 0, bottom: 0, overflowY:'scroll'}}>
		
			
			
			{screenings?.map((s,i)=>{
				return <React.Fragment key={i}>
					<Box py={2} >
						<Stack direction="row" flex={1} px={2} spacing={2} alignItems="center" fontSize="0.9rem" color={s.invalidated===0 ? theme.palette.success.main : (s.invalidated==1 ? theme.palette.error.main : theme.palette.grey.main)}>
							<Box sx={{fontSize:"32px", lineHeight: 0.5}}>
								{s.invalidated == 1 ? <AssignmentLate color="error" fontSize="inherit"></AssignmentLate>: (s.invalidated === 0 ? <AssignmentTurnedIn fontSize="inherit" color="success"/> : <ContentPasteSearch fontSize="inherit" color="disabled"/> )}</Box>
							<Stack flex={1}>
								<Box className={`sub-title xs ${s.invalidated===0 ? "primary" : (s.invalidated===1 ? "error" :"disabled")}`}>Collected</Box>
								<Box sx={{}}>{asDate(s.collection_date)}</Box>
							</Stack>

							<Stack flex={1}>
								<Box className={`sub-title xs ${s.invalidated===0 ? "primary" : (s.invalidated===1 ? "error" :"disabled")}`}>Donor DIN</Box>
								<Box sx={{}}>{s.donor_no}</Box>
							</Stack>

							<Stack flex={1}>
								<Box className={`sub-title xs ${s.invalidated===0 ? "primary" : (s.invalidated===1 ? "error" :"disabled")}`}>Result</Box>
								<Stack direction="row" alignItems="center" spacing={0.5} >
									<Box>{s.blood_type}</Box>
									{s.low_titer ? <ArrowCircleDown sx={{fontSize: "18px"}}/> : <ArrowCircleUp sx={{fontSize: "18px"}}/>}
								</Stack>
							</Stack>
						
							<Stack flex={1}>
								<Box className={`sub-title xs ${s.invalidated===0 ? "primary" : (s.invalidated===1 ? "error" :"disabled")}`}>Recorded By</Box>
								<Box>{s.recorded_by_title}</Box>
							</Stack>
						
							<Stack flex={1}>
								<Box className={`sub-title xs ${s.invalidated===0 ? "primary" : (s.invalidated===1 ? "error" :"disabled")}`}>Reviewed By</Box>
								<Box>{s.verified_by_title || '---'}</Box>
							</Stack>
							
						</Stack>

						{s.invalidated === 1 && s.comments && <Stack direction="row" p={1} pt={2} fontSize="0.9rem" pb={0} spacing={1} pl={8}>
							<Box><b>Reviewer comments:</b></Box><Box flex={1}><i>{s.comments}</i></Box>
						</Stack>}
						
					</Box>	
					<Divider/>
				</React.Fragment>
			})}
			
		</Box>
		<Backdrop open={showAdd} sx={{zIndex: 1, position:'absolute', backgroundColor: '#ffffff99 !important' }} onClick={()=>onToggleAddScreening(false)} />
	</>;
}

export default function AddDonors(props) {
	const {open, unit, onClose, donor, onAddDonor, onUpdateDonor} = props;

	const theme = useTheme();
	const [lastDonor, setLastDonor] = useState(null);
	const [lastResult, setLastResult] = useState(null);
	const [agencyRanks, setAgencyRanks] = useState([]);
	const [tab, setTab] = useState(0);
	const [existingDonor, setExistingDonor] = useState(null);
	const [saveError, setSaveError] = useState(null);
	const [isNotOkay, setIsNotOkay] = useState(false);
	const {affiliations, agencies,  ranks} = useSelector(state=>state.data);
	const [showAddScreening, setShowAddScreening] = useState(false);
	const {user} = useSelector(state=>state.data);

	const canEdit = can(user, actions.MODIFY_UNIT_DONOR, unit.id);

	const {control, register, watch, reset, setValue, handleSubmit, formState: { errors }} = useForm({
		defaultValues: {
			affiliation_id: donor?.affiliation_id || "1",
			agency_id: unit.agency_id,
			rank_id: "",
			unit_id: unit.id,
			donor_no: "",
			mil_id: "",
			first_name: "",
			last_name: ""	,
			blood_type: "",
			low_titer: false,
			venous_score: 0,
			fill_score: 0,
			vasovagal_score: 0,
			tactical_score: 0,
			weight_score: 0,
			okay_to_donate: false,
			reassign: false,
			collection_date: new Date().toISOString().split('T')[0],
			acknolwedged: false //used if the donor is not okay to donate
		}
	});

	const donor_no = watch('donor_no');
	const agency_id = watch("agency_id");
	const blood_type = watch("blood_type");
	
	const fieldValues = watch();

	async function acknolwedge(data) {
		if (!data.okay_to_donate && !data.acknolwedged) {
			setIsNotOkay(true);
			return false;
		}
		return true;
	}

	async function add(data) {
		try {
			
			if (!acknolwedge(data)){
				return;
			}

			//Hack for undefined value (MUI issue with Checkbox)
			data.low_titer = data.low_titer || false;
			if (!data.okay_to_donate && !data.acknolwedged) {
				setIsNotOkay(true);
				return;
			}
			setIsNotOkay(false);

			var result = await jax.put(`/app/admin/donors/${unit.id}`, data);
			
			!!onAddDonor && onAddDonor(data);
			setExistingDonor(null);
			setLastDonor(data.donor_no);
			setLastResult(result.new_donor);
			reset();
			
		} catch (error) {
			if (error.code == 409) {
				var data = await error.message.json();
				setExistingDonor(data);
			}
		}
	}

	async function update(data) {
		//Hack for undefined value (MUI issue with Checkbox)
		data.low_titer = data.low_titer || false;
		try {
			var result = await jax.post(`/app/admin/donors/${unit.id}/${data.id}`, data);
			!!onUpdateDonor && onUpdateDonor(data);
			onClose();
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(()=>{
		if (!open) {
			setLastDonor(null);
			setLastResult(null);
			setTab(0);
		}

		if (open && donor) {
			var agency = ranks.find(x=>x.id == donor.rank_id)?.agency_id;
			var aff = agencies.find(x=>x.id == agency)?.affiliation_id;
			reset({
				affiliation_id: aff || "1",
				agency_id: agency || unit.agency_id,
				rank_id: donor?.rank_id || unit.rank_id,
				unit_id: donor?.unit_id || unit.id,
				id: donor?.id,
				collection_date: asDate(donor?.collection_date, 'YYYY-MM-DD'),
				donor_no: donor?.donor_no || "",
				mil_id: donor?.mil_id || "",
				first_name: donor?.first_name || "",
				last_name: donor?.last_name || ""	,
				blood_type: donor?.blood_type || "",
				low_titer: donor?.low_titer || false,
				venous_score: donor?.venous_score || 0,
				okay_to_donate: donor?.okay_to_donate || false,
				fill_score: donor?.fill_score || 0,
				vasovagal_score: donor?.vasovagal_score || 0,
				tactical_score: donor?.tactical_score,
				weight_score: donor?.weight_score,
				acknolwedged: false
			})
		} else if (open) {
			reset({
				affiliation_id: donor?.affiliation_id || "1",
				agency_id: unit.agency_id,
				rank_id: ranks.find(x=>x.agency_id)?.id,
				unit_id: unit.id,
				donor_no: "",
				mil_id: "",
				rank_id: "",
				first_name: "",
				last_name: ""	,
				blood_type: "",
				low_titer: false,
				okay_to_donate: false,
				venous_score: 0,
				fill_score: 0,
				vasovagal_score: 0,
				tactical_score: 0,
				weight_score: 0,
				collection_date: new Date().toISOString().split('T')[0],
				acknolwedged: false

			});
		}
	}, [open]);

	useEffect(()=>{
		var r = ranks.filter(x=>x.agency_id == agency_id);
		setAgencyRanks(r);
	}, [agency_id]);

	useEffect(()=>{
		if (!blood_type?.startsWith('O')) {
			setValue('low_titer', false);
		}
	}, [blood_type]);

	useEffect(()=>{
		if (Object.keys(errors).length) {
			console.log(errors);
		}
	}, [errors])

	return <Dialog open={!!open} onClose={onClose} maxWidth="md" PaperProps={{sx:{overflowY: "visible"}}} fullWidth >
		{open && <>
			<Box pt={2} pb={!!donor ? 0 : 2}>
				<Stack direction="row" px={2} pb={!!donor ? 1 : 0} justifyContent="space-between">
					<h2 flex={1}>{donor ? `${donor.first_name} ${donor.last_name}` : "Add Donor(s)"}</h2>	
					{donor && <Can action={actions.RECORD_UNIT_DONOR_SCREENING} id={unit.id}><Alert severity="info" sx={{fontSize: "0.9rem", padding: "0px 12px"}}><Link href="#" onClick={(e)=>{e.preventDefault(); setTab(1); setShowAddScreening(true);}} color="inherit">Click here</Link> to record updated screening data.</Alert></Can>}
				</Stack>
				{!!donor && <Tabs value={tab} onChange={(e,v) => setTab(v)}>
					<Tab label="Donor Profile"></Tab>
					<Tab label="Screening History"></Tab>
					<Tab label="Donation History"></Tab>
				</Tabs>}
			</Box>
			{/* <DialogContent  sx={{padding:0}}> */}
				
				<Box minHeight={420} position="relative">
					<Divider/>
					<Box display={tab == 0 ? "block" : "none"} p={0}>
						<Backdrop open={existingDonor} sx={{zIndex: 5, position:'absolute', backgroundColor: '#ffffffcc !important' }}>
							<Box p={2} >
								{existingDonor && <Alert severity="warning" elevation={2} >
									<AlertTitle>Existing Donor Found</AlertTitle>
									<Stack spacing={2} flex={1} sx={{fontWeight:"normal"}}>
										<Box>A donor with DOD/EDPI# <b>{existingDonor.mil_id}</b> is currently registered to <b>{existingDonor.unit_name}</b>.  In order to update this donor's screening data, they must first be reassigned to this command.  </Box>
										<Box>The Donor Manager(s) at <b>{existingDonor.unit_name}</b> will be notified of this action if you choose to proceed.</Box>
										<Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" flex={1}>
											<Button size="small"  startIcon={<ArrowBack/>} onClick={()=>{setExistingDonor(null);}}>Go Back</Button>
											<Button size="small" variant="contained" startIcon={<AssignmentTurnedIn/>} onClick={()=>{add({...fieldValues, reassign: true})}}>Reassign Donor and Add Screening Data</Button>
										</Stack>
									</Stack>
								</Alert>}
							</Box>
						</Backdrop>
						{!donor && <Box p={2} >
							The following donor will be attached to <b>{unit.name}</b>.  If the donor is already known to this command, their screening history will be updated.
						</Box>}
						{!donor && <Divider/>}
						<form onSubmit={handleSubmit(donor ? update : add)} noValidate>
							<Stack spacing={2} >
								<Stack direction={{xs:"column", lg:"row"}} spacing={0} >
									
									{/* MIL PROFILE */}
									<Stack  spacing={2} flex={1} p={2}>
										<h4 style={{marginBottom: 8}}>Donor Profile</h4>

										<Controller	control={control} name="mil_id" rules={{required: 'Required'}} render={({field})=><TextField {...field} autoFocus disabled={!!donor} size="small" label="DODID / EDIPI #" variant="outlined" fullWidth error={!!errors.mil_id}/>}/>
										
										<Controller	control={control} name="first_name" rules={{required: 'Required'}}  render={({field})=><TextField required disabled={!!donor} {...field} error={!!errors.first_name} size="small" label="First Name" variant="outlined" fullWidth/>}/>
										<Controller	control={control} name="last_name" rules={{required: 'Required'}} render={({field})=><TextField {...field} disabled={!!donor} error={!!errors.last_name}  size="small" label="Last Name" variant="outlined" fullWidth/>}/>

										<FormControl size="small" fullWidth>
											<InputLabel id="affiliation-label">Affiliation</InputLabel>
											<Controller	control={control} name="affiliation_id" rules={{required: 'Required'}} render={({field})=>(
												<Select {...field} size="small" fullWidth label="Affiliation" defaultValue="1" variant="outlined" disabled={!canEdit} error={!!errors.affiliation_id}>
													{affiliations.map((a,i)=><MenuItem key={i} value={a.id}>{a.name}</MenuItem>)}
												</Select>
											)}/>
										</FormControl>
										
										<FormControl size="small" fullWidth>
											<InputLabel id="agency-label">Agency</InputLabel>
											<Controller	control={control} name="agency_id" rules={{required: 'Required'}} render={({field})=>(
												<Select {...field} size="small" fullWidth label="Agency" variant="outlined" disabled={!canEdit} error={!!errors.agency_id}>
													{agencies.map((a,i)=><MenuItem key={i} value={a.id}>{a.name}</MenuItem>)}
												</Select>
											)}/>
										</FormControl>

										<FormControl size="small" fullWidth>
											<InputLabel id="command-label">Rank</InputLabel>
											<Controller	control={control} name="rank_id" required rules={{required: 'Required'}} render={({field})=>(
												<Select {...field} size="small" fullWidth label="Rank" variant="outlined" disabled={!agency_id || !canEdit} error={!!errors.rank_id}>
													<MenuItem value="">&nbsp;</MenuItem>
													{agencyRanks.map((a,i)=><MenuItem key={i} value={a.id}>{a.name}</MenuItem>)}
												</Select>
											)}/>
										</FormControl>
										
									</Stack>

									<Divider orientation='vertical' flexItem/>

									{/* DONOR INFO */}
									<Stack spacing={2} flex={1} p={2}>
										<h4 style={{marginBottom: 8}}>{donor ? "Last ": ""}Screening Results</h4>
										
										<Controller	control={control} name="collection_date" rules={{required: 'Required'}} render={({field})=><TextField type="date" disabled={!!donor} {...field} size="small" InputLabelProps={{ shrink: true }} label="Collection Date" variant="outlined" fullWidth error={!!errors.collection_date}/>}/>

										{<Controller control={control} rules={{required: 'Required'}} name="donor_no" render={({field})=><TextField  {...field} disabled={!!donor} size="small" label="Donor ID No. (DIN)" variant="outlined" fullWidth helperText="Lab supplied donor identifier" error={!!errors?.donor_no}/>}/>}

										<Stack direction="row" spacing={2} alignItems="center">
											<Box flex={1}>
												<FormControl size="small" fullWidth >
													<InputLabel size="small" id="bt-label" variant="outlined" fullWidth >Blood Type</InputLabel>
													<Controller	control={control} name="blood_type" rules={{required: 'Required'}} render={({field})=>
														<Select {...field} size="small"  labelId='bt-label' disabled={!!donor} label="Blood Type" error={!!errors.blood_type}>
															<MenuItem value="O POS">O POS</MenuItem>
															<MenuItem value="O NEG">O NEG</MenuItem>
															<MenuItem value="A POS">A POS</MenuItem>
															<MenuItem value="A NEG">A NEG</MenuItem>
															<MenuItem value="B POS">B POS</MenuItem>
															<MenuItem value="B NEG">B NEG</MenuItem>
															<MenuItem value="AB POS">AB POS</MenuItem>
															<MenuItem value="AB NEG">AB NEG</MenuItem>
														</Select>
													}/>
												</FormControl>
											</Box>
											<Box > 
												<FormControlLabel  size="small" label="Low Titer" disabled={!blood_type?.startsWith('O') || !!donor} control={<Controller name="low_titer" control={control} render={({field:props})=>(
													<Box pl={1}>
														<Checkbox
															color="primary"
															label="Low Titer"
															disabled={!blood_type?.startsWith('O') || !!donor}
															{...props}
															checked={!!props.value}
															sx={{px:1, py:0}}/>
													</Box>)}
												/>}/>
											</Box>
										</Stack>
										
										
										<Box > 
											<FormControlLabel  size="small" label="OK to donate" disabled={!!donor} control={<Controller name="okay_to_donate" control={control} render={({field:props})=>(
												<Checkbox
													
													color="primary"
													label="OK to donate"
													disabled={!!donor}
													{...props}
													checked={!!props.value}
            										
													sx={{px:1, py:0}}/>
												
												)}
													/>}
											/>
											<Box className="sub-title xs" pl={4.75} sx={{opacity: donor ? 0.5 : 1}}>
													<i>This individual meets all ASBP laboratory criteria</i>
												</Box>
										</Box>

										{!donor && <Alert severity="info" sx={{fontSize: "0.9rem"}}>Lab results are preliminary until verified by a third party.</Alert>}
										{donor && !donor.verified_by && <Alert severity="warning" sx={{fontSize: "0.9rem"}}>These screening results have yet to be verified.</Alert>}
										
									</Stack>

									<Divider orientation='vertical' flexItem/>

									{/* SCORE FACTORS */}
									<Box flex={1} p={2}>
										<h4 style={{marginBottom: 12}}>Donor Factors</h4>
										<Stack spacing={3} pr={2} mt={2} pt={0.5} pb={2}>
											<FactorSlider disabled={!canEdit} title="Tactical Impact" value={fieldValues["tactical_score"]} minLabel="Key Leader" maxLabel="Low Impact" highColor="success" lowColor="error" icon={<MilitaryTech/>} onChange={(e,v)=>setValue('tactical_score',v)}/>
											<FactorSlider disabled={!canEdit} title="Vasovagal Response" value={fieldValues["vasovagal_score"]} minLabel="Poor" maxLabel="Resilient" highColor="success" lowColor="error" icon={<AirlineSeatFlatAngled/>} onChange={(e,v)=>setValue('vasovagal_score',v)}/>
											<FactorSlider disabled={!canEdit} title="Fill Speed" value={fieldValues["fill_score"]} minLabel="Slow" maxLabel="Fast" highColor="success" lowColor="error" icon={<Update/>} onChange={(e,v)=>setValue('fill_score',v)}/>
											<FactorSlider disabled={!canEdit} title="Venous Access" value={fieldValues["venous_score"]} minLabel="Difficult" maxLabel="Easy" highColor="success" lowColor="error" icon={<Vaccines/>} onChange={(e,v)=>setValue('venous_score',v)}/>
											<FactorSlider disabled={!canEdit} title="Body weight" value={fieldValues["weight_score"]} minLabel="< 130lbs (or Female)" maxLabel="> 220lbs" highColor="success" lowColor="error" icon={<Speed/>} onChange={(e,v)=>setValue('weight_score',v)}/>
										</Stack>
									</Box>
								</Stack>
								
								{lastResult !== null && <Alert sx={{marginTop:'0 !important'}} severity="success">Donor <b>{lastDonor}</b> {lastResult == 1 ? "was added to this command" : "already existed and was updated"}.</Alert>}
							</Stack>
							
							<Divider/>
							<Can action={actions.MODIFY_UNIT_DONOR} id={unit.id}><Stack direction="row" justifyContent="center" spacing={2} p={2}>
								{!donor && <FeedbackButton variant="contained" type='submit' severity="info" onHideError={()=>setIsNotOkay(false)} error={isNotOkay && <Box padding={0} maxWidth={300}>
									<Box pb={1}>
										Donors deemed "Not OK to donate" are effectively hidden until future acceptable results are recorded.  Do you want to continue?
									</Box>
									<Box textAlign="center">
										<Button size="small" variant="contained" color="info" type="submit" onClick={()=>{ add({...fieldValues, acknolwedged: true})}}>Yes, Continue</Button>
									</Box>
								</Box>}>Register Donor</FeedbackButton>}
								{donor && <Button variant="contained" type='submit'>Save Changes</Button>}
							</Stack></Can>
						</form>	
					</Box>

					{/* SCREENING */}
					<Box display={tab == 1 ? "block" : "none"} position="static"  >
						<Screenings isActive={tab == 1} unit_id={unit.id} donor_id={donor?.id} showAdd={showAddScreening} onToggleAddScreening={setShowAddScreening}/>
					</Box>

					{/* DONATIONS */}
					<Box display={tab == 2 ? "block" : "none"} >
						Donation
					</Box>
				</Box>								
				
			{/* </DialogContent> */}
			{/* {!donor && <DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				{!donor && <Button variant="contained" type='submit'>Register Donor</Button>}
			</DialogActions>} */}
		</>}
	</Dialog>
}
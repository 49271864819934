import { Box, Paper, Rating, Stack, Typography, alpha } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Area, ComposedChart, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { tagColors, unique } from '../../helper/util';
import Courses from '../../training/Courses';
import { Star } from '@mui/icons-material';

interface Props {
	data: Array<any>,
	height?: number
}

const CustomTooltip = (props) => {
	const {payload, label, active} = props;
	if (active && payload && payload.length) {
		var course_name = payload[0].payload.course_name;

		var data = payload[0].payload;
		return (
			
			<Paper elevation={3} sx={{p:1, pointerEvents:"none"}}>
				<Box className='sub-title xs'>{moment(label).format('yyyy-MM-DD')}</Box>
				<Box>
					<Typography sx={{fontSize: '1rem', fontWeight:"bold"}}>{course_name}</Typography>
				</Box>
				
				<Stack sx={{fontSize:'0.8rem', textTransform:'capitalize'}} mt={1} spacing={0.5}>
				<form>
					<Stack>
						{payload.map((p,i)=>{
							return <Stack direction="row" alignItems="center" spacing={1}>
								<Box >
									<Rating size="small" name={`rating_${i}`} icon={<Star fontSize="inherit" sx={{color: i==0 ? "#000000" : p.color}}/>} value={p.value.toFixed(2)} defaultValue={p.value.toFixed(2)} precision={0.5} readOnly/>
								</Box>
								<Box key={i} flex={1} color={i==0 ? "black" : p.color}><b>{p.name}</b> </Box>
							</Stack>
						})}
					</Stack>
				</form>
				
				</Stack>
				
			</Paper>
		);
	}
  
	return null;
  };

const EvalHistory: React.FC<Props> = (props) => {
	// Implement your component logic here

	const [domain, setDomain] = React.useState<Array<number>>([moment().valueOf(),0]);
	const [data, setData] = React.useState<Array<any>>([]);
	const [series, setSeries] = React.useState<Array<any>>([]);
	const [colors, setColors] = React.useState<Array<string>>(tagColors.map(x=>x));
	const [focused, setFocused] = React.useState<number | null>(null);

	React.useEffect(() => {
		if (!props.data) {
			return;
		}
		var ids = unique(props.data,"course_id"); 
		var block_names = unique(props.data,"block_id");
		var blocks = block_names.map((block:any)=>{
			var first = props.data.find((item:any)=>item.block_id==block);
			return {
				label: first?.block,
				block_id: first?.block_id
			}
		});
		setSeries(blocks);
		var courses = ids.map((course:number)=>{
			var items = props.data.filter((item:any)=>item.course_id==course);
			var item = {
				course_id: course,
				course_name: items[0].course_name,
				total: 0,
				uDate:moment(items[0].start_date).valueOf(),
				avg: 0
			};
			items.map((block:any)=>{
				item[block.block_id] = block.rating;
				item.total += block.rating;
			});
			item.avg = item.total / items.length;
			return item;
		});

		var dmn = courses?.reduce((acc, item) => {
			if (item.uDate < acc.min) { 
				acc.min = item.uDate;
			}
			if (item.uDate > acc.max) {
				acc.max = item.uDate;
			}
			return acc;
		}, {min: domain[0], max: domain[1]});
		
		//dmn && setDomain([dmn.min, dmn.max]);
		setData(courses);

	}, [props.data]);

	function highlight(payload, index, e) {
		setFocused(index-1);
		var cols = tagColors.map((c,i)=>i==index-1 ? c : "#00000011");
		setColors(cols);
	}

	function unhighlight(payload, index, e) {
		if (focused == index-1) {
			setFocused(null);
		}
		
		setColors(tagColors.map(x=>x));
	}

	return (
		<Box p={2} position="relative" sx={{"& li.recharts-legend-item": {padding: "4px 8px", margin:"0 !important", fontSize:"0.9rem"}, "& .recharts-legend-wrapper": {paddingBottom: "8px"}}}>
			<ResponsiveContainer width="100%" height={props.height || 400} >
				<ComposedChart data={data} >
					<XAxis className='sub-title xs' interval="preserveStartEnd" id="y" tickCount={3} padding={{left: 30, right: 30}} dataKey="uDate"  type="number" domain={domain} tickFormatter={(v)=>moment(v).format("MM/DD/yyyy")} />
					<YAxis ticks={[1,2,3,4,5]} className='sub-title xs' domain={[0,5]} id='x' />
					<Legend verticalAlign='top' margin={{top:30, bottom:30, left: 0, right: 0}} onMouseEnter={highlight} onMouseLeave={unhighlight} />
					<Area type="monotone" dataKey="avg" name="Overall" stroke="#00000033" fill="#00000011"   strokeWidth={focused === -1 ? 3 :1}/>
					{series.map((item:any, i: number)=>{
						return <Line key={i} type="monotone" connectNulls strokeWidth={focused == i ? 3 : 1} dataKey={item.block_id} name={item.label} label={item.label} stroke={colors[i % colors.length]} />
					})}
					{data.map((item:any, i: number)=>{
						return <ReferenceLine key={i} x={item.uDate} stroke="#00000044" strokeWidth={1} strokeDasharray="3 3" />
					})}
					<Tooltip cursor={{fill:'#00000011'}} content={<CustomTooltip/>} wrapperStyle={{ pointerEvents: "auto"}} />
					
				</ComposedChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default EvalHistory;
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import jax from "../../helper/jax";
import { useSelector } from "react-redux";
import { useParams, Link as RouterLink } from "react-router-dom";
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Text, XAxis, YAxis, BarChart, Bar, Radar, ResponsiveContainer, Legend, Tooltip, CartesianGrid, Cell } from 'recharts';
import { Card, Box, Typography, Checkbox, FormControlLabel, Tabs, Tab, Divider, Stack, Paper, Pagination, CardHeader, LinearProgress, Alert, useMediaQuery, Button, AlertTitle, Breadcrumbs } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { asDate } from "../../helper/util";
import { useTheme } from "@emotion/react";
import { Chat, ExpandLess, ExpandMore } from "@mui/icons-material";
import Can from "../../components/Can";

import { resources, actions, can, roles, hasRole } from "../../features/access";
import theme from "../../theme";

const FullStar = () => <path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218" />;

const CustomYAxisTick = ({ x, y, payload, anchor, verticalAnchor, format }) => {
    if (payload && payload.value) {
      return (
        <Text
            fontSize={"12px"}
            width={120}
            x={x} 
            y={y+4} 
            textAnchor={anchor || "end"}
            verticalAnchor={anchor || "center"}
        >{format ? format(payload.value) : payload.value}</Text>
      );
    }
    return null;
};

const CustomRadarTick = ({ x, y, payload, format, angle }) => {
	
    if (payload && payload.value) {

      return (
        <Text
            fontSize={"12px"}
            width={120}
            x={x} 
            y={y+4} 
            textAnchor="left"
            verticalAnchor="center"
        >{format ? format(payload.value) : payload.value}</Text>
      );
    }
    return null;
};

const CustomRatingAxisTick = (props) => {
	const {x, y, payload, anchor, verticalAnchor, format, ratings} = props;
    if (payload && payload.value) {
		return (
			<Text
				fontSize={"12px"}
				width={80}
				x={x} 
				y={y} 
				textAnchor="middle"
				verticalAnchor="start"
			>{format ? format(ratings[payload.value-1]) : ratings[payload.value-1]}</Text>
		  );
     
		//stars
		//return <svg x={-(payload.value-1)*6}>{Array(payload.value).fill(0).map((p,i)=><svg key={i} x={x-6+(i*12)} y={y} width={12} height={12} viewBox="0 0 18 18"><FullStar/></svg>)}</svg>;
    }
    return null;
};

const CustomTooltip = (props) => {
	const {payload, label, active} = props;
	if (active && payload && payload.length) {
		var question = payload[0].payload.prompt;
	  return (
		<Paper elevation={3} sx={{p:1}}>
			<Box>
				<Typography sx={{fontSize: '1rem', fontWeight:"bold"}}>{label}</Typography>
			</Box>
			<i className='sub-title'>{question}</i>
			<Stack sx={{fontSize:'0.8rem', textTransform:'capitalize'}} mt={1} spacing={0.5}>
			{payload.map((p,i)=>{
				return <Box key={i}><b>{p.name}{p.name != "course" && " Avg."}:</b> {p.value.toFixed(2)}</Box>;
			})}
			</Stack>
			
		</Paper>
			  );
	}
  
	return null;
  };

const LikertChart = (props) => {
	const formatTooltip = (value, name, props) => {
		switch(name) {
			case "course":
				return [value.toFixed(2), "Course Average"];
			case "program":
				return [value.toFixed(2), "Program Benchmark"];
			case "global":
				return [value.toFixed(2), "Global Benchmark"];
		}
	}

	const isMobile = useMediaQuery(theme=>theme.breakpoints.down('md'));
	
	const {data, showGlobal, showProgram, dataKey, ratings, labelKey} = props;
	return <ResponsiveContainer width="100%" minHeight="100%">
		<BarChart data={data} barSize={10} barGap={1} layout="vertical">
			<CartesianGrid strokeDasharray="3 3" vertical={true} horizontal={false}  />
			<XAxis type="number" domain={[0,5]} tick={<CustomRatingAxisTick anchor="center" verticalAnchor="start" ratings={ratings || ["Poor", "Needs Attention", "Average", "Good", "Excellent"]}/>} ticks={[1,2,3,4,5]} padding={{right:isMobile ? "20" :"50"}}  />
			<YAxis type="category" dataKey={dataKey} tick={<CustomYAxisTick/>} width={120}   />
			
			<Tooltip content={<CustomTooltip/>}  cursor={{fill:'#00000011'}}/>
			
			
			<Bar dataKey="course" fill="#6c782e" spacing={0}>
			{
				data?.map((entry, index) => {
					var color = theme.palette.primary.main;
					if (entry.course < 2)
						color = theme.palette.error.main;
					else if (entry.course < 1)
						color = theme.palette.warning.main;
					return <Cell key={`cell-${index}`} fill={color} />
				})
			}
			</Bar>
			{showProgram && <Bar dataKey="program" fill={"#1769aa66"} barSize={4} />}
			{showGlobal && <Bar dataKey="global" fill="#b22a0066" barSize={4} />}
			
			
		</BarChart>
	</ResponsiveContainer>;
}

const Comments = (props) =>{
	const {comments, block} = props;
	const answers = comments.filter(c=>c?.answer?.trim()?.length > 0);
	const [hasMore, setHasMore] = useState(true);
	const [showMore, setShowMore] = useState(false);

	var wrapperRef = useRef(null);
	var innerRef = useRef(null);
	const theme = useTheme();

	useEffect(()=>{
		if (wrapperRef.current && innerRef.current) {
			var wrapper = wrapperRef.current;
			var inner = innerRef.current;
			var height = wrapper.clientHeight;
			var innerHeight = inner.clientHeight;
			setHasMore(innerHeight > height);
		}
	}, []);

	return <Stack alignItems="stretch"  >
		<Box px={4} pb={4}>
			<Card className={`eval-comments ${hasMore && 'has-more'} ${showMore && 'show-more'}`}  ref={wrapperRef} >
				<Box ref={innerRef} pb={showMore && 3}>
					<Stack alignItems="stretch" justifyContent="stretch" >
						<Box className="card-header" >
							<Typography variant="h6">{block.label}</Typography>
						</Box>
						<Box p={1} sx={{backgroundColor: theme.palette.primary.light}}>
								<span className="sub-title" style={{color: 'white'}}><b>Prompt:</b> <i>{block.prompt}</i></span>
						</Box>
						<Box p={2}>
							
							
							<Box p={2} >
								{answers.map((c,i)=>{
									return <><Stack direction="row" alignItems="flex-top" spacing={1}>
										<Box>
											<Chat sx={{color:"#777777"}}></Chat>
										</Box>
										<Box ><i>{c.answer}</i></Box>
										
									</Stack>
									{i < answers.length-1 && <Box my={2}><Divider ></Divider></Box>}
									</>
								})}
							</Box>
						</Box>
					</Stack>
				</Box>
				{hasMore && <Box p={1} textAlign="center" className="show-more-button">
					<Button variant="outlined" size="small" onClick={()=>setShowMore(!showMore)} startIcon={showMore ? <ExpandLess/> : <ExpandMore/>}>{showMore ? "Show Less" : "Show All"}</Button>
				</Box>}
			</Card>
			</Box>
		</Stack>
		
	;
}

export default function CourseReport(props) {
	const[ratings, setRatings] = useState([]);
	const [blocks, setBlocks] = useState([]);
	const [comments, setComments] = useState([]);
	const [section, setSection] = useState("summary");
	const [course, setCourse] = useState(null);
	const [evaluationsPending, setEvaluationsPending] = useState(0);
	const [isPMorAdmin, setIsPMorAdmin] = useState(false);
	const user = useSelector(state=>state.data.user);
	const params = useParams();
	const[showProgramBenchmarks, setShowProgramBenchmarks] = useState(true);
	const[showGlobalBenchmarks, setShowGlobalBenchmarks] = useState(true);

	const theme = useTheme();
	useEffect(()=>{
		jax.get(`/app/courses/${params.course_code}/report`).then((data)=>{
			setRatings(data.ratings);
			setBlocks(data.blocks);
			setComments(data.comments);
			setCourse(data.course);
			var admin = hasRole(user, roles.ADMIN) || (user.programs || []).map(p=>p.id).includes(data.course.program_id) ;
			setIsPMorAdmin(admin);
			setEvaluationsPending(data.course.enrollees-data.course.evaluated);
		});
	}, [props.courseCode]);

	return <Stack spacing={4}>
		{course && <Breadcrumbs separator="›">
			<RouterLink to="/">Home</RouterLink>
			{can(user,resources.PROGRAM, actions.MANAGE_COURSES) && <RouterLink to="/courses">Courses</RouterLink>}
			<RouterLink to={`/courses/${course.code}`}>{course.name}</RouterLink>
			<Typography>Course Report</Typography>
		</Breadcrumbs>}
		<Card elevation={6}>
		{evaluationsPending > 0 && <Grid xs={12}>
			<Alert sx={{borderRadius: '4px 4px 0 0', borderBottom: 1, borderColor: theme.palette.error.dark, padding: '12px'}} severity="error" ><AlertTitle>Please Note:</AlertTitle> <b>{evaluationsPending}</b> evaluation{evaluationsPending > 1 ? "s are" : " is"}  pending and not represented in this report.</Alert>
		</Grid>}
		<Grid xs={12}>
			<Stack alignItems={{xs:"flex-start", lg:"center"}} justifyContent="flex-end" spacing={{xs:0, lg:4}} direction={{xs:"column", lg: "row"}} p={2}>
				<FormControlLabel label="Show Program Benchmarks" control={<Checkbox sx={{color:"#1769aa44", '&.Mui-checked': {color: "#1769aa99"}}} className="tight" checked={showProgramBenchmarks} onChange={(e)=>{setShowProgramBenchmarks(e.target.checked)}}></Checkbox>}></FormControlLabel>
				<FormControlLabel label="Show Global Benchmarks" control={<Checkbox sx={{color:"#b22a0033", '&.Mui-checked': {color: "#b22a0099"}}} className="tight" checked={showGlobalBenchmarks} onChange={(e)=>{setShowGlobalBenchmarks(e.target.checked)}}></Checkbox>}></FormControlLabel>
			</Stack>
		</Grid>
		<Tabs value={section} onChange={(e,v)=>setSection(v)} variant="scrollable" scrollButtons={false}>
			<Tab label="Summary" value="summary"></Tab>
			{course?.student_evals && <Tab label={`Student Evals (${course.student_evals})`} value="students"></Tab>}
			{course?.iut_evals && <Tab label={`IUT Evals (${course.iut_evals})`} value="iuts" ></Tab>}
			
			{isPMorAdmin && comments.some(c=>c.role_id==1) && <Tab label={`Student Feedback (${comments.filter(c=>c.role_id==1).length})`} value="student_comments"></Tab>}
			{isPMorAdmin && comments.some(c=>c.role_id==3) && <Tab label={`IUT Feedback (${comments.filter(c=>c.role_id==3).length})`} value="iut_comments"></Tab>}
			{isPMorAdmin && comments.some(c=>c.role_id==4) && <Tab label={`Instructor Feedback (${comments.filter(c=>c.role_id==4).length})`} value="instructor_comments"></Tab>}
			
		</Tabs>
		<Divider></Divider>

		{section == "summary" && <Grid container spacing={2}>
			{course && <><Grid xs={12} lg={8}>
				<Box textAlign="left" p={2} pb={0} pl={4}>
					<Typography variant="h6">
						{course?.name}
					</Typography>
					<Box><i className="sub-title">{course?.program_name}</i></Box>
					<Box pt={0.5}><i className="sub-title">{asDate(course.start_date)} - {asDate(course.end_date)}</i></Box>
				</Box>
			</Grid>
			<Grid xs={12} lg={4}>
				<Box textAlign={{xs:"left", lg:"right"}} px={4} pb={0} pt={{xs: 1, lg:3}} >
					<Box py={1} className="sub-title" sx={{fontWeight: '600'}}>{course?.evaluated} / {course?.enrollees} evaluations completed </Box>
					
					<LinearProgress sx={{height: 4, mb:0.5}} variant="determinate" value={course?.student_evals/course?.students*100} />
					{course?.students > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.student_evals} / {course?.students} Students </Box>}
					
					{course?.IUTs > 0 &&  <LinearProgress sx={{height: 4, mb:0.5}} variant="determinate" value={course?.iut_evals/course?.IUTs*100} />}
					{course?.IUTs > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.iut_evals} / {course?.IUTs} IUTs </Box>}

					<LinearProgress sx={{height: 4, mb:0.5}} variant="determinate" value={course?.instructor_evals/course?.instructors*100} />
					{course?.instructors > 0 && <Box pb={1} className="sub-title" sx={{fontSize:'0.75rem'}}>{course?.instructor_evals} / {course?.instructors} Instructors </Box>}
				</Box>
			</Grid>
			<Grid xs={12} >
				<Box mt={1}>
					<Divider></Divider>
				</Box>
			</Grid>
			</>}
			<Grid  xs={12} lg={6}>
				<Box sx={{height: "100%"}}>
					<Box textAlign="center" p={2} pb={0}>
						<Typography variant="h6">
							Student Rating Summary
						</Typography>
					</Box>
					<Box pt="50%" sx={{position:'relative'}}>
						<Box p={2} sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
							<LikertChart dataKey="label" labelKey="label" data={ratings.student_summary} showProgram={showProgramBenchmarks} showGlobal={showGlobalBenchmarks}/>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid  xs={12} md={6}>
				
				<Box sx={{height: "100%"}}>
					<Box textAlign="center" p={2} pb={0}>
						<Typography variant="h6">
							IUT Rating Summary
						</Typography>
					</Box>
					<Box pt="50%" sx={{position:'relative'}}>
						<Box p={2} sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ResponsiveContainer width="100%">
							{!!ratings?.iut_summary?.length ? <RadarChart cx="50%" cy="50%"height="100%" width="100%" data={ratings.iut_summary}>
								<Tooltip/>
								<PolarGrid outerRadius="80%" />
								<PolarAngleAxis dataKey="block" domain={[0,6]} tick={<CustomYAxisTick/>} />
								{/* <PolarRadiusAxis domain={[0,5]}  type="number" angle={90}/> */}
								
								
							
								{showGlobalBenchmarks && <Radar name="Global" dataKey="global" stroke="#999999" fill="#999999" fillOpacity={0.4} />}
								{showProgramBenchmarks && <Radar name="Program" dataKey="program" stroke="#999999" fill="#99999966" fillOpacity={0.4} />}
								<Radar name="Course" dataKey="course" stroke="#6c782e" fill="#6c782e" fillOpacity={0.5} label={<CustomRadarTick/>} />
								
							</RadarChart> : <>No Data</>}
						</ResponsiveContainer>	
						</Box>
					</Box>
				</Box>
			</Grid>
		
		
		</Grid>}

		{section == "students" && <Grid container spacing={2} >
			{blocks.filter(x=>x.role_id==1 && x.type == 'likert').map((block, i)=> {
				var questions = ratings.students.filter(x=>x.block == block.block_id);
				var likert = block.likert_labels?.split('|');
				return <Grid  xs={12} lg={6} key={i}>
					<Box sx={{height: "100%"}}>
						<Box textAlign="center" p={2} pb={0}>
							<Typography variant="h6">
								{block.label}
							</Typography>
							<i className="sub-title">{block.prompt}</i>
						</Box>
						<Box pt={`${questions.length*10+10}%`} sx={{position:'relative'}}>
							<Box p={2} sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<LikertChart dataKey="label" data={questions} showProgram={showProgramBenchmarks} ratings={likert} showGlobal={showGlobalBenchmarks}/>
							</Box>
						</Box>
					</Box>
				</Grid>
})}
		</Grid>}

		{section == "iuts" && <Grid container spacing={2} >
			{blocks.filter(x=>x.role_id==3 && x.type == 'likert').map((block, i)=> {
				var questions = ratings.iuts.filter(x=>x.block == block.block_id);
				
				return <Grid  xs={12} lg={6} key={i}>
					<Box sx={{height: "100%"}}>
						<Box textAlign="center" p={2} pb={0}>
							<Typography variant="h6">
								{block.label}
							</Typography>
							<i className="sub-title">{block.prompt}</i>
						</Box>
						<Box pt={`${questions.length*10+10}%`} sx={{position:'relative'}}>
							<Box p={2} sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<LikertChart dataKey="label" data={questions} showProgram={showProgramBenchmarks} showGlobal={showGlobalBenchmarks}/>
							</Box>
						</Box>
					</Box>
				</Grid>
})}
		</Grid>}

		{section == "student_comments" && <Grid container spacing={2} my={4} >
			
			{blocks.filter(x=>x.role_id==1 && (x.type == 'multiline' || x.type=='mixed')).map((block, i)=> {
				return <Grid xs={12} key={i}>
					
					<Comments block={block} comments={comments.filter(x=>x.role_id==1 && x.block == block.block_id)}/>
					
				</Grid>
			})}
		</Grid>}
		{section == "iut_comments" && <Grid container spacing={2} my={4} >
			
			{blocks.filter(x=>x.role_id==3 && (x.type == 'multiline' || x.type=='mixed')).map((block, i)=> {
				return <Grid xs={12} key={i}>
					
					<Comments block={block} comments={comments.filter(x=>x.role_id==3 && x.block == block.block_id)}/>
					
				</Grid>
			})}
		</Grid>}
		{section == "instructor_comments" && <Grid container spacing={2} my={4} >
			
			{blocks.filter(x=>x.role_id==4 && (x.type == 'multiline' || x.type=='mixed')).map((block, i)=> {
				return <Grid xs={12} key={i}>
					
					<Comments block={block} comments={comments.filter(x=>x.role_id==4&& x.block == block.block_id)}/>
					
				</Grid>
			})}
		</Grid>}

		
		
	</Card>
	</Stack>;
	
	
	
}
import { forwardRef, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, OutlinedInput, IconButton, Avatar, Box, Stack } from '@mui/material';
import { Clear } from '@mui/icons-material';
import UnitSearch from './UnitSearch';


export default function UnitSelector(props) {
	const {unit, onSelect, onTextChange, InputProps, required, index, error, managedOnly, defaultText, parent, label, clearOnSelect} = props;
	const {agencies} = useSelector((state) => state.data);
	const config = useSelector(state=>state.data.config);

	const [unitLogo, setUnitLogo] = useState(null);

	useEffect(()=>{
		var abbr = agencies.find((x)=>x.id == unit?.agency_id)?.abbr;
		if (abbr) {
			setUnitLogo(`/images/agency/${abbr}.png`);
		}
	}, [unit]);

	var input = useRef();
	
	return unit ? <FormControl flex={1}  size="small" fullWidth className="unit-selector-filled">
		<InputLabel focused shrink variant="outlined" htmlFor="unit-holder" sx={{backgroundColor:'white', "& label":{backgroundColor:"white"}}}>{label || "Assigned Command"}</InputLabel><OutlinedInput sx={{paddingRight:"4px", justifyContent:'space-between', flex:1}} label={label || "Assigned Command"} slots={{input: forwardRef((props,ref)=>{ return <Box ref={ref} flex={1} pl={1.5} pr={1.75} py={0.75} >
		<Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1} width="100%">
			<Avatar src={unitLogo} sx={{border:"solid 2px white", height: 24, cursor: 'pointer', width: 24, mr:1 }} />
			<Box>{unit.name}</Box>
		</Stack>
	</Box>; }
	)}} endAdornment={<IconButton size="small" onClick={()=>onSelect && onSelect(null)}><Clear fontSize="inherit"/></IconButton>} /> </FormControl>
	: <UnitSearch onTextChange={onTextChange} error={error} InputProps={InputProps || {}} label={label || "Assigned Command"} parent={parent} defaultText={defaultText} onSelect={(unit) => {onSelect && onSelect(unit); onTextChange && onTextChange(null)} } clearOnSelect={clearOnSelect} managedOnly={managedOnly} index={index || config.unit_index} unit={unit} />
}